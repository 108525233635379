import { useState, useEffect } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';

import { useAuth } from 'hooks';

import type { Transaction } from 'types';
import { getTransactionsByClient, setApplicantId as applicantIdSetter } from 'services/firestore';

interface UseTransactionsByClient {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  transactions: Transaction[] | null;
  setApplicantId: (id: string) => Promise<void>;
}

export function useTransactionsByClient(): UseTransactionsByClient {
  const [transactions, setTransactions] = useState<Transaction[] | null>(null);
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  const email = currentUser?.email;
  const uid = currentUser?.uid;

  const transactionsQuery = useQuery('transactionsByClient', () =>
    getTransactionsByClient(email ?? '')
  );

  const setApplicantIdMutation = useMutation<
    void,
    unknown,
    { id: string; applicantId: Transaction['applicantsIds'][string] },
    unknown
  >(({ id, applicantId }) => applicantIdSetter(id, email ?? '', applicantId), {
    onSettled: () => {
      queryClient.invalidateQueries('transactionsByClient');
    }
  });

  function setApplicantId(id: string) {
    const path = `${email}/${uid}/id/clientId`;
    return setApplicantIdMutation.mutateAsync({ id, applicantId: { shared: true, idPath: path } });
  }

  useEffect(() => {
    if (transactionsQuery.isSuccess) {
      const result = transactionsQuery.data;
      const queryResult: Transaction[] = [];
      if (!result.empty) {
        result.forEach((item) => queryResult.push(item.data()));
      }
      setTransactions(queryResult);
    }
  }, [transactionsQuery.data, transactionsQuery.isSuccess]);

  return {
    isLoading: transactionsQuery.isLoading,
    isError: transactionsQuery.isError,
    isSuccess: transactionsQuery.isSuccess,
    transactions: transactions,
    setApplicantId
  };
}
