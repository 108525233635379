import type { ReactNode } from 'react';
import { createContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { auth, onIdTokenChanged } from 'services/firebase';
import { logout as firebaseLogout, register as firebaseRegister } from 'services/auth';
import Loading from 'pages/Loading';
import { useFullPageLoader, useSnackbar } from 'hooks';
import type { FieldItem, User, UserCredential, UserWithClaims } from 'types';
import { getFields, setFields as insertFields } from '../services/firestore';
import { initialFields } from '../config/initialFields';

interface Props {
  children: ReactNode;
}

export interface AuthContextProps {
  currentUser: User;
  fields: FieldItem[];
  fieldsObject: { [x: string]: FieldItem };
  logout: () => Promise<void>;
  register: (email: string, password: string, displayName?: string) => Promise<UserCredential>;
}

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export function AuthProvider({ children }: Props): JSX.Element {
  const [currentUser, setCurrentUser] = useState<User>(null);
  const [isLoading, setLoading] = useState(true);
  const [fields, setFields] = useState<FieldItem[]>([]);
  const queryClient = useQueryClient();
  const { setLoader } = useFullPageLoader();
  const { setSnack } = useSnackbar();

  const fieldsMutation = useMutation<void, unknown, FieldItem[], unknown>(
    (settings) => insertFields(settings),
    {
      onSettled: () => {
        queryClient.invalidateQueries('settings');
      }
    }
  );

  function initializeFields() {
    return fieldsMutation.mutateAsync(initialFields);
  }

  const fieldsQuery = useQuery('fields', () => getFields(), {
    onSuccess: (data) => {
      // Check if user Settings is empty so this is the first time user has logged in
      // If so, we should populate some initial data for the user
      const exists = !data.empty;
      if (!exists) {
        console.log('initializeFields!');
        initializeFields();
      }
    }
  });

  async function logout() {
    setLoader({
      color: 'blue',
      key: 'logout'
    });
    try {
      await firebaseLogout();
    } catch (error) {
      console.error('Invalid Token!');
    } finally {
      //Clear react-query cache after logout
      queryClient.clear();
      setLoader({
        color: false,
        key: 'logout'
      });
    }
  }

  function register(
    email: string,
    password: string,
    displayName?: string
  ): Promise<UserCredential> {
    return firebaseRegister(email, password, displayName);
  }

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      if (!user) {
        setCurrentUser(user as UserWithClaims);
        setLoading(false);
        return;
      }

      try {
        const idTokenResult = await user.getIdTokenResult();

        setCurrentUser({
          ...user,
          isRealtor: !!idTokenResult.claims?.isRealtor,
          isClient: !!idTokenResult.claims?.isClient,
          isAdmin: !!idTokenResult.claims?.isAdmin,
          isMFA: idTokenResult.signInSecondFactor === 'phone',
          reco: (idTokenResult.claims?.reco as Record<string, unknown>) ?? {}
        });
      } catch {
        setSnack({
          message: 'Error getting authentication data.',
          severity: 'error',
          open: true
        });
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [setSnack]);

  useEffect(() => {
    if (fieldsQuery.isSuccess) {
      const result = fieldsQuery.data;
      const queryResult: FieldItem[] = [];
      if (!result.empty) {
        result.forEach((item) => queryResult.push({ ...item.data(), id: item.id }));
      }
      setFields(queryResult);
    }
  }, [fieldsQuery.data, fieldsQuery.isSuccess]);

  const value: AuthContextProps = {
    currentUser,
    logout,
    fields,
    fieldsObject: fields.reduce((a, v) => ({ ...a, [v.text]: v }), {}),
    register
  };

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && children}
      {isLoading && <Loading />}
    </AuthContext.Provider>
  );
}
