import greenPlusIcon from 'assets/icons/green_plus.svg';

import { Template } from 'types';

interface Props {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  latestDocuments: Template['documents'];
}

export function AddDocumentInput({
  value,
  setValue,
  onClick,
  latestDocuments
}: Props): JSX.Element {
  return (
    <div className="w-64">
      <div className="w-full h-[50px] flex rounded-[4px] border border-gray-4 overflow-hidden">
        <div className="flex-1">
          <input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            type="text"
            id="documentNameToAdd"
            className="w-full h-full border-0 p-2 text-input focus:outline-none"
            placeholder="Document Name"
            autoComplete="off"
            list="latestDocuments"
          />
          <datalist id="latestDocuments">
            {latestDocuments.map((document) => (
              <option key={document.id} value={document.name} />
            ))}
          </datalist>
        </div>
        <div
          className="w-[50px] h-[50px] flex justify-center items-center bg-gray-2 cursor-pointer hover:opacity-70"
          onClick={onClick}
        >
          <img src={greenPlusIcon} alt="add" className="w-5 h-5" />
        </div>
      </div>
    </div>
  );
}
