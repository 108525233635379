import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { useAuth } from 'hooks';
import { deleteDocumentFile, getDocumentFile, setDocumentFile } from 'services/storage';

import type { DocumentFileWithMetaData, UploadTask } from 'types';
import type { FilePondFile } from 'filepond';
import { getTransaction } from 'services/transaction';
import { ExtendedTransaction } from 'types/newTransaction';

interface Props {
  transactionId: string;
  documentId?: string;
}
interface UseDocumentUploader {
  isLoading: boolean;
  documentUploader: (file: FilePondFile['file'], documentId: string) => UploadTask;
  documentRemover: (documentId: string) => Promise<void>;
  useDocumentFileQuery: (
    documentId: string | null
  ) => UseQueryResult<DocumentFileWithMetaData, unknown>;
  transaction: ExtendedTransaction;
  setDocumentURL: Dispatch<SetStateAction<DocumentFileWithMetaData | null>>;
  documentURL: DocumentFileWithMetaData | null;
}

export function useDocumentUploader({ transactionId, documentId }: Props): UseDocumentUploader {
  const { currentUser } = useAuth();
  const email = currentUser?.email;
  const [documentURL, setDocumentURL] = useState<DocumentFileWithMetaData | null>(null);

  const transactionQuery = useQuery(['getTransaction', { transactionId }], () =>
    getTransaction(transactionId)
  );

  function documentUploader(file: FilePondFile['file'], documentId: string): UploadTask {
    return setDocumentFile(email ?? '', transactionId, documentId, file);
  }

  function documentRemover(documentId: string) {
    setDocumentURL(null);
    return deleteDocumentFile(email ?? '', transactionId, documentId);
  }

  const useDocumentFileQuery = (documentId: string | null) =>
    useQuery(
      ['document', { email, transactionId, documentId }],
      () => {
        return getDocumentFile(email ?? '', transactionId, documentId || '');
      },
      {
        enabled: false,
        onSuccess: (data) => {
          setDocumentURL(data);
        }
      }
    );

  useEffect(() => {
    setDocumentURL(null);
  }, [transactionId, transactionId, documentId]);

  return {
    isLoading: transactionQuery.isLoading,
    useDocumentFileQuery,
    setDocumentURL,
    documentUploader,
    documentRemover,
    documentURL,
    transaction: transactionQuery.data?.data?.success
      ? transactionQuery.data.data.transaction
      : undefined
  };
}
