import { useCallback } from 'react';

import type { ComponentPropsWithoutRef } from 'react';

interface Props {
  page: number;
  limit: number;
  count: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

interface PageItemProps extends ComponentPropsWithoutRef<'div'> {
  num: number | string;
  selected: boolean;
  onClick: () => void;
}

function PageItem({ num, selected, onClick, className }: PageItemProps): JSX.Element {
  return (
    <div
      className={`h-5 text-center cursor-pointer rounded-[3px] px-2 ${
        selected ? 'text-white bg-gray-5' : 'hover:opacity-70'
      } ${className}`}
      onClick={onClick}
    >
      {num}
    </div>
  );
}

export function Pagination({ page, limit, count, setPage }: Props): JSX.Element {
  const getPageCount = useCallback(() => {
    return Math.ceil(count / limit);
  }, [count, limit]);

  const getMiddleItems = useCallback(() => {
    const pageCount = getPageCount();

    return [...Array(5)]
      .map((_, i) => page + i - 2)
      .filter((page) => page > 0 && page <= pageCount);
  }, [getPageCount, page]);

  return (
    <>
      {getPageCount() !== 1 && (
        <div className="hidden sm:flex flex-row flex-nowrap text-list1 text-gray-6 space-x-2">
          {page !== 1 && (
            <PageItem num="< Prev" onClick={() => setPage(page - 1)} selected={false} />
          )}
          {getMiddleItems()[0] > 1 && (
            <PageItem num={1} onClick={() => setPage(1)} selected={page === 1} />
          )}
          {getMiddleItems()[0] > 2 && <div>...</div>}
          {getMiddleItems().map((item) => (
            <PageItem
              key={item}
              num={item}
              onClick={() => setPage(item)}
              selected={page === item}
            />
          ))}
          {getMiddleItems()[getMiddleItems().length - 1] < getPageCount() - 1 && <div>...</div>}
          {getMiddleItems()[getMiddleItems().length - 1] < getPageCount() && (
            <PageItem
              num={getPageCount()}
              onClick={() => setPage(getPageCount())}
              selected={page === getPageCount()}
            />
          )}
          {page !== getPageCount() && (
            <PageItem num="Next >" onClick={() => setPage(page + 1)} selected={false} />
          )}
        </div>
      )}
      {getPageCount() !== 1 && (
        <div className="flex flex-row flex-nowrap text-list1 text-gray-6 space-x-2 sm:hidden">
          <PageItem
            className={`${page === 1 ? 'text-gray-3' : ''}`}
            num="<"
            onClick={() => {
              if (page === 1) return;
              setPage(page - 1);
            }}
            selected={false}
          />
          <div className="px-1">
            {page} of {getPageCount()}
          </div>
          <PageItem
            className={`${page === getPageCount() ? 'text-gray-3' : ''}`}
            num=">"
            onClick={() => {
              if (page === getPageCount()) return;
              setPage(page + 1);
            }}
            selected={false}
          />
        </div>
      )}
      {getPageCount() === 1 && <div></div>}
    </>
  );
}
