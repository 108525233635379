import { Dispatch, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { createTemplate, deleteTemplate, getMyTemplates } from 'services/transaction';
import { ExtendedTemplate } from 'types/newTransaction';

interface UseTemplates {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  templates: ExtendedTemplate[] | null;
  setTemplate: (template: ExtendedTemplate) => Promise<void>;
  removeTemplate: (id: string) => Promise<void>;
  setTemplates: Dispatch<React.SetStateAction<ExtendedTemplate[]>>;
}

export function useTemplates(fetchOnStart = true): UseTemplates {
  const [templates, setTemplates] = useState<ExtendedTemplate[]>();
  const [isLoading, setIsLoading] = useState<boolean>(fetchOnStart);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const getMyTemplatesQuery = useQuery(
    ['getMyTemplates'],
    () => {
      return getMyTemplates();
    },
    {
      enabled: fetchOnStart,
      refetchOnMount: fetchOnStart ? 'always' : undefined,
      onSuccess({ data }) {
        setTemplates(data.templates);
      }
    }
  );
  useEffect(() => {
    setIsError(getMyTemplatesQuery.isError);
    setIsLoading(getMyTemplatesQuery.isLoading || getMyTemplatesQuery.isFetching);
    setIsSuccess(getMyTemplatesQuery.isSuccess);
    setTemplates(getMyTemplatesQuery.data?.data?.templates);
  }, [
    getMyTemplatesQuery.isError,
    getMyTemplatesQuery.isFetching,
    getMyTemplatesQuery.isLoading,
    getMyTemplatesQuery.isSuccess,
    getMyTemplatesQuery.data?.data?.templates
  ]);

  const setTemplate = async ({ realtors, ...template }: ExtendedTemplate) => {
    try {
      setIsLoading(true);
      const res = await createTemplate(template);
      if (res.data.success) {
        const temp = res.data.template;
        setTemplates((a) => [...a, temp]);
      }
      setIsSuccess(true);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const removeTemplate = async (id: string) => {
    try {
      setIsLoading(true);
      await deleteTemplate(id);
      setTemplates((template) => template.filter((temp) => temp.id !== id));
      setIsSuccess(true);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    isError,
    isSuccess,
    templates,
    setTemplate,
    setTemplates,
    removeTemplate
  };
}
