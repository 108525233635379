import { forwardRef } from 'react';
import type { ComponentPropsWithoutRef, LegacyRef } from 'react';

interface Props extends ComponentPropsWithoutRef<'textarea'> {
  label?: string;
  disableResize?: boolean;
  noBorder?: boolean;
}

export const TextArea = forwardRef(
  (
    { className = '', label, disabled, style, id, disableResize, noBorder, ...props }: Props,
    ref: LegacyRef<HTMLTextAreaElement>
  ) => (
    <div
      className={`w-[260px] text-label cursor-pointer ${
        disabled ? 'text-gray-5' : 'text-gray-6'
      } ${className}`}
      style={style}
    >
      {label && (
        <label htmlFor={id} className="cursor-pointer mb-[6px]">
          {label}
        </label>
      )}
      <div className="w-full flex">
        <textarea
          id={id}
          disabled={disabled}
          className={`w-full p-4 focus:outline-none text-input ${
            disableResize ? 'resize-none' : ''
          } ${noBorder ? '' : 'border border-gray-4 rounded-[4px]'}`}
          ref={ref}
          rows={3}
          {...props}
        />
      </div>
    </div>
  )
);
