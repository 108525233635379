import { Redirect } from 'react-router-dom';
import { nanoid } from 'nanoid';

import { ROOT_ROUTES } from 'routers/Routes';
import { REALTOR_NESTED_ROUTES } from 'routers/RealtorNestedRoutes';
import { HASH } from 'routers/Hash';

interface Props {
  newForm?: boolean;
}

export default function NewForm({ newForm = false }: Props): JSX.Element {
  const id = nanoid();

  return (
    <Redirect
      to={`${ROOT_ROUTES.realtor}${REALTOR_NESTED_ROUTES.formBuilderRoot}/${id}${
        newForm ? HASH.editForm : ''
      }`}
    />
  );
}
