import React from 'react';

import type { ComponentPropsWithoutRef, LegacyRef } from 'react';

export const Select = React.forwardRef(
  (
    { className = '', children, ...props }: ComponentPropsWithoutRef<'select'>,
    ref: LegacyRef<HTMLSelectElement>
  ) => (
    <select
      className={`w-[300px] h-[50px] rounded-[4px] border border-gray-4 p-2 cursor-pointer focus:outline-none text-input mb-5 pl-2 pr-8 ${className}`}
      ref={ref}
      {...props}
    >
      {children}
    </select>
  )
);
