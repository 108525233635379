import { FORM_BUILDER_DEFAULT_FONT_SIZE, FORM_BUILDER_DEFAULT_LINE_HEIGHT } from 'config/constants';

import type { CSSProperties } from 'react';
import type { AllToolTypes } from 'types';

interface FieldConfig {
  style: CSSProperties | undefined;
  className: string;
  lockAspectRatio: boolean;
  defaultWidth: number;
  defaultHeight: number;
  minHeight: number;
  minWidth: number;
  resizeGrid: [number, number];
  enableResizing: {
    top: boolean;
    right: boolean;
    bottom: boolean;
    left: boolean;
    topRight: boolean;
    bottomRight: boolean;
    bottomLeft: boolean;
    topLeft: boolean;
  };
}

type FieldsConfig = {
  [FieldType in AllToolTypes]: FieldConfig;
};

export function fieldsConfig(widthRatio: number): FieldsConfig {
  return {
    TextBox: {
      style: {},
      className: '',
      lockAspectRatio: false,
      defaultWidth: 150 * widthRatio,
      defaultHeight: FORM_BUILDER_DEFAULT_FONT_SIZE * widthRatio * FORM_BUILDER_DEFAULT_LINE_HEIGHT,
      minHeight: FORM_BUILDER_DEFAULT_FONT_SIZE * widthRatio * FORM_BUILDER_DEFAULT_LINE_HEIGHT,
      minWidth: 10,
      resizeGrid: [
        1,
        FORM_BUILDER_DEFAULT_FONT_SIZE * widthRatio * FORM_BUILDER_DEFAULT_LINE_HEIGHT
      ],
      enableResizing: {
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }
    },
    CheckBox: {
      style: {},
      className: '',
      lockAspectRatio: true,
      defaultWidth: 13 * widthRatio,
      defaultHeight: 13 * widthRatio,
      minHeight: 10,
      minWidth: 10,
      resizeGrid: [1, 1],
      enableResizing: {
        top: false,
        right: false,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: true,
        bottomLeft: false,
        topLeft: false
      }
    },
    SignatureBox: {
      style: {},
      className: '',
      lockAspectRatio: false,
      defaultWidth: 70 * widthRatio,
      defaultHeight: 35 * widthRatio,
      minHeight: 10,
      minWidth: 10,
      resizeGrid: [1, 1],
      enableResizing: {
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true
      }
    },
    InitialBox: {
      style: {},
      className: '',
      lockAspectRatio: false,
      defaultWidth: 70 * widthRatio,
      defaultHeight: 35 * widthRatio,
      minHeight: 10,
      minWidth: 10,
      resizeGrid: [1, 1],
      enableResizing: {
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true
      }
    },
    MlsBox: {
      style: {},
      className: '',
      lockAspectRatio: false,
      defaultWidth: 150 * widthRatio,
      defaultHeight: FORM_BUILDER_DEFAULT_FONT_SIZE * widthRatio * FORM_BUILDER_DEFAULT_LINE_HEIGHT,
      minHeight: FORM_BUILDER_DEFAULT_FONT_SIZE * widthRatio * FORM_BUILDER_DEFAULT_LINE_HEIGHT,
      minWidth: 10,
      resizeGrid: [
        1,
        FORM_BUILDER_DEFAULT_FONT_SIZE * widthRatio * FORM_BUILDER_DEFAULT_LINE_HEIGHT
      ],
      enableResizing: {
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }
    },
    SignatureDate: {
      style: {},
      className: '',
      lockAspectRatio: false,
      defaultWidth: 70 * widthRatio,
      defaultHeight: 35 * widthRatio,
      minHeight: 10,
      minWidth: 10,
      resizeGrid: [1, 1],
      enableResizing: {
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: true,
        bottomRight: true,
        bottomLeft: true,
        topLeft: true
      }
    },
    Text: {
      style: {},
      className: '',
      lockAspectRatio: false,
      defaultWidth: 150 * widthRatio,
      defaultHeight: FORM_BUILDER_DEFAULT_FONT_SIZE * widthRatio * FORM_BUILDER_DEFAULT_LINE_HEIGHT,
      minHeight: FORM_BUILDER_DEFAULT_FONT_SIZE * widthRatio * FORM_BUILDER_DEFAULT_LINE_HEIGHT,
      minWidth: 10,
      resizeGrid: [
        1,
        FORM_BUILDER_DEFAULT_FONT_SIZE * widthRatio * FORM_BUILDER_DEFAULT_LINE_HEIGHT
      ],
      enableResizing: {
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }
    },
    Strikethrough: {
      style: {
        background: 'transparent',
        borderWidth: 0
      },
      className: '',
      lockAspectRatio: false,
      defaultWidth: 0,
      defaultHeight: 1,
      minHeight: 0,
      minWidth: 0,
      resizeGrid: [
        1,
        FORM_BUILDER_DEFAULT_FONT_SIZE * widthRatio * FORM_BUILDER_DEFAULT_LINE_HEIGHT
      ],
      enableResizing: {
        top: true,
        right: true,
        bottom: true,
        left: true,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false
      }
    }
  };
}
