import { RealtorSettings } from 'types';

export const initialRealtorSettings: RealtorSettings = {
  profileUrl: '',
  realtorFields: {},
  customFields: {},
  latestDocuments: [],
  preferences: {
    sendEmailNotifications: true,
    sendNotifications: true,
    askForClientId: true,
    formSeenEmail: false,
    formSeenNotifications: true,
    allTransactionSubmittedEmail: true,
    allTransactionSubmittedNotifications: true,
    transactionSubmittedEmail: true,
    transactionSubmittedNotifications: true,
    documentCommittedEmail: false,
    documentCommittedNotifications: true,
    documentUncommittedEmail: false,
    documentUncommittedNotifications: false,
    applicantSignedEmail: false,
    applicantSignedNotifications: false,
    transactionSignedEmail: true,
    transactionSignedNotifications: true,
    applicantIdUploadedEmail: false,
    applicantIdUploadedNotifications: true,
    incorrectEmailEmail: true,
    incorrectEmailNotifications: true
  }
};
