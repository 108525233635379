import { nanoid } from 'nanoid';

import { fieldsConfig } from 'config/fieldsConfig';
import { atan2, defaultSubFields, pythagoras } from 'utils';

import {
  Form,
  FormAnnotation,
  FormField,
  ToolTypes,
  WithoutOwnerId,
  annotationFields
} from 'types';

interface Props {
  formData: WithoutOwnerId<Form>;
  setFormData: React.Dispatch<React.SetStateAction<WithoutOwnerId<Form>>>;
  selectedTool: ToolTypes;
  filledBy: number;
  deSelectTool: () => void;
  wrapperWidth: number;
  originalWidth: number;
}

interface UseWorkSpace {
  handleWorkSpaceClick: (
    event: React.MouseEvent<HTMLElement>,
    setEditField: (
      fieldId: string,
      field: Form['fields']['fieldId'] | Form['annotations']['annotationId']
    ) => void
  ) => void;
  handleWorkSpaceDraw: (
    event: React.MouseEvent<HTMLElement>,
    setEditField: (
      fieldId: string,
      field: Form['fields']['fieldId'] | Form['annotations']['annotationId']
    ) => void
  ) => void;
  setField: (
    fieldId: string,
    newField: Form['fields']['fieldId'] | Form['annotations']['annotationId']
  ) => void;
  deleteField: (fieldId: string) => void;
  addApplicantsCount: () => void;
}

export function useWorkSpace({
  formData,
  setFormData,
  selectedTool,
  filledBy,
  deSelectTool,
  wrapperWidth,
  originalWidth
}: Props): UseWorkSpace {
  function handleWorkSpaceClick(
    event: React.MouseEvent<HTMLElement>,
    setEditField: (
      fieldId: string,
      field: Form['fields']['fieldId'] | Form['annotations']['annotationId']
    ) => void
  ) {
    if (!selectedTool) return;
    const bounds = event.currentTarget.getBoundingClientRect();
    const newFieldId = nanoid();
    const widthRatio = wrapperWidth / originalWidth;
    const newGeneralField = {
      x: (event.clientX - bounds.left) / widthRatio,
      y: (event.clientY - bounds.top) / widthRatio,
      w: fieldsConfig(wrapperWidth / originalWidth)[selectedTool].defaultWidth / widthRatio,
      h: fieldsConfig(wrapperWidth / originalWidth)[selectedTool].defaultHeight / widthRatio,
      filledBy: filledBy
    };
    let newField: FormField;

    switch (selectedTool) {
      case 'TextBox':
        newField = {
          type: 'TextBox',
          metaData: {
            filledWith: 'Name',
            lines: 1,
            required: false,
            unique: false,
            subFields: defaultSubFields.name
          },
          ...newGeneralField
        };
        break;
      case 'CheckBox':
        newField = {
          type: 'CheckBox',
          metaData: {
            checked: false,
            description: 'Check Box'
          },
          ...newGeneralField
        };
        break;
      case 'InitialBox':
        newField = {
          type: 'InitialBox',
          ...newGeneralField
        };
        break;
      case 'SignatureBox':
        newField = {
          type: 'SignatureBox',
          ...newGeneralField
        };
        break;
      case 'MlsBox':
        newField = {
          type: 'MlsBox',
          metaData: {
            lines: 1,
            category: 'Address',
            dataField: 'Address',
            subFields: defaultSubFields.address
          },
          ...newGeneralField,
          filledBy: 0
        };
        break;
      case 'SignatureDate':
        newField = {
          type: 'SignatureDate',
          ...newGeneralField
        };
        break;
      default:
        const exhaustiveCheck = selectedTool;
        return exhaustiveCheck;
    }

    const newFields = { ...formData.fields, [newFieldId]: newField };
    setFormData((prev) => {
      const newState: typeof prev = { ...prev, fields: newFields };
      return newState;
    });
    if (!['InitialBox', 'SignatureBox', 'SignatureDate'].includes(selectedTool)) {
      setTimeout(() => {
        setEditField(newFieldId, newField);
      }, 100);
    }
    deSelectTool();
  }

  function handleWorkSpaceDraw(
    event: React.MouseEvent<HTMLElement>,
    setEditField: (
      fieldId: string,
      field: Form['fields']['fieldId'] | Form['annotations']['annotationId']
    ) => void
  ) {
    const $currentTarget = event.currentTarget;
    if (!selectedTool) return;
    const $placeHolder = document.createElement('div');
    $placeHolder.className =
      selectedTool === 'Text'
        ? 'annotation-placeholder'
        : selectedTool === 'Strikethrough'
        ? 'strikethrough-line'
        : 'field-placeholder';
    $placeHolder.style.left = `${event.pageX}px`;
    $placeHolder.style.top = `${event.pageY}px`;
    if (selectedTool === 'Strikethrough') {
      $placeHolder.style.borderWidth = '2px';
      $placeHolder.style.borderColor = 'red';
    }

    const onMouseMove = (_event) => {
      if (selectedTool === 'Strikethrough') {
        $placeHolder.style.width = `${pythagoras(
          _event.pageX - event.pageX,
          _event.pageY - event.pageY
        )}px`;
        const alpha = atan2(_event.pageY - event.pageY, _event.pageX - event.pageX);
        $placeHolder.style.rotate = `${window.shiftKey ? Math.round(alpha / 45) * 45 : alpha}deg`;
      } else {
        const w = _event.pageX - event.pageX;
        const h = _event.pageY - event.pageY;
        if (w < 0) {
          $placeHolder.style.left = `${_event.pageX}px`;
        }
        if (h < 0) {
          $placeHolder.style.top = `${_event.pageY}px`;
        }
        $placeHolder.style.width = `${Math.abs(w)}px`;
        $placeHolder.style.height = `${Math.abs(h)}px`;
      }
    };
    const onMouseUp = (_event: any) => {
      $currentTarget.removeEventListener('mouseup', onMouseUp);
      $currentTarget.removeEventListener('mousemove', onMouseMove);
      const placeholderBounds = $placeHolder.getBoundingClientRect();
      const bounds = $currentTarget.getBoundingClientRect();
      const newFieldId = nanoid();
      const lines = Math.ceil(
        placeholderBounds.height /
          fieldsConfig(wrapperWidth / originalWidth)[selectedTool].defaultHeight
      );
      const widthRatio = wrapperWidth / originalWidth;
      const newGeneralField = {
        x: (placeholderBounds.x - bounds.left) / widthRatio,
        y: (placeholderBounds.y - bounds.top) / widthRatio,
        w:
          (placeholderBounds.width < 10
            ? fieldsConfig(wrapperWidth / originalWidth)[selectedTool].defaultWidth
            : placeholderBounds.width) / widthRatio,
        h:
          (placeholderBounds.height < 10
            ? fieldsConfig(wrapperWidth / originalWidth)[selectedTool].defaultHeight
            : placeholderBounds.height) / widthRatio,
        filledBy: filledBy
      };

      $placeHolder.remove();
      let newField: FormField | FormAnnotation;
      switch (selectedTool) {
        case 'TextBox':
          newField = {
            type: 'TextBox',
            metaData: {
              filledWith: 'Name',
              lines,
              required: false,
              unique: false,
              subFields: defaultSubFields.name
            },
            ...newGeneralField
          };
          break;
        case 'CheckBox':
          newField = {
            type: 'CheckBox',
            metaData: {
              checked: false,
              description: 'Check Box'
            },
            ...newGeneralField
          };
          break;
        case 'InitialBox':
          newField = {
            type: 'InitialBox',
            ...newGeneralField
          };
          break;
        case 'SignatureBox':
          newField = {
            type: 'SignatureBox',
            ...newGeneralField
          };
          break;
        case 'MlsBox':
          newField = {
            type: 'MlsBox',
            metaData: {
              lines,
              category: 'Address',
              dataField: 'Address',
              subFields: defaultSubFields.address
            },
            ...newGeneralField,
            filledBy: 0
          };
          break;
        case 'SignatureDate':
          newField = {
            type: 'SignatureDate',
            ...newGeneralField
          };
          break;
        case 'Strikethrough':
          newField = {
            type: 'Strikethrough',
            ...newGeneralField,
            metaData: {
              color: 'red',
              width: 2 * widthRatio,
              opacity: 1,
              start: {
                x: event.clientX / widthRatio,
                y: event.clientY / widthRatio
              },
              end: {
                x:
                  (_event.clientX > event.clientX
                    ? placeholderBounds.right
                    : placeholderBounds.left) / widthRatio,
                y:
                  (_event.clientY > event.clientY
                    ? placeholderBounds.bottom
                    : placeholderBounds.top) / widthRatio
              }
            },
            filledBy: 0
          };
          break;
        case 'Text':
          newField = {
            type: 'Text',
            ...newGeneralField,
            metaData: {
              content: '',
              style: {
                fontSize: 8,
                lineHeight: 1,
                color: 'black'
              }
            },
            filledBy: 0
          };
          break;
        default:
          const exhaustiveCheck = selectedTool;
          return exhaustiveCheck;
      }
      const newFields = { ...formData.fields, [newFieldId]: newField };
      const newAnnotation = { ...formData.annotations, [newFieldId]: newField };
      setFormData((prev) => {
        if (['Strikethrough', 'Text'].includes(selectedTool)) {
          const newState: typeof prev = {
            ...prev,
            annotations: newAnnotation as {
              [id: string]: FormAnnotation;
            }
          };
          return newState;
        }
        const newState: typeof prev = {
          ...prev,
          fields: newFields as {
            [id: string]: FormField;
          }
        };
        return newState;
      });
      if (!['InitialBox', 'SignatureBox', 'SignatureDate'].includes(selectedTool)) {
        setTimeout(() => {
          setEditField(newFieldId, newField);
        }, 100);
      }
      deSelectTool();
    };

    $currentTarget.addEventListener('mouseup', onMouseUp);
    $currentTarget.addEventListener('mousemove', onMouseMove);
    $currentTarget.appendChild($placeHolder);
  }

  function setField(
    fieldId: string,
    newField: Form['fields']['fieldId'] | Form['annotations']['annotationId']
  ) {
    setFormData((prev) => {
      if (annotationFields.includes(newField.type)) {
        return {
          ...prev,
          annotations: { ...prev.annotations, [fieldId]: newField }
        } as typeof prev;
      }
      return { ...prev, fields: { ...prev.fields, [fieldId]: newField } } as typeof prev;
    });
  }

  function deleteField(fieldId: string) {
    setFormData((prev) => {
      const { [fieldId]: fieldToDelete, ...fields } = prev.fields;
      const { [fieldId]: annotationToDelete, ...annotations } = prev.annotations || {};
      const newState: typeof prev = { ...prev, fields, annotations };
      return newState;
    });
  }

  function addApplicantsCount() {
    setFormData((prev) => {
      const newState: typeof prev = { ...prev, applicantsCount: prev.applicantsCount + 1 };
      return newState;
    });
  }

  return {
    handleWorkSpaceClick,
    setField,
    deleteField,
    addApplicantsCount,
    handleWorkSpaceDraw
  };
}
