import { useMutation, useQueryClient } from 'react-query';

import { useCRUD } from 'hooks';
import {
  getApplicants as getter,
  setApplicant as setter,
  removeApplicant as remover,
  setApplicants as batchSetter
} from 'services/firestore';

import type { Applicant, Applicant as ItemType, WithoutOwnerId } from 'types';

interface UseApplicants {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  applicants: ItemType[] | null;
  setApplicant: (applicant: WithoutOwnerId<ItemType>) => Promise<void>;
  setApplicants: (applicants: ItemType[]) => Promise<void>;
  removeApplicant: (id: string) => Promise<void>;
}

export function useApplicants(): UseApplicants {
  const queryClient = useQueryClient();
  const { isLoading, isError, isSuccess, items, setItem, removeItem } = useCRUD<ItemType>({
    queryKey: 'applicants',
    getter,
    setter,
    remover
  });

  const setApplicantsMutation = useMutation<void, unknown, Applicant[], unknown>(
    (applicants) => batchSetter(applicants),
    {
      onSettled: () => {
        queryClient.invalidateQueries('applicants');
      }
    }
  );

  function setApplicants(applicants: Applicant[]) {
    return setApplicantsMutation.mutateAsync(applicants);
  }

  return {
    isLoading,
    isError,
    isSuccess,
    applicants: items,
    setApplicant: setItem,
    setApplicants,
    removeApplicant: removeItem
  };
}
