import { Input, InputM } from 'components';
import { ComponentPropsWithoutRef } from 'react';

interface Props extends ComponentPropsWithoutRef<'input'> {
  label: string;
  halfSize?: boolean;
}
export function LabeledInput({
  label,
  id,
  disabled,
  halfSize = false,
  style,
  ...props
}: Props): JSX.Element {
  return (
    <div
      className={`w-80 mb-5 mr-2 text-label cursor-pointer ${
        disabled ? 'text-gray-5' : 'text-gray-6'
      }`}
      style={style}
    >
      <label htmlFor={id} className="cursor-pointer">
        {label}
      </label>
      <div className="mt-[6px]">
        <Input
          type="text"
          id={id}
          style={{ ...style, ...(halfSize ? { width: '150px' } : {}) }}
          className="text-black"
          disabled={disabled}
          {...props}
        />
      </div>
    </div>
  );
}

export function LabeledInputM({
  label,
  id,
  disabled,
  style,
  className = '',
  ...props
}: Props): JSX.Element {
  return (
    <div
      className={`w-[260px] text-label cursor-pointer ${
        disabled ? 'text-gray-5' : 'text-gray-6'
      } ${className}`}
      style={style}
    >
      <label htmlFor={id} className="cursor-pointer">
        {label}
      </label>
      <div className="mt-[6px]">
        <InputM type="text" id={id} className="text-black" disabled={disabled} {...props} />
      </div>
    </div>
  );
}
