import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Suspense, lazy } from 'react';

import ProtectedRoute from 'routers/ProtectedRoute';
import LinearProgress from 'components/LinearProgress';
const Dashboard = lazy(() => import('pages/realtor/Dashboard'));
const ESign = lazy(() => import('pages/realtor/ESign'));
const Print = lazy(() => import('pages/realtor/Print'));
const SecondRealtorFill = lazy(() => import('pages/realtor/SecondRealtorFill'));
const IncorrectEmail = lazy(() => import('pages/realtor/IncorrectEmail'));

export const REALTOR_ROUTES = {
  secondRealtorFill: '/fill/:transactionId/:openTransactionId' as const,
  incorrectEmail: '/incorrectEmail/:transactionId/:openTransactionId' as const,
  printRoot: '/print' as const,
  print: '/print/:transactionId/:type?' as const,
  eSignRoot: '/eSign' as const,
  eSign: '/eSign/:type/:transactionId/:formId?' as const
};

export default function RealtorRoutes(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Suspense fallback={<LinearProgress />}>
      <Switch>
        <ProtectedRoute role="realtor" path={`${path}${REALTOR_ROUTES.print}`}>
          <Print />
        </ProtectedRoute>
        <Route path={`${path}${REALTOR_ROUTES.secondRealtorFill}`}>
          <SecondRealtorFill />
        </Route>
        <Route path={`${path}${REALTOR_ROUTES.incorrectEmail}`}>
          <IncorrectEmail />
        </Route>
        <ProtectedRoute role="realtor" exact path={`${path}${REALTOR_ROUTES.eSign}`}>
          <ESign />
        </ProtectedRoute>
        <ProtectedRoute role="realtor" path={path}>
          <Dashboard />
        </ProtectedRoute>
      </Switch>
    </Suspense>
  );
}
