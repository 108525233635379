import { useCallback, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { useAuth } from 'hooks';
import type { QueryDocumentSnapshot } from 'firebase/firestore';
import { onSnapshot } from 'firebase/firestore';
import { getNotifications, newNotificationsQuery, seenNotifications } from 'services/firestore';
import { sortArrayByKey, stackNotifications } from 'utils';
import { NOTIFICATIONS_LOAD_LIMIT, TRANSACTIONS_WAIT_FOR_ELASTIC_TO_SYNC } from 'config/constants';

import type { HomelloNotification } from 'types';

interface UseNotifications {
  notifications: HomelloNotification[] | null;
  loadMoreNotifications: () => void;
  updateSeenNotifications: () => void;
  showLoadMore: boolean;
}

export function useNotifications(lastActivity: number): UseNotifications {
  const [notifications, setNotifications] = useState<HomelloNotification[] | null>(null);
  const [allNotifications, setAllNotifications] = useState<HomelloNotification[] | null>(null);
  const [lastLoadedNotification, setLastLoadedNotification] =
    useState<QueryDocumentSnapshot<HomelloNotification> | null>(null);
  const [startAfterCursor, setStartAfterCursor] =
    useState<QueryDocumentSnapshot<HomelloNotification> | null>(null);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  const uid = currentUser!.uid;

  const loadMoreNotifications = useCallback(() => {
    setStartAfterCursor(lastLoadedNotification);
  }, [lastLoadedNotification]);
  const { data, isSuccess } = useQuery(['notifications', startAfterCursor, uid], () =>
    getNotifications(uid, startAfterCursor)
  );

  function updateSeenNotifications() {
    const seenIds = allNotifications?.filter((n) => !n.seen).map((n) => n.id);
    if (currentUser?.isRealtor && seenIds && seenIds.length > 0) {
      seenNotifications(seenIds).finally(() => {
        setNotifications((prev) => {
          if (!prev) return prev;
          return prev.map((n) => {
            n.seen = true;

            return n;
          });
        });
      });
    }
  }

  useEffect(() => {
    const q = newNotificationsQuery(uid, lastActivity);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const queryResult: HomelloNotification[] = [];
      if (!querySnapshot.empty) {
        querySnapshot.forEach((item) =>
          queryResult.push({ ...item.data(), id: item.id, updateCount: 1 })
        );
      }

      if (queryResult.length === 0) return;

      setAllNotifications((prev) => {
        const notIds = prev?.map((n) => n.id) || [];
        const newNotifications = queryResult.filter((n) => !notIds.includes(n.id));

        return [...(prev || []), ...newNotifications];
      });
      setNotifications((prev) => {
        if (!prev || !startAfterCursor)
          return stackNotifications(sortArrayByKey(queryResult, 'dateCreated'));

        return stackNotifications([...prev, ...sortArrayByKey(queryResult, 'dateCreated')]);
      });

      setTimeout(() => {
        queryClient.invalidateQueries('notifications');
        queryClient.invalidateQueries('transactions');
      }, TRANSACTIONS_WAIT_FOR_ELASTIC_TO_SYNC);
    });
    return unsubscribe;
  }, [lastActivity, queryClient, startAfterCursor, uid]);

  useEffect(() => {
    if (isSuccess && data) {
      if (data.docs.length !== 0) {
        setLastLoadedNotification(data.docs[data.docs.length - 1]);
      }

      if (data.docs.length < NOTIFICATIONS_LOAD_LIMIT) {
        setShowLoadMore(false);
      } else {
        setShowLoadMore(true);
      }

      const queryResult: HomelloNotification[] = [];
      if (!data.empty) {
        data.forEach((item) => queryResult.push({ ...item.data(), id: item.id, updateCount: 1 }));
      }

      setAllNotifications((prev) => {
        const notIds = prev?.map((n) => n.id) || [];
        const newNotifications = queryResult.filter((n) => !notIds.includes(n.id));

        return [...(prev || []), ...newNotifications];
      });
      setNotifications((prev) => {
        if (!prev || !startAfterCursor)
          return stackNotifications(sortArrayByKey(queryResult, 'dateCreated'));

        return stackNotifications([...prev, ...sortArrayByKey(queryResult, 'dateCreated')]);
      });
    }
  }, [data, isSuccess, startAfterCursor]);

  useEffect(() => {
    if (notifications && notifications.length < 5 && showLoadMore) {
      loadMoreNotifications();
    }
  }, [loadMoreNotifications, notifications, showLoadMore]);

  return {
    updateSeenNotifications,
    notifications,
    loadMoreNotifications,
    showLoadMore
  };
}
