import { SUBFIELD_DESIGNATOR } from 'config/constants';
import { mlsDataFieldTypes } from 'utils';

import type {
  SubFields,
  SubField,
  DefaultSubFields,
  ClientTransaction,
  Profile,
  MlsDataFieldTypes,
  Listing,
  CustomFieldTypes,
  FormField
} from 'types';
import { FormFields } from 'types/newTransaction';

export const subFields: SubFields = {
  name: [
    {
      id: 'firstName',
      text: 'First Name',
      order: 0
    },
    {
      id: 'middleName',
      text: 'Middle Name',
      order: 1
    },
    {
      id: 'lastName',
      text: 'Last Name',
      order: 2
    }
  ],
  date: [
    {
      id: 'year(yyyy)',
      text: 'Year (YYYY)',
      format: 'YYYY',
      order: 2
    },
    {
      id: 'year(yy)',
      text: 'Year (YY)',
      format: 'YY',
      order: 5
    },
    {
      id: 'month',
      text: 'Month',
      format: 'MM',
      order: 1
    },
    {
      id: 'monthName',
      text: 'Month Name',
      format: 'MMMM',
      order: 4
    },
    {
      id: 'day',
      text: 'Day',
      format: 'DD',
      order: 0
    },
    {
      id: 'dayOrdinal',
      text: 'Day (Ordinal)',
      format: 'Do',
      order: 3
    }
  ],
  address: [
    {
      id: 'unitNumber',
      text: 'Unit Number',
      order: 0
    },
    {
      id: 'streetNumber',
      text: 'Street Number',
      order: 1
    },
    {
      id: 'street',
      text: 'Street',
      order: 2
    },
    {
      id: 'city',
      text: 'City',
      order: 3
    },
    {
      id: 'province',
      text: 'Province',
      order: 4
    },
    {
      id: 'postalCode',
      text: 'Postal Code',
      order: 5
    }
  ],
  text: [],
  time: [],
  boolean: [],
  number: [],
  phoneNumber: []
};

export const subTypes: SubFields = {
  name: [],
  date: [],
  address: [],
  text: [],
  time: [],
  boolean: [
    {
      id: 'checkbox',
      text: 'Checkbox'
    },
    {
      id: 'boolean',
      text: 'True or False'
    },
    {
      id: 'yes-no',
      text: 'Yes/No'
    }
  ],
  number: [
    {
      id: 'number',
      text: 'Number'
    },
    {
      id: 'number-text',
      text: 'Number as Text'
    }
  ],
  phoneNumber: []
};

export function getOrdinal(digit: number): string {
  switch (digit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export const defaultSubFields: DefaultSubFields = {
  name: [subFields.name[0], subFields.name[1], subFields.name[2]],
  date: [subFields.date[4], subFields.date[2], subFields.date[0]],
  address: [
    subFields.address[0],
    subFields.address[1],
    subFields.address[2],
    subFields.address[3],
    subFields.address[4],
    subFields.address[5]
  ],
  text: [],
  time: [],
  boolean: [],
  number: [],
  phoneNumber: []
};

export function getIdFromSubfield(filledWith: string, subField: SubField): string {
  return `${filledWith}${SUBFIELD_DESIGNATOR}${subField.id}`;
}

export function getValueFromProfile(
  profileFormData: FormFields,
  uniqueFields: {
    [fieldId: string]: FormField;
  }
) {
  return Object.entries(uniqueFields).reduce((output, [fieldId, field]) => {
    switch (field.type) {
      case 'TextBox':
        if (field.metaData.subFields && field.metaData.subFields.length) {
          const subfields = field.metaData.subFields.reduce((acc, subfield) => {
            const id = getIdFromSubfield(field.metaData.filledWith, subfield);
            return {
              ...acc,
              [id]: profileFormData[id]
            };
          }, {});
          return {
            ...output,
            ...subfields
          };
        }
        return {
          ...output,
          [fieldId]: profileFormData[fieldId]
        };
      default:
        return {
          ...output,
          [fieldId]: profileFormData[fieldId]
        };
    }
  }, {});
}

export function getValueFromAddress(
  formData: ClientTransaction['committedFormData'],
  filledWith: string,
  subFields: SubField[],
  fieldType: CustomFieldTypes,
  realtorValue?: string
):
  | string
  | {
      [key: string]: string;
    } {
  if (realtorValue) return realtorValue;
  if (fieldType === 'address') {
    return subFields.reduce(
      (acc, subField) => ({
        ...acc,
        [subField.id]: formData[getIdFromSubfield(filledWith, subField)]
      }),
      {}
    );
  }
}

export function getValueFromSubFields(
  formData: ClientTransaction['committedFormData'],
  filledWith: string,
  subFields: SubField[],
  fieldType: CustomFieldTypes,
  realtorValue?: string
): string {
  if (realtorValue) return realtorValue;
  return subFields
    .sort((a, b) => a.order - b.order)
    .reduce((prev, subField, index, arr) => {
      const dataId = getIdFromSubfield(filledWith, subField);
      const { id: subFieldId } = subField;
      const value = formData?.[dataId];
      if (typeof value === 'string') {
        if (value === '') return prev;

        if (index === 0) {
          if (fieldType === 'date' && subFieldId === 'dayOrdinal') {
            const day = +value;
            if (Number.isNaN(day)) return value;
            const lastDigit = day % 10;
            return `${day.toString()}${getOrdinal(lastDigit)}`;
          }
          if (
            subFieldId === 'streetNumber' && arr.length > index + 1
              ? arr[index + 1].id === 'street'
              : false
          ) {
            return `${value} `;
          }
          if (subFieldId === 'unitNumber' && value) {
            return `Unit ${value}, `;
          }
          return value;
        }

        switch (fieldType) {
          case 'address':
            if (subFieldId === 'unitNumber') {
              return prev
                ? prev + `Unit ${value}${index < arr.length - 1 ? ', ' : ''}`
                : `Unit ${value}${index < arr.length - 1 ? ', ' : ''}`;
            }
            if (
              subFieldId === 'streetNumber' && arr.length > index + 1
                ? arr[index + 1].id === 'street'
                : false
            ) {
              return `${prev}${value} `;
            }
            return prev
              ? prev + `${value}${index < arr.length - 1 ? ', ' : ''}`
              : `${value}${index < arr.length - 1 ? ', ' : ''}`;
          case 'date':
            if (subFieldId === 'dayOrdinal') {
              const day = +value;
              if (Number.isNaN(day)) return prev + ' - ' + value;
              const lastDigit = day % 10;
              return `${day.toString()}${getOrdinal(lastDigit)}` + '-' + prev;
            }

            return value + '-' + prev;
          case 'name':
          case 'number':
          case 'text':
          case 'time':
          case 'boolean':
            return prev + ' ' + value;
          case 'phoneNumber':
            return prev + ' ' + value;
          default:
            const exhaustiveCheck: never = fieldType;
            return exhaustiveCheck;
        }
      }

      return prev;
    }, '');
}

export function getValueFromSubFieldsOfProfile(
  profile: Profile,
  filledWith: string,
  subFields: SubField[],
  fieldType: CustomFieldTypes
): string {
  return subFields.reduce((prev, subField, index) => {
    const dataId = getIdFromSubfield(filledWith, subField);
    const value = profile?.[dataId]?.value ?? '';
    if (typeof value === 'string') {
      if (value === '') return prev;

      if (index === 0) {
        return value;
      }

      switch (fieldType) {
        case 'address':
          return prev + ', ' + value;
        case 'date':
          return prev + ' - ' + value;
        case 'name':
        case 'number':
        case 'text':
        case 'time':
        case 'boolean':
        case 'phoneNumber':
          return prev + ' ' + value;
        default:
          const exhaustiveCheck: never = fieldType;
          return exhaustiveCheck;
      }
    }

    return prev;
  }, '');
}

function getTypeOfDataField(dataField: string): keyof MlsDataFieldTypes {
  let mlsDataFieldType: keyof MlsDataFieldTypes;
  for (mlsDataFieldType in mlsDataFieldTypes) {
    const dataFields = mlsDataFieldTypes[mlsDataFieldType];
    if (dataFields.find((field) => field === dataField)) {
      return mlsDataFieldType;
    }
  }

  return 'text';
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export function getMlsDataFromSubFields(
  selectedMls: Listing | undefined,
  subFields: SubField[],
  dataField: string
): string {
  const dataFieldType = getTypeOfDataField(dataField);

  switch (dataFieldType) {
    case 'name':
      return '';
    case 'address':
      return subFields.reduce((prev, curr, index, arr) => {
        const subFieldId = curr.id;
        const value = selectedMls?.[curr.text];
        if (subFieldId === 'unitNumber') {
          const value = selectedMls?.['Unit Number'];
          if (!value) return prev;
          return prev
            ? prev + `Unit ${value}${index < arr.length - 1 ? ', ' : ''}`
            : `Unit ${value}${index < arr.length - 1 ? ', ' : ''}`;
        }
        if (!value) return prev;
        if (
          subFieldId === 'streetNumber' && arr.length > index + 1
            ? arr[index + 1].id === 'street'
            : false
        ) {
          return `${prev}${value} `;
        }
        return prev
          ? prev + `${value}${index < arr.length - 1 ? ', ' : ''}`
          : `${value}${index < arr.length - 1 ? ', ' : ''}`;
      }, '');
    case 'date':
      const fieldValue = selectedMls?.[dataField] as unknown as number | undefined;
      if (!fieldValue) return '';
      if (typeof fieldValue !== 'number') return '';

      const date = new Date(fieldValue * 1000);
      return subFields.reduce((prev, curr, index) => {
        let newValue = '';
        switch (curr.id) {
          case 'day':
            newValue = date.getDate().toString();
            break;
          case 'dayOrdinal':
            const day = date.getDate();
            const lastDigit = day % 10;
            newValue = `${day.toString()}${getOrdinal(lastDigit)}`;
            break;
          case 'month':
            newValue = (date.getMonth() + 1).toString();
            break;
          case 'monthName':
            newValue = monthNames[date.getMonth()];
            break;
          case 'year(yy)':
            newValue = (date.getFullYear() % 100).toString();
            break;
          case 'year(yyyy)':
            newValue = date.getFullYear().toString();
            break;
        }
        if (index === 0) {
          return `${prev}${newValue}`;
        }
        return `${prev} - ${newValue}`;
      }, '');
    case 'number':
    case 'phoneNumber':
    case 'text':
    case 'time':
    case 'boolean':
      return '';
    default:
      const exhaustiveCheck: never = dataFieldType;
      return exhaustiveCheck;
  }
}
