import warningIcon from 'assets/icons/warning.svg';

interface Props {
  text: string;
  button: string;
  onClick: () => void;
}

export function AlertItem({ text, button, onClick }: Props): JSX.Element {
  return (
    <>
      <div className="flex flex-nowrap justify-between items-center py-6 pl-6 pr-4">
        <div className="flex flex-nowrap flex-1 justify-start items-center">
          <div className="mr-[22px]">
            <img src={warningIcon} className="w-6 h-6" />
          </div>
          <div className="flex-1 text-nav text-gray-6 font-semibold mr-4">{text}</div>
        </div>
        <div className="text-list2 text-green-1 cursor-pointer hover:opacity-70" onClick={onClick}>
          {button}
        </div>
      </div>
      <div className="w-full h-px bg-gray-2"></div>
    </>
  );
}
