import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import { getProfile } from 'services/firestore';

import type { Profile } from 'types';

interface UseRealtorProfile {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  profile: Profile | null;
}

export function useRealtorProfile(uid: string): UseRealtorProfile {
  const [profile, setProfileState] = useState<Profile | null>(null);

  const profileQuery = useQuery(['realtorProfile', { uid }], () => getProfile(uid));

  useEffect(() => {
    if (profileQuery.isSuccess) {
      const result = profileQuery.data;
      if (result.exists()) {
        setProfileState(result.data());
      }
    }
  }, [profileQuery.data, profileQuery.isSuccess]);

  return {
    isLoading: profileQuery.isLoading,
    isError: profileQuery.isError,
    isSuccess: profileQuery.isSuccess,
    profile: profile
  };
}
