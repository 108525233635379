import { useMutation, useQueryClient } from 'react-query';

import { useAuth } from 'hooks';
import { setLastActivity as setter } from 'services/firestore';

interface UseSetLastActivity {
  setLastActivity: () => Promise<void>;
}

export function useSetLastActivity(): UseSetLastActivity {
  const { currentUser } = useAuth();
  const uid = currentUser!.uid;
  const queryClient = useQueryClient();

  const setLastActivityMutation = useMutation<void, unknown, void, unknown>(() => setter(uid), {
    onSettled: () => {
      queryClient.invalidateQueries('lastActivity');
    }
  });

  function setLastActivity() {
    return setLastActivityMutation.mutateAsync();
  }

  return {
    setLastActivity
  };
}
