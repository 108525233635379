import ReactTimeAgo from 'react-time-ago';
import { useHistory } from 'react-router-dom';

import { closeAllPopups, useAuth } from 'hooks';
import { eventToText } from 'utils';
import { HASH } from 'routers/Hash';
import { ROOT_ROUTES } from 'routers/Routes';

import type { HomelloNotification } from 'types';
import { DocumentFileWithMetaData } from 'types';
import { ButtonM } from './Button';
import { getIdFileByPath } from 'services/storage';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

interface Props {
  notification: HomelloNotification;
}

export function NotificationsItem({ notification }: Props): JSX.Element {
  const { currentUser } = useAuth();
  const history = useHistory();
  const [fileLink, setFileLink] = useState<DocumentFileWithMetaData | null>();

  const isDownloadNotification = useMemo(() => {
    return notification.event === 'pdfFormCreated' || notification.event === 'zipCompleted';
  }, [notification]);

  const fileLinkQuery = useQuery(
    ['getFormFileLink', notification.metaData],
    () => {
      const { transactionId, formId, formName, transactionName } = notification.metaData || {};
      return getIdFileByPath(
        notification.event === 'pdfFormCreated'
          ? `${currentUser?.uid}/${transactionId}/${transactionId}/${formId}/${formName}.pdf`
          : `${currentUser?.uid}/${transactionId}/${transactionName}.zip`
      );
    },
    {
      enabled: notification.event === 'pdfFormCreated' || notification.event === 'zipCompleted'
    }
  );

  function handleNotificationClick() {
    if (notification.event === 'applicantIdUploaded') {
      const transactionId = notification?.metaData?.transactionId;

      if (!transactionId) return;

      closeAllPopups();
      history.push(`${ROOT_ROUTES.realtor}?transactionId=${transactionId}`);
      return;
    }

    const openTransactionId = notification?.metaData?.transactionId;

    if (!openTransactionId) return;

    closeAllPopups();
    history.push(`${ROOT_ROUTES.realtor}?transactionId=${openTransactionId}`);
  }

  useEffect(() => {
    if (fileLinkQuery.data) setFileLink(fileLinkQuery.data);
  }, [fileLinkQuery.data, fileLinkQuery.isSuccess]);

  return (
    <>
      <div
        className={`flex w-full flex-nowrap justify-between items-center ${
          !isDownloadNotification ? 'hover:opacity-70 cursor-pointer h-14' : ''
        }`}
        onClick={() => {
          if (!isDownloadNotification) handleNotificationClick();
        }}
      >
        <div className="space-y-1 relative">
          {!notification.seen && (
            <div className="absolute left-[-10px] top-3 w-[6px] h-[6px] bg-blue-1 rounded-full" />
          )}
          {notification.event !== 'applicantIdUploaded' && (
            <div
              className={`text-p3 text-gray-6 ${
                notification.seen ? 'text-[#555555] font-medium' : 'text-[#333333] font-semibold'
              }`}
            >
              {isDownloadNotification
                ? 'Your Download is ready'
                : notification?.metaData?.transactionName}
            </div>
          )}
          {notification.event === 'applicantIdUploaded' && (
            <div className="flex flex-col gap-1">
              <div className="text-p3 font-semibold text-gray-6">{notification?.source}</div>
              <div className="text-list2 text-gray-5">
                {eventToText(notification.event, notification.source, notification)}
              </div>
            </div>
          )}
          {notification.event !== 'applicantIdUploaded' && !isDownloadNotification && (
            <div className="flex flex-col gap-1">
              {notification.event !== 'incorrectEmail' && !notification.event.startsWith('all') && (
                <div className="text-list2 text-gray-5">{notification.source}</div>
              )}
              <div className="text-list2 text-gray-5">
                {eventToText(notification.event, notification.source, notification)}
              </div>
            </div>
          )}
          <div className="flex items-center gap-2">
            <div className="text-list2 text-gray-5">{notification.updateCount} update(s)</div>
            <span className="w-1 h-1 bg-gray-5 rounded-full" />
            <div className="text-list2 text-gray-5">
              <ReactTimeAgo date={notification.dateCreated} locale="en-CA" />
            </div>
          </div>
          {isDownloadNotification && (
            <a target="_blank" href={fileLink?.url}>
              <ButtonM className="!mt-2 bg-white border border-[#CCCCCC] text-gray-5 hover:text-black">
                Download
              </ButtonM>
            </a>
          )}
        </div>
      </div>
      <div className="w-full h-px my-5 bg-gray-2"></div>
    </>
  );
}
