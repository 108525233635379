import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import { getProfilePicture } from 'services/storage';

interface UseSettings {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  profilePicURL: string;
}

export function useRealtorProPic(uid: string): UseSettings {
  const [profilePicURL, setProfilePicURL] = useState('');

  const profilePicQuery = useQuery(['profilePic', { uid }], () => {
    return new Promise<string>((res) => {
      getProfilePicture(uid)
        .then((url) => {
          res(url);
        })
        .catch(() => {
          //If the profile picture is not uploaded, there is no need to reject the reqeust.
          //The catch only causes the error not to be thrown
          res('');
        });
    });
  });

  useEffect(() => {
    if (profilePicQuery.isSuccess) {
      const result = profilePicQuery.data;

      if (result) {
        setProfilePicURL(result);
      }
    }
  }, [profilePicQuery.data, profilePicQuery.isSuccess]);

  return {
    isLoading: profilePicQuery.isLoading,
    isSuccess: profilePicQuery.isSuccess,
    isError: profilePicQuery.isError,
    profilePicURL
  };
}
