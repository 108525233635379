import type { InitialRealtorProfile } from 'config/initialRealtorProfile';

import { Profile } from 'types';

function generateGenericProfileItem(
  prop: string,
  text: Profile['fieldName']['text'],
  value: Profile['fieldName']['value'],
  type: Profile['fieldName']['type']
): Profile {
  return {
    [prop]: {
      value,
      text: text,
      type: type,
      description: ''
    }
  };
}

function generateTextItem(prop: string, text: string, value: string): Profile {
  return generateGenericProfileItem(prop, text, value, 'text');
}

export function generateProfileItem(prop: keyof InitialRealtorProfile, value: string): Profile {
  switch (prop) {
    case 'Name':
      return generateTextItem(prop, 'Name', value);
    case 'Name-$^$-firstName':
      return generateTextItem(prop, 'First Name', value);
    case 'Name-$^$-middleName':
      return generateTextItem(prop, 'Middle Name', value);
    case 'Name-$^$-lastName':
      return generateTextItem(prop, 'Last Name', value);
    case 'Phone':
      return generateTextItem(prop, 'Phone Number', value);
    case 'Realtor ID':
      return generateTextItem(prop, 'Realtor ID', value);
    case 'Brokerage Name':
      return generateTextItem(prop, 'Brokerage Name', value);
    case 'Website':
      return generateTextItem(prop, 'Website', value);
    case 'Email':
      return generateTextItem(prop, 'Email', value);
    default:
      const exhaustiveCheck: never = prop;
      return exhaustiveCheck;
  }
}

export function getFullName(profile: Profile): string {
  const nameParts = [
    profile?.['Name-$^$-firstName'].value ?? '',
    profile?.['Name-$^$-middleName'].value ?? '',
    profile?.['Name-$^$-lastName'].value ?? ''
  ];

  return nameParts.reduce((prev, name, index) => {
    if (name === '') return prev;

    if (index === 0) {
      return name;
    }
    return prev + ' ' + name;
  }, '');
}
