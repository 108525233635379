import { Link, useRouteMatch } from 'react-router-dom';
import type { ComponentPropsWithoutRef } from 'react';
import { useEffect } from 'react';

interface Props extends ComponentPropsWithoutRef<'div'> {
  children?: JSX.Element | string;
  to: string;
  openParent?: () => void;
  exact?: boolean;
  className?: string;
  disabled?: boolean;
}

export function CustomNavLink({
  children,
  to,
  openParent,
  className = 'text-nav',
  exact = true,
  disabled = false,
  ...props
}: Props): JSX.Element {
  const match = useRouteMatch({
    path: to,
    exact: exact
  });

  useEffect(() => {
    if (match && openParent) {
      openParent();
    }
  }, [match, openParent]);

  return (
    <div className={`${className} ${match ? 'text-black' : 'text-gray-5'}`} {...props}>
      {!disabled && <Link to={to}>{children}</Link>}
      {disabled && children}
    </div>
  );
}
