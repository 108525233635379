import { forwardRef } from 'react';
import type { ComponentPropsWithoutRef, LegacyRef } from 'react';

interface ButtonIconProps extends ComponentPropsWithoutRef<'button'> {
  icon: string;
}

export const ButtonIcon = forwardRef(
  (
    { children, className = '', icon, disabled, ...props }: ButtonIconProps,
    ref: LegacyRef<HTMLButtonElement>
  ) => (
    <button
      disabled={disabled}
      className={`h-5 focus:outline-none ${className} ${
        disabled ? 'bg-[#EEEEEE]' : 'hover:opacity-70'
      }`}
      ref={ref}
      {...props}
    >
      <div className="w-full h-full flex justify-center items-center">
        <img src={icon} alt="add" className="w-5 h-5 mr-[10px]" />
        <span className={`font-semibold text-link1 ${disabled ? 'text-[#555555]' : ''}`}>
          {children}
        </span>
      </div>
    </button>
  )
);
