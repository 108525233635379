import { createContext, useState, useCallback } from 'react';
import Drawer from '@mui/material/Drawer';
import Backdrop from '@mui/material/Backdrop';

import { useConfirm } from 'hooks';

import closeIcon from 'assets/icons/close.svg';

import type { ReactNode } from 'react';
import { Divider } from '@mui/material';

interface SetDrawerProps {
  content: JSX.Element;
  header?: string | boolean;
  size?: DrawerSize;
  onClose?: () => void;
  confirmOnClose?: boolean;
  hideClose?: boolean;
  preventClose?: boolean;
}

type DrawerSize = 'Medium' | 'Large';

interface DrawerClose {
  fn: (() => void) | undefined;
}

interface DrawerContent {
  component: ReactNode;
  open: boolean;
}

export interface ContextProps {
  openDrawer: ({ content, size, onClose, confirmOnClose }: SetDrawerProps) => void;
  closeDrawer: () => void;
}

interface Props {
  children: ReactNode;
}

export const DrawerContext = createContext<ContextProps>({} as ContextProps);

export function DrawerProvider({ children }: Props): JSX.Element {
  const [drawerConfirmOnClose, setDrawerConfirmOnClose] = useState(false);
  const [drawerHeader, setDrawerHeader] = useState<boolean | string>(false);
  const [drawerClose, setDrawerClose] = useState<DrawerClose>({ fn: undefined });
  const [hideClose, setHideClose] = useState<boolean>(false);
  const [preventClose, setPreventClose] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<DrawerContent>({
    component: null,
    open: false
  });
  const { getConfirmation } = useConfirm();

  const openDrawer = useCallback(
    ({ content, header, onClose, confirmOnClose, hideClose, preventClose }: SetDrawerProps) => {
      setDrawerContent({ component: content, open: true });
      if (typeof header === 'string') {
        setDrawerHeader(header);
      } else {
        setDrawerHeader(false);
      }

      if (typeof onClose === 'function') {
        setDrawerClose({ fn: onClose });
      } else {
        setDrawerClose({ fn: undefined });
      }

      setDrawerConfirmOnClose(confirmOnClose);
      setHideClose(hideClose);
      setPreventClose(preventClose);
    },
    []
  );

  const closeDrawer = useCallback(() => {
    if (typeof drawerClose.fn === 'function') {
      drawerClose.fn();
    }
    setDrawerContent((drawer) => ({ ...drawer, open: false }));
  }, [drawerClose]);

  const handleDrawerClose = useCallback(async () => {
    if (drawerConfirmOnClose) {
      const shouldClose = await getConfirmation({
        title: 'Close Drawer',
        text: 'Are you sure you want to close this drawer?',
        button: 'Yes',
        color: 'orange'
      });

      if (!shouldClose) return;
    }

    console.log('1');
    closeDrawer();
  }, [getConfirmation, closeDrawer, drawerConfirmOnClose]);

  const value: ContextProps = {
    openDrawer,
    closeDrawer
  };

  return (
    <DrawerContext.Provider value={value}>
      {children}
      <Drawer
        anchor="right"
        className="flex items-center justify-center"
        open={drawerContent.open}
        onClose={preventClose ? undefined : handleDrawerClose}
        aria-labelledby="drawer-drawer-title"
        aria-describedby="drawer-drawer-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200
        }}
      >
        <div
          className={`relative flex flex-col items-stretch flex-1 w-full max-w-[100vw] max-h-screen h-[calc(100vh-2.5rem)] sm:h-auto overflow-y-auto bg-white rounded-t-3xl sm:rounded-3xl focus:outline-none ${
            drawerHeader ? 'py-5' : 'px-[30px] sm:px-[50px] py-10'
          }`}
        >
          {!hideClose && (
            <div
              className="absolute right-5 top-5 cursor-pointer hover:opacity-70"
              onClick={handleDrawerClose}
            >
              <img src={closeIcon} className="w-6 h-6" alt="Close" />
            </div>
          )}
          {drawerHeader && (
            <div>
              <div className="flex items-center pt-3 px-10 pr-16">
                <h3 className="text-h2 font-bold">{drawerHeader}</h3>
              </div>
              <Divider className="!mt-10 !mb-8" />
            </div>
          )}
          {drawerContent.component}
        </div>
      </Drawer>
      <div id="drawer-container" />
    </DrawerContext.Provider>
  );
}
