import { Suspense, lazy } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';

import ProtectedRoute from 'routers/ProtectedRoute';
import LinearProgress from 'components/LinearProgress';

const Dashboard = lazy(() => import('pages/admin/Dashboard'));

export default function AdminRoutes(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Suspense fallback={<LinearProgress />}>
      <Switch>
        <ProtectedRoute role="admin" path={path}>
          <Dashboard />
        </ProtectedRoute>
      </Switch>
    </Suspense>
  );
}
