import { FC } from 'react';
import emptyIcon from 'assets/images/404.svg';
import pageNotFound from 'assets/images/page-not-found.svg';
import noNotification from 'assets/images/no-notif.svg';
import noTransaction from 'assets/images/no-transition.svg';

export enum NotFoundType {
  noTransaction,
  fourOFour,
  notFound,
  noNotification
}

interface Props {
  text: string;
  type?: NotFoundType;
  className?: string;
}

export const NotFound: FC<Props> = ({
  text,
  type = NotFoundType.noTransaction,
  className = 'w-[280px]'
}) => {
  const showImage = () => {
    switch (type) {
      case NotFoundType.fourOFour:
        return <img src={emptyIcon} alt="404" className={className} />;
      case NotFoundType.noTransaction:
        return <img src={noTransaction} alt="No Transaction" className={className} />;
      case NotFoundType.noNotification:
        return <img src={noNotification} alt="No Notification" className={className} />;
      default:
        return <img src={pageNotFound} alt="Page not found" className={className} />;
    }
  };

  return (
    <div className="flex flex-col items-center p-6">
      <div>{showImage()}</div>
      <div className="text-p2 text-gray-5 mt-4">{text}</div>
    </div>
  );
};
