import { functions, httpsCallable } from 'services/firebase';
import type { HttpsCallableResult, DownloadTransactionResponse } from 'types';

export function downloadTransaction(
  transactionId: string,
  formIds: string[],
  documentIds: string[],
  type?: 'eSign' | 'transaction'
): Promise<HttpsCallableResult<DownloadTransactionResponse>> {
  const downloadTransaction = httpsCallable<
    {
      transactionId: string;
      formIds: string[];
      documentIds: string[];
      type: 'eSign' | 'transaction';
    },
    DownloadTransactionResponse
  >(functions, 'downloadTransaction');
  return downloadTransaction({ transactionId, documentIds, formIds, type });
}
