import { ReactNode, useEffect, useRef } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from 'hooks';
import { ROOT_ROUTES } from 'routers/Routes';
import { AUTH_ROUTES } from 'routers/AuthRoutes';

import type { RouteComponentProps, RouteProps } from 'react-router-dom';
import type { Role } from 'types';

interface Props extends Omit<RouteProps, 'children'> {
  role: Role;
  children?: ReactNode;
}

export default function ProtectedRoute({ children, role, ...rest }: Props): JSX.Element {
  const { currentUser } = useAuth();
  const prevUserRole = useRef<Role | undefined>();

  useEffect(() => {
    if (currentUser) {
      if (currentUser.isClient) {
        prevUserRole.current = 'client';
      } else {
        prevUserRole.current = 'realtor';
      }
    }
  }, [currentUser]);

  function render({ location }: RouteComponentProps) {
    let loginPath: string = AUTH_ROUTES.login;
    if (prevUserRole.current === 'client') {
      loginPath = AUTH_ROUTES.clientLogin;
    }

    if (!currentUser) {
      return (
        <Redirect
          to={{
            pathname: ROOT_ROUTES.auth + loginPath,
            state: { from: location }
          }}
        />
      );
    }

    if (role === 'realtor' && currentUser?.isRealtor && !currentUser?.isAdmin) {
      return children;
    }

    if (role === 'client' && currentUser?.isClient) {
      return children;
    } else if (currentUser?.isRealtor && !currentUser?.isAdmin && role !== 'user') {
      return <Redirect to={ROOT_ROUTES.realtor} />;
    }

    if (role === 'user' && (currentUser?.isRealtor || currentUser?.isClient)) {
      return children;
    }

    if (role === 'admin' && currentUser?.isAdmin) {
      return children;
    } else if (currentUser?.isAdmin && role !== 'admin') {
      return <Redirect to={ROOT_ROUTES.admin} />;
    }

    return (
      <Redirect
        to={{
          pathname: '/',
          state: {}
        }}
      />
    );
  }

  return <Route {...rest} render={render} />;
}
