import Joi from 'joi';

import { FieldItem } from 'types';
import { KeyboardEvent } from 'react';

function validateText(value: string): boolean {
  const error = value.trim() === '';

  return !error;
}

function validateDate(value: string): boolean {
  const { error } = Joi.date().validate(value);

  return !error;
}

function validateNumber(value: string): boolean {
  const { error } = Joi.number().validate(value);

  return !error;
}

export function blockInvalidChar(e: KeyboardEvent) {
  return ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
}

export function validateEmail(value: string): boolean {
  const { error } = Joi.string()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .validate(value);

  return !error;
}

export function validateMobile(value: string): boolean {
  const { error } = Joi.number().validate(value);

  return !error;
}

export function validatePostalCode(value: string): boolean {
  const { error } = Joi.string()
    .pattern(new RegExp('^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$'))
    .validate(value);

  return !error;
}

export function validateTime(value: string): boolean {
  const { error } = Joi.string()
    .regex(/^([0-9]{2}):([0-9]{2})$/)
    .validate(value);

  return !error;
}

export function validatePassword(password: string): { valid: boolean; message: string }[] {
  const errors = [];

  if (password === '') {
    errors.push({ valid: false, message: 'Password cannot be empty' });
  }

  if (password.length < 8) {
    errors.push({ valid: false, message: 'Password should be at least 8 characters.' });
  }

  if (!/\d/.test(password)) {
    errors.push({ valid: false, message: 'Password should contain at least one number.' });
  }

  if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) {
    errors.push({ valid: false, message: 'Password should contain at least one symbol.' });
  }

  return errors;
}

export function validateField(
  value: FieldItem['value'],
  validator: FieldItem['type'],
  allowEmpty: boolean
): { valid: boolean; message: string } {
  let valid = false;
  let message = '';

  if (value === undefined) {
    valid = false;
    message = 'Value is undefined.';
    return { valid, message };
  }

  if (value === '' && allowEmpty) {
    valid = true;
    message = '';
    return { valid, message };
  }

  switch (validator) {
    case 'text':
      valid = validateText(value);
      message = 'Please enter a valid Text';
      break;
    case 'name':
      valid = validateText(value);
      message = 'Please enter a valid Text';
      break;
    case 'address':
      valid = validateText(value);
      message = 'Please enter a valid Address';
      break;
    case 'date':
      valid = validateDate(value);
      message = 'Please enter a valid Date';
      break;
    case 'time':
      valid = validateTime(value);
      message = 'Please enter a valid Time';
      break;
    case 'boolean':
      valid = true;
      break;
    case 'number':
      valid = validateNumber(value);
      message = 'Please enter a valid Number';
      break;
    case 'phoneNumber':
      valid = true;
      break;
    default:
      const exhaustiveCheck: never = validator;
      return exhaustiveCheck;
  }

  return { valid, message };
}
