import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { TRANSACTIONS_DEFAULT_PAGE_SIZE, TRANSACTIONS_SEARCH_DEBOUNCE } from 'config/constants';

import { type TransactionSort } from 'types';
import { ExtendedTransaction } from 'types/newTransaction';
import { searchTransactions } from 'services/transaction';
import { useEmitter } from './useEmitter';
import useURLQuery from './useURLQuery';
import { toNumber } from 'utils';

interface UseTransactions {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  transactions: ExtendedTransaction[];
  transactionsCount: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

export function useTransactions(
  query = '',
  limit = TRANSACTIONS_DEFAULT_PAGE_SIZE,
  sort: TransactionSort[] = ['_date'],
  ascending = false
): UseTransactions {
  const [transactions, setTransactions] = useState<ExtendedTransaction[]>([]);
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchLoading, setSearchLoading] = useState(true);
  const urlQuery = useURLQuery<{ page: number }>({
    page(value) {
      return toNumber(value) || 1;
    }
  });
  const page = urlQuery.page;
  const setPage = useCallback(
    (value: React.SetStateAction<number>) => {
      console.log({ value, urlQuery: urlQuery.page });
      if (typeof value === 'number') {
        urlQuery.page = value;
      } else {
        urlQuery.page = value(urlQuery.page);
      }
    },
    [urlQuery]
  );
  // const { currentUser } = useAuth();
  // const uid = currentUser!.uid;

  // const transactionsQuery = useQuery(
  //   ['transactions', { uid }],
  //   () => {
  //     return getTransaction(uid);
  //   },
  //   {
  //     enabled: page === 1,
  //     staleTime: 0
  //   }
  // );

  useEmitter<ExtendedTransaction>('updateTransactions', (data) => {
    setTransactions((prev) => [data, ...prev]);
  });

  useEmitter<ExtendedTransaction>('updateTransactionsAppend', (data) => {
    setTransactions((prev) => [...prev, data]);
  });

  useEmitter<ExtendedTransaction>('updateTransaction', (transaction) => {
    setTransactions((prev) =>
      prev.map((a) => (a.id === transaction.id ? { ...a, ...transaction } : a))
    );
  });
  const transactionsSearch = useQuery(
    ['transactions-search', { searchQuery, page, limit, sort, ascending }],
    () => {
      setSearchLoading(true);
      return searchTransactions({
        query: searchQuery,
        from: (page - 1) * limit,
        size: limit,
        sort,
        asc: ascending
      });
    }
  );

  // const setTransactionMutation = useMutation<void, unknown, SetTransactionMutation, unknown>(
  //   ({ transaction, openTransaction, clientTransactions }) =>
  //     setter(transaction, openTransaction, clientTransactions),
  //   {
  //     onSettled: () => {
  //       setTimeout(() => {
  //         queryClient.invalidateQueries('transactions');
  //         queryClient.invalidateQueries('clientTransactions');
  //         queryClient.invalidateQueries('openTransactions');
  //       }, TRANSACTIONS_WAIT_FOR_ELASTIC_TO_SYNC);
  //     }
  //   }
  // );

  useEffect(() => {
    const result = transactionsSearch.data;
    if (transactionsSearch.isSuccess && result?.data) {
      console.log('first');
      if (page > 1 || sort) {
        setSearchLoading(false);
        setTransactions(result.data.data);
      }
      setTransactionsCount(result.data.count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionsSearch.data, transactionsSearch.isSuccess]);

  useEffect(() => {
    let setQueryHandle: NodeJS.Timeout;
    if (page !== 1 || searchQuery !== query) {
      setQueryHandle = setTimeout(() => {
        setPage(page && page > 0 ? page : 1);
        setSearchQuery(query);
      }, TRANSACTIONS_SEARCH_DEBOUNCE);
    }

    return () => {
      clearTimeout(setQueryHandle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query, searchQuery]);

  return {
    isLoading: searchLoading || transactionsSearch.isLoading,
    isError: transactionsSearch.isError,
    isSuccess: transactionsSearch.isSuccess,
    transactions: transactions,
    transactionsCount,
    page,
    setPage
  };
}
