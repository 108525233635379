import { SUBFIELD_DESIGNATOR } from 'config/constants';
import type { HTMLInputTypeAttribute } from 'react';
import type { CustomFieldTypes, Form, FormField } from 'types';

export function isMlsFieldInForm(forms: Form[]) {
  for (let index = 0; index < forms?.length; index++) {
    if (!forms[index] || !forms[index].fields) {
      continue;
    }
    const fields = forms[index].fields;
    for (const field of Object.values(fields)) {
      if (field.type === 'MlsBox') {
        return true;
      }
    }
  }
  return false;
}

export function calculateFieldId(field: FormField, fieldId: string): string | string[] {
  if (field.type === 'TextBox' || field.type === 'MlsBox') {
    if (field.metaData.subFields && field.metaData.subFields.length) {
      return field.metaData.subFields.map(
        ({ id }) => `${field.metaData.filledWith}${SUBFIELD_DESIGNATOR}${id}`
      );
    }
    return field.metaData.filledWith;
  }
  return fieldId;
}

export function getFieldInputType(type: CustomFieldTypes): HTMLInputTypeAttribute {
  switch (type) {
    case 'name':
      return 'text';
    case 'text':
      return 'text';
    case 'address':
      return 'text';
    case 'date':
      return 'date';
    case 'number':
      return 'text';
    case 'boolean':
      return 'boolean';
    case 'time':
      return 'time';
    case 'phoneNumber':
      return 'tel';
    default:
      const exhaustiveCheck: never = type;
      return exhaustiveCheck;
  }
}
