import { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { useAuth } from 'hooks';
import { getSettings, setClientSettings as firestoreSetSettings } from 'services/firestore';
import { getProfilePicture, setProfilePicture } from 'services/storage';
import { initialClientSettings } from 'config/initialClientSettings';

import type { ClientSettings, UploadResult } from 'types';

interface UseSettings {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  settings: ClientSettings | null;
  profilePicURL: string;
  setSettings: (settings: ClientSettings) => Promise<void>;
  setProfilePic: (profilePic: File) => Promise<UploadResult>;
}

export function useClientSettings(): UseSettings {
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  const uid = currentUser!.uid;
  const [settings, setSettingsState] = useState<ClientSettings | null>(null);
  const [profilePicURL, setProfilePicURL] = useState('');

  const setProfilePicMutation = useMutation<UploadResult, unknown, File, unknown>(
    (profilePic) => setProfilePicture(uid, profilePic),
    {
      onSettled: () => {
        queryClient.invalidateQueries('profilePic');
      }
    }
  );

  const settingsMutation = useMutation<void, unknown, ClientSettings, unknown>(
    (settings) => firestoreSetSettings(uid, settings),
    {
      onSettled: () => {
        queryClient.invalidateQueries('settings');
      }
    }
  );

  function setSettings(settings: ClientSettings) {
    return settingsMutation.mutateAsync(settings);
  }

  function initializeSettings() {
    return setSettings(initialClientSettings);
  }

  const settingsQuery = useQuery('settings', () => getSettings(uid), {
    onSuccess: (data) => {
      // Check if user Settings is empty so this is the first time user has logged in
      // If so, we should populate some initial data for the user
      const exists = data.exists();
      if (!exists) {
        initializeSettings();
      }
    }
  });

  const profilePicQuery = useQuery('profilePic', () => {
    return new Promise<string>((res) => {
      getProfilePicture(uid)
        .then((url) => {
          res(url);
        })
        .catch(() => {
          //If the profile picture is not uploaded, there is no need to reject the reqeust.
          //The catch only causes the error not to be thrown
          res('');
        });
    });
  });

  function setProfilePic(profilePic: File) {
    return setProfilePicMutation.mutateAsync(profilePic);
  }

  useEffect(() => {
    if (settingsQuery.isSuccess) {
      const result = settingsQuery.data;
      if (result.exists()) {
        const settings = result.data() as ClientSettings;
        setSettingsState(settings);
      }
    }
  }, [settingsQuery.data, settingsQuery.isSuccess, uid]);

  useEffect(() => {
    if (profilePicQuery.isSuccess) {
      const result = profilePicQuery.data;
      setProfilePicURL(result);
    }
  }, [profilePicQuery.data, profilePicQuery.isSuccess]);

  return {
    isLoading: settingsQuery.isLoading || profilePicQuery.isLoading,
    isSuccess: settingsQuery.isSuccess && profilePicQuery.isSuccess,
    isError: settingsQuery.isError || profilePicQuery.isError,
    settings,
    setSettings,
    profilePicURL,
    setProfilePic
  };
}
