import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import { useAuth } from 'hooks';
import { deleteIdFile, getIdFile, setIdFile } from 'services/storage';

import type { UploadTask, FullMetadata } from 'types';
import type { FilePondFile } from 'filepond';
import type { UseQueryResult } from 'react-query';

interface IdFileWithMetaData {
  metaData: FullMetadata;
  url: string;
}

interface UseIdUploader {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  idUploader: (file: FilePondFile['file']) => UploadTask;
  idRemover: () => Promise<void>;
  getIdFileQuery: UseQueryResult<IdFileWithMetaData, unknown>;
  idURL: IdFileWithMetaData | null;
}

export function useIdUploader(): UseIdUploader {
  const { currentUser } = useAuth();
  const uid = currentUser!.uid;
  const email = currentUser!.email;
  const [idURL, setIdURL] = useState<IdFileWithMetaData | null>(null);

  function idUploader(file: FilePondFile['file']): UploadTask {
    return setIdFile(email ?? '', uid, file);
  }

  function idRemover() {
    setIdURL(null);
    return deleteIdFile(email ?? '', uid);
  }

  const getIdFileQuery = useQuery(
    'clientId',
    () => {
      return getIdFile(email ?? '', uid);
    },
    { enabled: false }
  );

  useEffect(() => {
    if (getIdFileQuery.isSuccess && getIdFileQuery.isFetchedAfterMount) {
      setIdURL(getIdFileQuery.data);
    }
  }, [getIdFileQuery.data, getIdFileQuery.isFetchedAfterMount, getIdFileQuery.isSuccess]);

  useEffect(() => {
    setIdURL(null);
  }, []);

  return {
    isLoading: getIdFileQuery.isLoading || getIdFileQuery.isFetching,
    isError: getIdFileQuery.isError,
    isSuccess: getIdFileQuery.isSuccess,
    getIdFileQuery,
    idUploader,
    idRemover,
    idURL
  };
}
