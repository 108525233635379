import { db, writeBatch } from 'services/firebase';
import {
  actionsDoc,
  applicantDoc,
  clientTransactionDoc,
  draftFormDoc,
  fieldsDoc,
  formDoc,
  notificationsDoc,
  openTransactionDoc,
  transactionDoc
} from 'services/firestore';

import type { Applicant, ClientTransaction, Form, OpenTransaction, Transaction } from 'types';
import { FieldItem } from 'types';

export function mutateAllForms(forms: Form[], draftForms: Form[]): Promise<void> {
  const batch = writeBatch(db);

  forms.forEach((form) => {
    batch.set(formDoc(form.id), form);
  });

  draftForms.forEach((form) => {
    batch.set(draftFormDoc(form.id), form);
  });

  return batch.commit();
}

export function setFields(fields: FieldItem[]): Promise<void> {
  const batch = writeBatch(db);

  fields.forEach((field) => {
    batch.set(fieldsDoc(field.text), field);
  });

  return batch.commit();
}

export function setApplicants(applicants: Applicant[]): Promise<void> {
  const batch = writeBatch(db);

  applicants.forEach((applicant) => {
    batch.set(applicantDoc(applicant.id), applicant);
  });

  return batch.commit();
}

export function setTransaction(
  transaction: Transaction,
  openTransaction: OpenTransaction,
  clientTransactions: ClientTransaction[]
): Promise<void> {
  const batch = writeBatch(db);

  batch.set(transactionDoc(transaction.id), transaction);
  batch.set(openTransactionDoc(transaction.id, openTransaction.id), openTransaction);
  clientTransactions.forEach((clientTransaction) => {
    batch.set(
      clientTransactionDoc(
        clientTransaction.transactionId,
        openTransaction.id,
        clientTransaction.applicantId
      ),
      clientTransaction
    );
  });

  return batch.commit();
}

export function setClientTransactionsForms(
  transactionId: string,
  openTransactionId: string,
  clientTransactions: ClientTransaction[],
  formData?: boolean
): Promise<void> {
  const batch = writeBatch(db);
  clientTransactions.forEach((clientTransaction) => {
    const data: Partial<ClientTransaction> = {};
    data.forms = clientTransaction.forms;
    if (formData) {
      data.committedFormData = clientTransaction.committedFormData;
    }
    batch.set(
      clientTransactionDoc(transactionId, openTransactionId, clientTransaction.applicantId),
      data,
      {
        merge: true
      }
    );
  });

  return batch.commit();
}

export function submitTransaction(clientTransaction: ClientTransaction): Promise<void> {
  const batch = writeBatch(db);

  batch.set(
    clientTransactionDoc(
      clientTransaction.transactionId,
      clientTransaction.openTransactionId,
      clientTransaction.applicantId
    ),
    {
      skipTrigger: false,
      locked: true,
      dateEdited: Date.now()
    },
    { merge: true }
  );

  batch.set(
    openTransactionDoc(clientTransaction.transactionId, clientTransaction.openTransactionId),
    {
      applicantLocks: { [clientTransaction.ownerEmail]: Date.now() },
      dateEdited: Date.now(),
      skipTrigger: false
    },
    { merge: true }
  );

  return batch.commit();
}

export function lockTransaction(
  openTransaction: OpenTransaction,
  clientTransactions: ClientTransaction[]
): Promise<void> {
  const applicantLocks = clientTransactions.reduce((prev, clientTransaction) => {
    return { ...prev, [clientTransaction.ownerEmail]: Date.now() };
  }, {});

  const batch = writeBatch(db);

  batch.set(
    openTransactionDoc(openTransaction.transactionId, openTransaction.id),
    { applicantLocks, dateEdited: Date.now(), skipTrigger: true },
    { merge: true }
  );

  clientTransactions.forEach((clientTransaction) => {
    batch.set(
      clientTransactionDoc(
        clientTransaction.transactionId,
        clientTransaction.openTransactionId,
        clientTransaction.applicantId
      ),
      {
        locked: true,
        dateEdited: Date.now(),
        skipTrigger: true
      },
      { merge: true }
    );
  });
  return batch.commit();
}

export function unlockTransaction(
  openTransaction: OpenTransaction,
  clientTransactions: ClientTransaction[]
): Promise<void> {
  const batch = writeBatch(db);

  batch.set(
    openTransactionDoc(openTransaction.transactionId, openTransaction.id),
    { applicantLocks: {}, dateEdited: Date.now(), skipTrigger: true },
    { merge: true }
  );

  clientTransactions.forEach((clientTransaction) => {
    batch.set(
      clientTransactionDoc(
        clientTransaction.transactionId,
        clientTransaction.openTransactionId,
        clientTransaction.applicantId
      ),
      {
        locked: false,
        dateEdited: Date.now(),
        skipTrigger: true
      },
      { merge: true }
    );
  });
  return batch.commit();
}

export function cancelTransaction(
  openTransaction: OpenTransaction,
  clientTransactions: ClientTransaction[]
): Promise<void> {
  const batch = writeBatch(db);

  batch.set(
    openTransactionDoc(openTransaction.transactionId, openTransaction.id),
    { deleted: true, dateEdited: Date.now(), skipTrigger: true },
    { merge: true }
  );

  clientTransactions.forEach((clientTransaction) => {
    batch.set(
      clientTransactionDoc(
        clientTransaction.transactionId,
        clientTransaction.openTransactionId,
        clientTransaction.applicantId
      ),
      {
        deleted: true,
        dateEdited: Date.now(),
        skipTrigger: true
      },
      { merge: true }
    );
  });
  return batch.commit();
}

export const seenNotifications = function (notifications: string[]): Promise<void> {
  const batch = writeBatch(db);

  notifications.forEach((notification) => {
    batch.update(notificationsDoc(notification), 'seen', true);
  });

  return batch.commit();
};

export const seenActions = function (actions: string[]): Promise<void> {
  const batch = writeBatch(db);

  actions.forEach((action) => {
    batch.update(actionsDoc(action), 'seen', true);
  });

  return batch.commit();
};
