import { CSSProperties } from 'react';
import type { RealtorSettings, CustomFieldTypes, FieldItem } from 'types';

export type AllToolTypes =
  | 'TextBox'
  | 'CheckBox'
  | 'SignatureBox'
  | 'InitialBox'
  | 'MlsBox'
  | 'SignatureDate'
  | 'Text'
  | 'Strikethrough';

export const annotationFields: AllToolTypes[] = ['Strikethrough', 'Text'];

export type ToolTypes = AllToolTypes | '';

export type SelectedField = {
  fieldId: string;
  field: Form['fields']['fieldId'] | Form['annotations']['annotationId'];
} | null;

interface GeneralFormField {
  type: AllToolTypes;
  x: number;
  y: number;
  w: number;
  h: number;
  filledBy: number;
  value?: string | boolean;
}

export interface Point {
  x: number;
  y: number;
}

export interface AnnotationLine extends GeneralFormField {
  type: 'Strikethrough';
  metaData: {
    color: CSSProperties['color'];
    width: CSSProperties['borderWidth'];
    opacity: CSSProperties['opacity'];
    start: Point;
    end: Point;
  };
}
export interface AnnotationText extends GeneralFormField {
  type: 'Text';
  metaData: {
    content: string;
    style?: CSSProperties;
  };
}

export interface SubField {
  id: string;
  text: string;
  format?: string;
  order?: number;
}

export type SubFields = {
  [fieldType in CustomFieldTypes]: SubField[];
};

export type DefaultSubFields = {
  [fieldType in CustomFieldTypes]: SubField[];
};

export type MlsDataCategories =
  | 'Address'
  | 'Physical Properties'
  | 'Expenses'
  | 'Details'
  | 'Dates'
  | 'MLS';

export type MlsData = {
  [category in MlsDataCategories]: string[];
};

export type MlsDataFieldTypes = {
  [subFieldType in keyof SubFields]: string[];
};

interface TextBox extends GeneralFormField {
  type: 'TextBox';
  metaData: {
    filledWith: string;
    filledWithDetails?: FieldItem;
    lines: number;
    required: boolean;
    unique: boolean;
    subType?: string;
    subFields: SubField[];
  };
}

interface CheckBox extends GeneralFormField {
  type: 'CheckBox';
  metaData: {
    checked: boolean;
    byRealtor?: boolean;
    description: string;
  };
}

interface SignatureBox extends GeneralFormField {
  type: 'SignatureBox';
}

interface InitialBox extends GeneralFormField {
  type: 'InitialBox';
}

interface MlsBox extends GeneralFormField {
  type: 'MlsBox';
  metaData: {
    filledWith?: string;
    filledWithDetails?: FieldItem;
    lines: number;
    category: keyof MlsData;
    dataField: string;
    subType?: string;
    subFields: SubField[];
  };
}

interface SignatureDate extends GeneralFormField {
  type: 'SignatureDate';
}

export type FormField = TextBox | CheckBox | SignatureBox | InitialBox | MlsBox | SignatureDate;

export type FormAnnotation = AnnotationLine | AnnotationText;

export interface GeneralForm {
  ownerId: string;
  formPath: string;
  id: string;
  applicantsCount: number;
  name: string;
  description: string;
  dateCreated: number;
  dateEdited: number;
  isCommitted: boolean;
  isFileUploaded: boolean;
  isSystemForm: boolean;
  fields: {
    [fieldId: string]: FormField;
  };
  note?: string;
  annotations?: {
    [annotationId: string]: FormAnnotation;
  };
}

interface RealtorForm extends GeneralForm {
  isSystemForm: false;
}

interface AdminForm extends GeneralForm {
  isSystemForm: true;
  metaData: {
    customFields: RealtorSettings['customFields'];
    realtorFields: RealtorSettings['customFields'];
  };
}

export interface TransactionForm extends GeneralForm {
  transactionId: string;
}

export type Form = AdminForm | RealtorForm;
