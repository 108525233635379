import {
  getTransactionFileByPathRef,
  getDocumentRef,
  getFormRef,
  getIdRef,
  getProfilePictureRef,
  getRefByPath
} from 'services/storage';
import { getDownloadURL, getMetadata } from 'services/firebase';

import type { DocumentFileWithMetaData } from 'types';

export function getFormPath(isAdmin: boolean, formId: string, uid: string): string {
  return isAdmin ? `system_forms/${formId}.pdf` : `${uid}/forms/${formId}.pdf`;
}

export function getProfilePicture(uid: string): Promise<string> {
  const imageRef = getProfilePictureRef(uid);

  return getDownloadURL(imageRef);
}

export function getFormFile(formPath: string): Promise<string> {
  const imageRef = getFormRef(formPath);

  return getDownloadURL(imageRef);
}

export async function getFile(formPath: string): Promise<DocumentFileWithMetaData> {
  const imageRef = getFormRef(formPath);
  const metaData = await getMetadata(imageRef);
  const url = await getDownloadURL(imageRef);
  return { metaData, url };
}

export async function getDocumentFile(
  email: string,
  transactionId: string,
  documentId: string
): Promise<DocumentFileWithMetaData> {
  const imageRef = getDocumentRef(email, transactionId, documentId);
  const metaData = await getMetadata(imageRef);
  const url = await getDownloadURL(imageRef);
  return { metaData, url };
}

export async function getIdFile(email: string, uid: string): Promise<DocumentFileWithMetaData> {
  const imageRef = getIdRef(email, uid);
  const metaData = await getMetadata(imageRef);
  const url = await getDownloadURL(imageRef);
  return { metaData, url };
}

export async function getIdFileByPath(path: string): Promise<DocumentFileWithMetaData> {
  const imageRef = getRefByPath(path);
  const metaData = await getMetadata(imageRef);
  const url = await getDownloadURL(imageRef);
  return { metaData, url };
}

export async function getTransactionFileByPath(path: string): Promise<string> {
  const imageRef = getTransactionFileByPathRef(path);

  return getDownloadURL(imageRef);
}
