import closeIcon from 'assets/icons/close.svg';

interface Props {
  id: string;
  text: string;
  hideClose?: boolean;
  onRemove: (id: string) => void;
}

export function RemovableChip({ id, text, onRemove, hideClose }: Props): JSX.Element {
  return (
    <div
      onClick={() => hideClose && onRemove(id)}
      className={`flex h-[38px] max-w-xs relative items-center justify-center rounded-md border-2 border-green-1 px-3 ${
        hideClose ? 'cursor-pointer' : ''
      }`}
    >
      <span className="font-medium text-list1 truncate" title={text}>
        {text}
      </span>
      {!hideClose && (
        <div
          className="absolute -right-2 -top-2 w-5 h-5 cursor-pointer hover:opacity-80"
          onClick={() => onRemove(id)}
        >
          <img src={closeIcon} className="w-full h-full" alt="remove" />
        </div>
      )}
    </div>
  );
}
