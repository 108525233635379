import { useQuery } from 'react-query';

import { getEmailFromPasswordReset } from 'services/auth';

import type { UseQueryResult } from 'react-query';

interface UsePasswordReset {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  emailAddressToRecoverQuery: UseQueryResult<string, unknown>;
  actionCode: string | undefined;
}

export function usePasswordReset(): UsePasswordReset {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params: { oobCode?: string } = Object.fromEntries(urlSearchParams.entries());
  const actionCode = params.oobCode;

  const emailAddressToRecoverQuery = useQuery('emailAddressToRecover', () =>
    getEmailFromPasswordReset(actionCode)
  );

  return {
    isLoading: emailAddressToRecoverQuery.isLoading,
    isError: emailAddressToRecoverQuery.isError,
    isSuccess: emailAddressToRecoverQuery.isSuccess,
    emailAddressToRecoverQuery,
    actionCode
  };
}
