interface Props {
  label: string;
  selected: boolean;
  onClick: () => void;
}

export function SortItem({ label, selected, onClick }: Props): JSX.Element {
  return (
    <div
      onClick={onClick}
      className={`flex h-5 items-center text-list2 rounded cursor-pointer p-1 ${
        selected
          ? 'text-white bg-gray-5'
          : 'text-gray-5 bg-transparent hover:text-white hover:bg-gray-5 hover:opacity-50'
      }`}
    >
      {label}
    </div>
  );
}
