import { useState, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';

import { useAuth } from 'hooks';
import { getLastActivity, setLastActivity as setter } from 'services/firestore';

import type { LastActivity } from 'types';

interface UseLastActivity {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  lastActivityDate: number;
  newActivity: number;
}

export function useLastActivity(): UseLastActivity {
  const { currentUser } = useAuth();
  const uid = currentUser!.uid;
  const [lastActivity, setLastActivity] = useState<number | null>(null);
  const [newActivity, setNewActivity] = useState<number | null>(null);
  const didAlreadyRun = useRef(false);

  const lastActivityQuery = useQuery('lastActivity', () => getLastActivity(uid));

  useEffect(() => {
    if (lastActivityQuery.isSuccess) {
      const result = lastActivityQuery.data;
      if (result.exists()) {
        const lastActivity = result.data() as LastActivity;
        setNewActivity(lastActivity.lastActivity);
        setLastActivity(lastActivity.lastActivity);
      } else {
        if (!didAlreadyRun.current) {
          didAlreadyRun.current = true;
          setNewActivity(0);
          setLastActivity(0);
          setter(uid);
        }
      }
    }
  }, [lastActivityQuery.data, lastActivityQuery.isSuccess, uid]);

  return {
    isLoading: lastActivityQuery.isLoading,
    isSuccess: lastActivityQuery.isSuccess,
    isError: lastActivityQuery.isError,
    lastActivityDate: lastActivity ?? Infinity,
    newActivity: newActivity ?? Infinity
  };
}
