export const SNACK_BAR_AUTO_HIDE_DURATION = 5000;
export const NEW_FORM_NAME = 'New Form';
export const MAX_LATEST_DOCUMENTS_TO_SAVE = 5;
export const CLONE_ITEM_POSTFIX = ' - Copy';
export const CLONE_FORM_POSTFIX = ' (Copy)';
export const RECO_MIME_TYPES = { 'image/png': 'PNG', 'image/jpeg': 'JPG' };
export const RECO_MAX_FILE_SIZE = '5MB';
export const PROFILE_PICTURE_MAX_FILE_SIZE = 1; //MB
export const FORM_MAX_FILE_SIZE = '15MB';
export const FORM_MIME_TYPES = {
  'application/pdf': 'PDF',
  'image/png': 'PNG',
  'image/jpeg': 'JPG'
};
export const FORM_BUILDER_AUTOSVAE_DEBOUNCE = 3000; //ms
export const FORM_BUILDER_DEFAULT_FONT_SIZE = 12; //px
export const FORM_BUILDER_DEFAULT_LINE_HEIGHT = 1.4; //ratio
export const LISTINGS_SEARCH_DEBOUNCE = 500; //ms
export const LISTINGS_SEARCH_MAX_SUGGESTS = 5;
export const CLAUSES_DEFAULT_PAGE_SIZE = 500;
export const CLAUSES_SEARCH_DEBOUNCE = 500; //ms
export const CLAUSES_WAIT_FOR_ELASTIC_TO_SYNC = 5000; //ms
export const TRANSACTIONS_DEFAULT_PAGE_SIZE = 5;
export const TRANSACTIONS_SEARCH_DEBOUNCE = 500; //ms
export const TRANSACTIONS_WAIT_FOR_ELASTIC_TO_SYNC = 5000; //ms
export const DOCUMENT_MAX_FILE_SIZE = '5MB';
export const DOCUMENT_MIME_TYPES = {
  'image/png': 'PNG',
  'image/jpeg': 'JPG',
  'application/pdf': 'PDF'
};
export const SUBFIELD_DESIGNATOR = '-$^$-'; //Do not change this ever
export const FIELD_DESCRIPTION_MAX_CHARACTER_COUNT = 140;
export const NOTIFICATIONS_LOAD_LIMIT = 10;
export const SORT_FIELD_X_Y_OFFSET = 10;
