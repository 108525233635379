import {
  applicantDoc,
  clientTransactionDoc,
  transactionFormDoc,
  draftFormDoc,
  formDoc,
  lastActivitiesDoc,
  markDownsDoc,
  notificationsDoc,
  openTransactionDoc,
  settingsDoc,
  systemFormDoc,
  templateDoc,
  transactionDoc,
  clauseDoc
} from 'services/firestore';
import { deleteDoc, deleteField, setDoc } from 'services/firebase';
import type {
  Applicant,
  ClientSettings,
  ClientTransaction,
  Form,
  MarkDowns,
  RealtorSettings,
  SetClientTransaction,
  Template,
  Transaction,
  TransactionForm
} from 'types';
import { Clause } from 'types/clause';

export function setRealtorSettings(uid: string, settings: RealtorSettings): Promise<void> {
  return setDoc(settingsDoc(uid), settings);
}

export function setClientSettings(uid: string, settings: ClientSettings): Promise<void> {
  return setDoc(settingsDoc(uid), settings);
}

export function setLatestDocuments(
  id: string,
  documents: RealtorSettings['latestDocuments']
): Promise<void> {
  return setDoc(settingsDoc(id), { latestDocuments: documents }, { merge: true });
}

export function setPreferences(
  id: string,
  preferences: Partial<RealtorSettings['preferences']>
): Promise<void> {
  return setDoc(settingsDoc(id), { preferences }, { merge: true });
}

export function setBuilderOnboarding(
  id: string,
  builderOnboarding: Partial<RealtorSettings['builderOnboarding']>
): Promise<void> {
  return setDoc(settingsDoc(id), { builderOnboarding }, { merge: true });
}

export function setCustomFields(
  id: string,
  customFields: Partial<RealtorSettings['customFields']>
): Promise<void> {
  return setDoc(settingsDoc(id), { customFields }, { merge: true });
}

export function setRealtorFields(
  id: string,
  realtorFields: Partial<RealtorSettings['realtorFields']>
): Promise<void> {
  return setDoc(settingsDoc(id), { realtorFields }, { merge: true });
}

export function setLastActivity(id: string): Promise<void> {
  return setDoc(lastActivitiesDoc(id), { lastActivity: Date.now() });
}

export function setTemplate(template: Template): Promise<void> {
  return setDoc(templateDoc(template.id), template);
}

export function removeTemplate(id: string): Promise<void> {
  return deleteDoc(templateDoc(id));
}

export function setApplicant(applicant: Applicant): Promise<void> {
  return setDoc(applicantDoc(applicant.id), applicant);
}

export function removeApplicant(id: string): Promise<void> {
  return deleteDoc(applicantDoc(id));
}

export function setClause(clause: Clause): Promise<void> {
  return setDoc(clauseDoc(clause.id), clause);
}

export function removeClause(id: string): Promise<void> {
  return deleteDoc(clauseDoc(id));
}

export function setForm(form: Form): Promise<void> {
  return setDoc(formDoc(form.id), form);
}

export function removeForm(id: string): Promise<void> {
  return deleteDoc(formDoc(id));
}

export function setTransactionForm(form: TransactionForm): Promise<void> {
  return setDoc(transactionFormDoc(form.id), form);
}

export function removeTransactionForm(id: string): Promise<void> {
  return deleteDoc(transactionFormDoc(id));
}

export function setDraftForm(form: Form): Promise<void> {
  return setDoc(draftFormDoc(form.id), form);
}

export function removeDraftForm(id: string): Promise<void> {
  return deleteDoc(draftFormDoc(id));
}

export function setSystemForm(form: Form): Promise<void> {
  return setDoc(systemFormDoc(form.id), form);
}

export function removeSystemForm(id: string): Promise<void> {
  return deleteDoc(systemFormDoc(id));
}

export function setApplicantId(
  id: string,
  email: string,
  applicantId: Transaction['applicantsIds'][string]
): Promise<void> {
  return setDoc(
    transactionDoc(id),
    { applicantsIds: { [email]: applicantId }, dateEdited: Date.now() },
    { merge: true }
  );
}

export function setVerifiedApplicantsIds(id: string, applicantEmail: string): Promise<void> {
  return setDoc(
    transactionDoc(id),
    { verifiedApplicantsIds: { [applicantEmail]: Date.now() } },
    { merge: true }
  );
}

export function setClientTransaction(
  transactionId: string,
  clientTransactionId: string,
  applicantId: string,
  clientTransaction: Partial<SetClientTransaction>
): Promise<void> {
  return setDoc(
    clientTransactionDoc(transactionId, clientTransactionId, applicantId),
    clientTransaction,
    { merge: true }
  );
}

export function setCommittedDocument(
  transactionId: string,
  openTransactionId: string,
  applicantId: string,
  documentId: string
): Promise<void> {
  return setDoc(
    clientTransactionDoc(transactionId, openTransactionId, applicantId),
    {
      committedDocumentIds: { [documentId]: Date.now() },
      dateEdited: Date.now(),
      skipTrigger: false
    },
    { merge: true }
  );
}

export function addDocument(
  transactionId: string,
  openTransactionId: string,
  applicantId: string,
  documents: Template['documents']
): Promise<void> {
  return setDoc(
    clientTransactionDoc(transactionId, openTransactionId, applicantId),
    {
      documents: documents
    },
    { merge: true }
  );
}

export function removeCommittedDocument(
  transactionId: string,
  openTransactionId: string,
  applicantId: string,
  documentId: string
): Promise<void> {
  return setDoc(
    clientTransactionDoc(transactionId, openTransactionId, applicantId),
    {
      committedDocumentIds: { [documentId]: deleteField() },
      dateEdited: Date.now(),
      skipTrigger: false
    },
    { merge: true }
  );
}

export function setCommittedFormData(
  transactionId: string,
  openTransactionId: string,
  applicantId: string,
  formId: string,
  formDataToCommit: ClientTransaction['committedFormData']
): Promise<void> {
  return setDoc(
    clientTransactionDoc(transactionId, openTransactionId, applicantId),
    {
      committedFormIds: { [formId]: Date.now() },
      committedFormData: formDataToCommit,
      dateEdited: Date.now(),
      skipTrigger: false
    },
    { merge: true }
  );
}

export function setSeenForms(
  transactionId: string,
  openTransactionId: string,
  applicantId: string,
  formId: string
): Promise<void> {
  return setDoc(
    clientTransactionDoc(transactionId, openTransactionId, applicantId),
    {
      seenFormIds: { [formId]: Date.now() },
      isSeen: true,
      dateEdited: Date.now(),
      skipTrigger: false
    },
    { merge: true }
  );
}

export function setOpenTransactionForms(
  transactionId: string,
  openTransactionId: string,
  forms: Form[],
  note?: string
): Promise<void> {
  const data: any = { forms, dateEdited: Date.now(), skipTrigger: false };
  if (note) {
    data.note = note;
  }
  return setDoc(openTransactionDoc(transactionId, openTransactionId), data, { merge: true });
}

export function setMarkDowns(markDowns: MarkDowns): Promise<void> {
  return setDoc(markDownsDoc(), markDowns);
}

export const seenNotification = function (uid: string): Promise<void> {
  return setDoc(notificationsDoc(uid), { seen: true }, { merge: true });
};
