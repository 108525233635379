import type { FilePondFile } from 'filepond';

export function checkIfPdfEncrypted(file: FilePondFile['file']): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');
    reader.onload = function (evt) {
      const fileContent = evt.target?.result;
      if (typeof fileContent !== 'string') {
        reject('Error Reading File!');
        return;
      }

      const trailerIndex = fileContent.lastIndexOf('trailer\n<<');
      if (trailerIndex !== -1) {
        const trailer = fileContent.substring(trailerIndex);
        if (trailer.lastIndexOf('/Encrypt') === -1) {
          resolve(false);
          return;
        }
        resolve(true);
      } else {
        resolve(false);
      }
    };
  });
}

export function calculateRotation(
  startX: number,
  startY: number,
  endX: number,
  endY: number
): number {
  const deltaX: number = endX - startX;
  const deltaY: number = endY - startY;
  const rotationInRadians: number = Math.atan2(deltaY, deltaX);
  const rotationInDegrees: number = rotationInRadians * (180 / Math.PI);
  return rotationInDegrees;
}
