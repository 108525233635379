export * from 'utils/sorters';
export * from 'utils/validators';
export * from 'utils/formFields';
export * from 'utils/canada';
export * from 'utils/reco';
export * from 'utils/profileItems';
export * from 'utils/formBuilder';
export * from 'utils/subFields';
export * from 'utils/mlsData';
export * from 'utils/dateTime';
export * from 'utils/notificationTexts';

export function deepClone<T>(item: T): T {
  if (!item) return item;
  return JSON.parse(JSON.stringify(item));
}

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function groupBy<T extends { [key: string]: any } = any>(xs: T[], key: keyof T) {
  return xs.reduce(function (rv, x) {
    const innerKey = x[key];
    return {
      ...rv,
      [innerKey]: [...(rv[innerKey] || []), x]
    };
  }, {} as { [name: string]: T[] });
}

export const pythagoras = (width: number, height: number) => Math.sqrt(width ** 2 + height ** 2);
export const atan2 = (width: number, height: number) => (180 / Math.PI) * Math.atan2(width, height);

export const toNumber = (number: string, fallback = 0): number => {
  const res = +number;
  return isNaN(res) ? fallback : res;
};

export const removeKeysFromObject = (obj: any, keys: string[]) => {
  const newObj = { ...obj };
  keys.forEach((key) => delete newObj[key]);
  return newObj;
};

export function replaceTextBetweenPositions(
  text: string,
  startPos: number,
  endPos: number,
  newText: string
): string {
  debugger;
  if (startPos < 0 || endPos > text.length || startPos > endPos) {
    return newText;
  }

  const leftPart = text.slice(0, startPos);
  const rightPart = text.slice(endPos);

  return leftPart + newText + rightPart;
}

export const pluralize = (word: string, count: number): string => {
  if (count === 0) {
    return '';
  }
  if (count === 1) {
    return `${count} ${word}`;
  }
  return `${count} ${word}s`;
};
