import { useState, useEffect, useRef, useCallback } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { UseCRUDConf, useAuth, useCRUD } from 'hooks';
import {
  getTransactionForm as transactionFormGetter,
  setTransactionForm as transactionFormSetter,
  removeTransactionForm as transactionFormRemover,
  getDraftForm as draftFormGetter,
  setDraftForm as draftFormSetter,
  removeDraftForm as draftFormRemover,
  getForm as formGetter,
  setForm as formSetter,
  removeForm as formRemover,
  getSystemForms as systemFormGetter,
  setSystemForm as systemFormSetter,
  removeSystemForm as systemFormRemover,
  mutateAllForms as batchMutate
} from 'services/firestore';
import { setFormFile, getFormFile, getFormPath } from 'services/storage';
import { FORM_BUILDER_AUTOSVAE_DEBOUNCE } from 'config/constants';

import type { Form, WithoutOwnerId, UploadTask, TransactionForm } from 'types';
import type { FilePondFile } from 'filepond';

interface UseForms {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  forms: Form[] | null;
  transactionForms: TransactionForm[] | null;
  draftForms: Form[] | null;
  formData: WithoutOwnerId<Form>;
  systemForms: Form[] | null;
  savingStatus: string;
  formURL: string;
  onFormNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFormDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formUploader: (file: FilePondFile['file']) => UploadTask;
  handleUpload: () => void;
  setFormData: React.Dispatch<React.SetStateAction<WithoutOwnerId<Form>>>;
  removeTransactionForm: (id: string) => Promise<void>;
  removeDraftForm: (id: string) => Promise<void>;
  removeForm: (id: string) => Promise<void>;
  removeSystemForm: (id: string) => Promise<void>;
  setForm: (item: WithoutOwnerId<Form>) => Promise<void>;
  setTransactionForm: (item: WithoutOwnerId<Form>) => Promise<void>;
  setDraftForm: (item: WithoutOwnerId<Form>) => Promise<void>;
  commitForm: () => Promise<void>;
  commitSystemForm: (formData: Form) => Promise<void>;
  discardChanges: () => Promise<void>;
  saveForm: (formData: WithoutOwnerId<Form>) => Promise<void>;
  saveTransactionForm: (formData: WithoutOwnerId<Form>) => Promise<void>;
  skipAutoSave: () => void;
  mutateAllForms: (forms: Form[], draftForms: Form[]) => Promise<void>;
}

interface UseFormConf {
  transactionForms: UseCRUDConf<TransactionForm>;
  draftForms?: UseCRUDConf;
  forms?: UseCRUDConf;
  systemForms?: UseCRUDConf;
}

export function useForms(formId = '', conf?: UseFormConf): UseForms {
  const queryClient = useQueryClient();
  const {
    isLoading: isTransactionFormsLoading,
    isError: isTransactionFormsError,
    isSuccess: isTransactionFormsSuccess,
    items: transactionForms,
    setItem: setTransactionForm,
    removeItem: removeTransactionForm
  } = useCRUD<TransactionForm>(
    {
      queryKey: 'transactionForms',
      getter: transactionFormGetter,
      setter: transactionFormSetter,
      remover: transactionFormRemover
    },
    (conf || {}).transactionForms
  );
  const {
    isLoading: isDraftFormsLoading,
    isError: isDraftFormsError,
    isSuccess: isDraftFormsSuccess,
    items: draftForms,
    setItem: setDraftForm,
    removeItem: removeDraftForm
  } = useCRUD<Form>(
    {
      queryKey: 'draftForms',
      getter: draftFormGetter,
      setter: draftFormSetter,
      remover: draftFormRemover
    },
    (conf || {}).draftForms
  );
  const {
    isLoading: isFormsLoading,
    isError: isFormsError,
    isSuccess: isFormsSuccess,
    items: forms,
    setItem: setForm,
    removeItem: removeForm
  } = useCRUD<Form>(
    {
      queryKey: 'forms',
      getter: formGetter,
      setter: formSetter,
      remover: formRemover
    },
    (conf || {}).forms
  );
  const {
    isLoading: isSystemFormsLoading,
    isError: isSystemFormsError,
    isSuccess: isSystemFormsSuccess,
    items: systemForms,
    setItem: setSystemForm,
    removeItem: removeSystemForm
  } = useCRUD<Form>(
    {
      queryKey: 'systemForms',
      getter: systemFormGetter,
      setter: systemFormSetter,
      remover: systemFormRemover
    },
    (conf || {}).systemForms
  );
  const { currentUser } = useAuth();
  const uid = currentUser!.uid;
  const isAdmin = !!currentUser?.isAdmin;
  const [formData, setFormDataState] = useState<WithoutOwnerId<Form>>({
    id: formId,
    formPath: getFormPath(isAdmin, formId, uid),
    applicantsCount: 1,
    name: '',
    description: '',
    isCommitted: false,
    dateCreated: Date.now(),
    dateEdited: Date.now(),
    isFileUploaded: false,
    isSystemForm: isAdmin,
    fields: {} as Form['fields']
  });
  const [savingStatus, setSavingStatus] = useState('');
  const [formURL, setFormURL] = useState('');
  const didSkipFirstSaveRef = useRef(false);
  const didLoadFormsRef = useRef(false);
  const formPathRef = useRef(getFormPath(isAdmin, formId, uid));
  const saverHandle = useRef<NodeJS.Timeout>();

  function skipAutoSave() {
    if (saverHandle.current) {
      clearTimeout(saverHandle.current);
    }
    didSkipFirstSaveRef.current = false;
  }

  const setFormData = useCallback((setterFn: React.SetStateAction<WithoutOwnerId<Form>>) => {
    setFormDataState((prev) => {
      if (typeof setterFn === 'function') {
        const newState = setterFn(prev);
        return { ...newState, dateEdited: Date.now(), isCommitted: false };
      }
      return prev;
    });
  }, []);

  function onFormNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const formName = e.target.value.trim();

    setFormData((prev) => {
      const newState: typeof prev = { ...prev, name: formName };
      return newState;
    });
  }

  function onFormDescriptionChange(e: React.ChangeEvent<HTMLInputElement>) {
    const description = e.target.value.trim();

    setFormData((prev) => {
      const newState: typeof prev = { ...prev, description: description };
      return newState;
    });
  }

  function formUploader(file: FilePondFile['file']): UploadTask {
    return setFormFile(getFormPath(isAdmin, formId, uid), file);
  }

  const getFormUrlQuery = useQuery(
    ['formFile', formId],
    () => {
      return getFormFile(formPathRef.current);
    },
    { enabled: false }
  );

  function handleUpload() {
    setFormData((prev) => {
      const newState: typeof prev = { ...prev, isFileUploaded: true };
      return newState;
    });
    getFormUrlQuery.refetch();
  }

  async function saveTransactionForm(formData: WithoutOwnerId<TransactionForm>) {
    try {
      await setTransactionForm(formData);
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  async function saveForm(formData: WithoutOwnerId<Form>) {
    setSavingStatus('Saving...');
    try {
      await setDraftForm(formData);
    } catch (error) {
      setSavingStatus('Error Saving Form!');
    } finally {
      setSavingStatus('Auto Saved');
    }
  }

  async function commitForm() {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const newFormData: typeof formData = {
          ...formData,
          dateEdited: Date.now(),
          isCommitted: true
        };
        await setDraftForm(newFormData);
        await setForm(newFormData);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  async function commitSystemForm(formData: Form) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!formData.isSystemForm) {
          reject('not a system form');
          return;
        }
        const newFormData: typeof formData = {
          ...formData,
          dateEdited: Date.now(),
          isCommitted: true
        };
        await setDraftForm(newFormData);
        await setSystemForm(newFormData);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  function discardChanges() {
    let selectedForm: Form | undefined;

    if (isAdmin) {
      if (!systemForms) return Promise.reject('no system forms found');

      selectedForm = systemForms.find((form) => form.id === formId);
    }

    if (!isAdmin) {
      if (!forms) return Promise.reject('no forms found');
      selectedForm = forms.find((form) => form.id === formId);
    }

    if (!selectedForm) return Promise.reject('form not found');

    setFormDataState(selectedForm);
    return setDraftForm(selectedForm);
  }

  useEffect(() => {
    didLoadFormsRef.current = false;
    didSkipFirstSaveRef.current = false;
    setSavingStatus('');
  }, [formId]);

  useEffect(() => {
    if (formId) {
      if (!didLoadFormsRef.current) {
        if (
          isFormsSuccess &&
          isSystemFormsSuccess &&
          isDraftFormsSuccess &&
          draftForms &&
          systemForms
        ) {
          didLoadFormsRef.current = true;

          const allForms = [...draftForms, ...systemForms];
          const selectedForm = allForms.find((form) => form.id === formId);
          if (selectedForm) {
            setFormDataState(selectedForm);
            didSkipFirstSaveRef.current = false;

            if (selectedForm.isSystemForm) {
              setSavingStatus('System Form');
            } else {
              setSavingStatus('');
            }

            if (selectedForm.isFileUploaded) {
              formPathRef.current = selectedForm.formPath;
              getFormUrlQuery.refetch();
            }
          }
        }
      }
    }
  }, [
    formId,
    draftForms,
    getFormUrlQuery,
    isDraftFormsSuccess,
    isFormsSuccess,
    isSystemFormsSuccess,
    systemForms
  ]);

  useEffect(() => {
    if (!formData.isSystemForm || isAdmin) {
      if (didLoadFormsRef.current) {
        if (didSkipFirstSaveRef.current) {
          saverHandle.current = setTimeout(
            () => saveForm(formData),
            FORM_BUILDER_AUTOSVAE_DEBOUNCE
          );
        } else {
          didSkipFirstSaveRef.current = true;
        }
      }
    }
    return () => {
      if (saverHandle.current) {
        clearTimeout(saverHandle.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, formId, isAdmin]);

  useEffect(() => {
    if (getFormUrlQuery.isSuccess) {
      setFormURL(getFormUrlQuery.data);
    }
  }, [getFormUrlQuery.data, getFormUrlQuery.isSuccess]);

  const mutateAllFormsMutation = useMutation<
    void,
    unknown,
    { forms: Form[]; draftForms: Form[] },
    unknown
  >(({ forms, draftForms }) => batchMutate(forms, draftForms), {
    onSettled: () => {
      queryClient.invalidateQueries('forms');
      queryClient.invalidateQueries('draftForms');
    }
  });

  function mutateAllForms(forms: Form[], draftForms: Form[]) {
    return mutateAllFormsMutation.mutateAsync({ forms, draftForms });
  }
  return {
    isLoading:
      isFormsLoading ||
      isSystemFormsLoading ||
      isTransactionFormsLoading ||
      isDraftFormsLoading ||
      getFormUrlQuery.isLoading,
    isError: isFormsError || isSystemFormsError || isTransactionFormsError || isDraftFormsError,
    isSuccess:
      isFormsSuccess && isSystemFormsSuccess && isTransactionFormsSuccess && isDraftFormsSuccess,
    forms,
    transactionForms,
    draftForms,
    formData,
    systemForms,
    savingStatus,
    formURL,
    onFormNameChange,
    onFormDescriptionChange,
    formUploader,
    handleUpload,
    setFormData,
    removeForm,
    removeTransactionForm,
    removeDraftForm,
    removeSystemForm,
    setForm,
    setTransactionForm,
    setDraftForm,
    commitForm,
    commitSystemForm,
    discardChanges,
    saveForm,
    saveTransactionForm,
    skipAutoSave,
    mutateAllForms
  };
}
