import { functions, httpsCallable } from 'services/firebase';
import type {
  HttpsCallableResult,
  SignTransactionResponse,
  SortParams,
  TransactionsSearchParams
} from 'types';
import { AsInputArgs, AsUpdateArs, ResponseFromFirebase } from 'types/base';
import {
  SearchTransactionsResponse,
  ExtendedTransaction,
  Transaction,
  Template,
  FillFormOfTransaction,
  FillDocumentOfTransaction,
  ExtendedTemplate,
  ESignRequestInput,
  ESign,
  ExtendedEsign
} from 'types/newTransaction';

export function getTransaction(
  transactionId: string
): Promise<HttpsCallableResult<ResponseFromFirebase<ExtendedTransaction, 'transaction'>>> {
  const fn = httpsCallable<string, ResponseFromFirebase<ExtendedTransaction, 'transaction'>>(
    functions,
    'getTransaction'
  );
  return fn(transactionId);
}

export function setSeenForm(
  transactionId: string,
  formId: string
): Promise<HttpsCallableResult<ResponseFromFirebase<{ success: boolean }>>> {
  const fn = httpsCallable<
    { transactionId: string; formId: string },
    ResponseFromFirebase<{ success: boolean }>
  >(functions, 'seenForm');
  return fn({
    transactionId,
    formId
  });
}

export function getESignsOfTransaction(
  transactionId: string
): Promise<HttpsCallableResult<ResponseFromFirebase<ESign[], 'eSigns'>>> {
  const fn = httpsCallable<string, ResponseFromFirebase<ESign[], 'eSigns'>>(
    functions,
    'getESignsOfTransaction'
  );
  return fn(transactionId);
}

export function getESign(
  esignId: string
): Promise<HttpsCallableResult<ResponseFromFirebase<ExtendedEsign, 'transaction'>>> {
  const fn = httpsCallable<string, ResponseFromFirebase<ExtendedEsign, 'transaction'>>(
    functions,
    'getESign'
  );
  return fn(esignId);
}

export function createTransaction(
  transaction: Omit<AsUpdateArs<Transaction>, 'realtorIds'>
): Promise<HttpsCallableResult<ResponseFromFirebase<Transaction, 'transaction'>>> {
  const createFunction = httpsCallable<
    Omit<AsInputArgs<Transaction>, 'realtorIds'>,
    ResponseFromFirebase<Transaction, 'transaction'>
  >(functions, 'createTransaction');
  return createFunction(transaction);
}

export function updateTransaction(
  transaction: Partial<Omit<Transaction, 'realtorIds'>> & { id: string }
): Promise<HttpsCallableResult<ResponseFromFirebase<Transaction, 'transaction'>>> {
  const createFunction = httpsCallable<
    Partial<Omit<Transaction, 'realtorIds'>> & { id: string },
    ResponseFromFirebase<Transaction, 'transaction'>
  >(functions, 'updateTransaction');
  return createFunction(transaction);
}

export function resendTransactionNotification(
  transactionId: string
): Promise<HttpsCallableResult<void>> {
  const createFunction = httpsCallable<string, void>(functions, 'resendTransactionNotification');
  return createFunction(transactionId);
}

export function signTransaction(
  args: ESignRequestInput
): Promise<HttpsCallableResult<SignTransactionResponse>> {
  const signTransaction = httpsCallable<ESignRequestInput, SignTransactionResponse>(
    functions,
    'fillAndSendSignrequest'
  );
  return signTransaction(args);
}

export function searchTransactions(
  searchParams: TransactionsSearchParams
): Promise<HttpsCallableResult<SearchTransactionsResponse>> {
  const searchIndexes = httpsCallable<TransactionsSearchParams, SearchTransactionsResponse>(
    functions,
    'searchNewTransaction'
  );
  return searchIndexes(searchParams);
}

export function getMyTransactions(
  args?: SortParams
): Promise<HttpsCallableResult<{ transactions: ExtendedTransaction[] }>> {
  const searchIndexes = httpsCallable<SortParams, { transactions: ExtendedTransaction[] }>(
    functions,
    'getMyTransactions'
  );
  return searchIndexes(args);
}

export function fillFormOfTransaction(
  args: FillFormOfTransaction
): Promise<HttpsCallableResult<ResponseFromFirebase<Transaction, 'transaction'>>> {
  const fillFunction = httpsCallable<
    FillFormOfTransaction,
    ResponseFromFirebase<Transaction, 'transaction'>
  >(functions, 'fillFormOfTransaction');
  return fillFunction(args);
}

export function submitTransaction(
  transactionId: string
): Promise<HttpsCallableResult<ResponseFromFirebase<Transaction, 'transaction'>>> {
  const fillFunction = httpsCallable<string, ResponseFromFirebase<Transaction, 'transaction'>>(
    functions,
    'submitTransaction'
  );
  return fillFunction(transactionId);
}

export function fillDocumentOfTransaction(
  args: FillDocumentOfTransaction
): Promise<HttpsCallableResult<ResponseFromFirebase<Transaction, 'transaction'>>> {
  const fillFunction = httpsCallable<
    FillDocumentOfTransaction,
    ResponseFromFirebase<Transaction, 'transaction'>
  >(functions, 'fillDocumentOfTransaction');
  return fillFunction(args);
}

//templates

export function getTemplate(
  templateId: string
): Promise<HttpsCallableResult<ResponseFromFirebase<ExtendedTemplate, 'template'>>> {
  const fn = httpsCallable<string, ResponseFromFirebase<ExtendedTemplate, 'template'>>(
    functions,
    'getTemplate'
  );
  return fn(templateId);
}

export function createTemplate(
  template: Omit<AsUpdateArs<Template>, 'realtorIds'>
): Promise<HttpsCallableResult<ResponseFromFirebase<ExtendedTemplate, 'template'>>> {
  const createFunction = httpsCallable<
    Omit<AsInputArgs<Template>, 'realtorIds'>,
    ResponseFromFirebase<ExtendedTemplate, 'template'>
  >(functions, 'createTemplate');
  return createFunction(template);
}

export function updateTemplate(
  template: Partial<Omit<Template, 'realtorIds'>> & { id: string }
): Promise<HttpsCallableResult<ResponseFromFirebase<Template, 'template'>>> {
  const createFunction = httpsCallable<
    Partial<Omit<Template, 'realtorIds'>> & { id: string },
    ResponseFromFirebase<Template, 'template'>
  >(functions, 'updateTemplate');
  return createFunction(template);
}

export function deleteTemplate(templateId: string): Promise<any> {
  const deleteFunction = httpsCallable<string, any>(functions, 'deleteTemplate');
  return deleteFunction(templateId);
}

export function getMyTemplates(
  args?: SortParams
): Promise<HttpsCallableResult<{ templates: ExtendedTemplate[] }>> {
  const searchIndexes = httpsCallable<SortParams, { templates: ExtendedTemplate[] }>(
    functions,
    'getMyTemplates'
  );
  return searchIndexes(args);
}
