import { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { getMarkDowns, setMarkDowns as setter } from 'services/firestore';

import type { MarkDowns } from 'types';

interface UseMarkDowns {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  markDowns: MarkDowns;
  setMarkDowns: (markDowns: MarkDowns) => Promise<void>;
}

export function useMarkDowns(): UseMarkDowns {
  const queryClient = useQueryClient();
  const [markDowns, setMarkDownsState] = useState<MarkDowns>({
    clientFAQ: '',
    realtorFAQ: '',
    clientContactUs: '',
    realtorContactUs: '',
    clientPrivacy: '',
    realtorPrivacy: '',
    clientTerms: '',
    realtorTerms: ''
  });

  const setMarkDownsMutation = useMutation<void, unknown, MarkDowns, unknown>(
    (profile) => setter(profile),
    {
      onSettled: () => {
        queryClient.invalidateQueries('markDowns');
      }
    }
  );

  const markDownsQuery = useQuery('markDowns', () => getMarkDowns());

  function setMarkDowns(markDowns: MarkDowns) {
    return setMarkDownsMutation.mutateAsync(markDowns);
  }

  useEffect(() => {
    if (markDownsQuery.isSuccess) {
      const result = markDownsQuery.data;
      if (result.exists()) {
        setMarkDownsState((prev) => {
          const newData = result.data();
          return { ...prev, ...newData };
        });
      }
    }
  }, [markDownsQuery.data, markDownsQuery.isSuccess]);

  return {
    isLoading: markDownsQuery.isLoading,
    isError: markDownsQuery.isError,
    isSuccess: markDownsQuery.isSuccess,
    markDowns: markDowns,
    setMarkDowns: setMarkDowns
  };
}
