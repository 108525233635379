import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import { NotFound } from 'components';
import ProtectedRoute from 'routers/ProtectedRoute';
import LinearProgress from 'components/LinearProgress';

const ClientTransactions = lazy(() => import('pages/client/ClientTransactions'));
const ClientSettings = lazy(() => import('pages/client/ClientSettings'));
const ClientProfile = lazy(() => import('pages/client/ClientProfile'));
const FormFiller = lazy(() => import('pages/client/FormFiller'));
const FAQ = lazy(() => import('pages/client/FAQ'));
const ContactUs = lazy(() => import('pages/client/ContactUs'));
const ClientDocumentsUpload = lazy(() => import('pages/client/clientDocumentsUpload'));
const FormFillerBase = lazy(() => import('pages/client/FormFillerBase'));

export const CLIENT_NESTED_ROUTES = {
  settings: '/settings' as const,
  profile: '/profile' as const,
  formFillerRoot: '/formFiller' as const,
  formFiller: '/formFiller/:transactionId/:formId' as const,
  formFillerBase: '/formFiller/:transactionId' as const,
  faq: '/FAQ' as const,
  contact: '/contact' as const,
  documentUploadRoot: '/documentUpload' as const,
  documentUploadPage: '/documentUpload/:transactionId/:documentId?' as const
};

export default function ClientNestedRoutes(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Suspense fallback={<LinearProgress />}>
      <Switch>
        <ProtectedRoute role="client" exact path={`${path}`}>
          <ClientTransactions />
        </ProtectedRoute>
        <ProtectedRoute
          role="client"
          exact
          path={`${path}${CLIENT_NESTED_ROUTES.documentUploadPage}`}
        >
          <ClientDocumentsUpload />
        </ProtectedRoute>
        <ProtectedRoute role="client" path={`${path}${CLIENT_NESTED_ROUTES.settings}`}>
          <ClientSettings />
        </ProtectedRoute>
        <ProtectedRoute role="client" path={`${path}${CLIENT_NESTED_ROUTES.profile}`}>
          <ClientProfile />
        </ProtectedRoute>
        <ProtectedRoute role="client" path={`${path}${CLIENT_NESTED_ROUTES.formFiller}`}>
          <FormFiller />
        </ProtectedRoute>
        <ProtectedRoute role="client" path={`${path}${CLIENT_NESTED_ROUTES.formFillerBase}`}>
          <FormFillerBase />
        </ProtectedRoute>
        <ProtectedRoute role="client" path={`${path}${CLIENT_NESTED_ROUTES.faq}`}>
          <FAQ />
        </ProtectedRoute>
        <ProtectedRoute role="client" path={`${path}${CLIENT_NESTED_ROUTES.contact}`}>
          <ContactUs />
        </ProtectedRoute>
        <Route>
          <NotFound text="The requested page was not found" />
        </Route>
      </Switch>
    </Suspense>
  );
}
