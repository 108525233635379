export interface TemplateDocument {
  id: string;
  name: string;
  custom?: boolean;
}

export interface TemplateForm {
  id: string;
}

export enum TemplateState {
  DRAFT = 'draft',
  IN_PROGRESS = 'in progress',
  COMPLETED = 'completed',
  SIGNED = 'signed',
  CANCELED = 'canceled',
  REJECTED = 'rejected',
  EXPIRED = 'expired'
}

export enum FormStatus {
  PENDING = 'pending',
  SUBMITTED = 'submitted',
  REJECTED = 'rejected'
}

export interface Template {
  ownerId: string;
  id: string;
  name: string;
  isTemp?: boolean;
  dateCreated: number;
  documents: TemplateDocument[];
  forms: TemplateForm[];
}
