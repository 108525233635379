import { forwardRef } from 'react';
import type { ComponentPropsWithoutRef, LegacyRef } from 'react';

export const Input = forwardRef(
  (
    { className = '', ...props }: ComponentPropsWithoutRef<'input'>,
    ref: LegacyRef<HTMLInputElement>
  ) => (
    <input
      className={`w-full h-[50px] border border-gray-4 rounded-[4px] p-4 focus:outline-none text-input ${className}`}
      ref={ref}
      {...props}
    />
  )
);

export const InputM = forwardRef(
  (
    { className = '', ...props }: ComponentPropsWithoutRef<'input'>,
    ref: LegacyRef<HTMLInputElement>
  ) => (
    <input
      className={`w-[260px] h-[50px] border border-gray-4 rounded-[4px] p-4 focus:outline-none text-input ${className}`}
      ref={ref}
      {...props}
    />
  )
);

export const InputLine = forwardRef(
  (
    { className = '', ...props }: ComponentPropsWithoutRef<'input'>,
    ref: LegacyRef<HTMLInputElement>
  ) => (
    <input
      className={`w-[220px] h-8 text-label italic text-gray-6 placeholder-gray-6 border-b border-gray-3 bg-transparent focus:outline-none ${className}`}
      ref={ref}
      {...props}
    />
  )
);
