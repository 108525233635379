interface Props {
  text: string;
  onClick?: () => void;
}

export function Chip({ text, onClick, ...props }: Props): JSX.Element {
  return (
    <div
      className="flex h-[38px] max-w-xs items-center justify-center rounded-md border border-gray-4 px-3 cursor-pointer hover:opacity-70"
      onClick={onClick}
      {...props}
    >
      <span className="font-medium text-list1 truncate" title={text}>
        {text}
      </span>
    </div>
  );
}
