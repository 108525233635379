import { getDocumentRef, getFormRef, getIdRef, getProfilePictureRef } from 'services/storage';
import { deleteObject, uploadBytes, uploadBytesResumable } from 'services/firebase';

import type { UploadResult, UploadTask } from 'types';

export function setProfilePicture(uid: string, file: Blob): Promise<UploadResult> {
  const imageRef = getProfilePictureRef(uid);

  return uploadBytes(imageRef, file);
}

export function setFormFile(formPath: string, file: Blob): UploadTask {
  const imageRef = getFormRef(formPath);

  return uploadBytesResumable(imageRef, file);
}

export function setDocumentFile(
  email: string,
  transactionId: string,
  documentId: string,
  file: Blob
): UploadTask {
  const imageRef = getDocumentRef(email, transactionId, documentId);
  return uploadBytesResumable(imageRef, file);
}

export function deleteDocumentFile(
  email: string,
  transactionId: string,
  documentId: string
): Promise<void> {
  const imageRef = getDocumentRef(email, transactionId, documentId);
  return deleteObject(imageRef);
}

export function setIdFile(email: string, uid: string, file: Blob): UploadTask {
  const imageRef = getIdRef(email, uid);

  return uploadBytesResumable(imageRef, file);
}

export function deleteIdFile(email: string, uid: string): Promise<void> {
  const imageRef = getIdRef(email, uid);
  return deleteObject(imageRef);
}
