import { NOTIFICATION_EVENTS, type HomelloNotification } from 'types';

export function stackNotifications(notifications: HomelloNotification[]): HomelloNotification[] {
  const stackedNotifications: HomelloNotification[] = [];
  const groupNotifications: any = {};

  // group notifications by openTransaction
  notifications.map((notification) => {
    const transactionId = notification?.metaData?.transactionId;
    if (!transactionId) {
      stackedNotifications.push(notification);
      return;
    }
    groupNotifications[transactionId] = groupNotifications[transactionId] || [];
    groupNotifications[transactionId].push(notification);
  });

  // do flat stacked notifications
  Object.keys(groupNotifications).map((key: string) => {
    const notifications: HomelloNotification[] = groupNotifications[key];
    let notSeen = false;
    let updateCount = 0;

    notifications.map((notification) => {
      // if one of stacked notifications is not seen, then set stacked as not seen
      const newNotification = notifications.find((n) => n.id === notification.id && !n.stacked);
      if (
        (!newNotification && notification.seen === false) ||
        (newNotification && newNotification.seen === false)
      )
        notSeen = true;
      updateCount += notification?.updateCount || 0;
    });

    stackedNotifications.push({
      ...notifications[0],
      stacked: true,
      seen: !notSeen,
      updateCount
    });
  });
  return stackedNotifications;
}

export function eventToText(
  event: HomelloNotification['event'],
  value?: string,
  action?: HomelloNotification
): string {
  switch (event) {
    case NOTIFICATION_EVENTS.formSeen: //as applicant has opened a form
      return 'Opened the transaction';
    case NOTIFICATION_EVENTS.documentCommitted: //an applicant has uploaded a document
      return 'Uploaded a document';
    case NOTIFICATION_EVENTS.documentUncommitted: //an applicant has deleted a document
      return 'Deleted a document';
    case NOTIFICATION_EVENTS.formCommitted: //an applicant has saved a form, this feature is removed from the backend
      return `${value} committed a form`;
    case NOTIFICATION_EVENTS.transactionSubmitted: //an applicant has submitted their transaction
      return 'Submitted the transaction';
    case NOTIFICATION_EVENTS.allTransactionSubmitted: //all the applicants have submitted their transactions
      return 'All applicants submitted';
    case NOTIFICATION_EVENTS.zipCompleted: //the requested zip file is ready to be downloaded
      return 'Download generated';
    case NOTIFICATION_EVENTS.applicantIdUploaded: //an applicant uploaded their ID
      return 'Uploaded an ID document';
    case NOTIFICATION_EVENTS.transactionSentSignrequest: //a sign request has been sent
      return `eSign ${action?.metaData?.eSignName || ''} sent`;
    case NOTIFICATION_EVENTS.applicantSigned: //an applicant has signed a form
      return 'Signed the transaction';
    case NOTIFICATION_EVENTS.allApplicantsSigned: //all applicants have signed a certain form, this feature is removed from backend
      return 'All applicants signed ' + value;
    case NOTIFICATION_EVENTS.transactionSigned: //all applicants have signed all the forms
      return 'Form Signature Complete';
    case NOTIFICATION_EVENTS.transactionComplete: //when forms do not contain any sign request fields and the realtor manually completes an transaction
      return 'Transaction has been completed';
    case NOTIFICATION_EVENTS.transactionRetractedSignrequest: //a sign request has been retracted
      return 'eSign has been Retracted';
    case NOTIFICATION_EVENTS.pdfFormCreated: //form file created for download
      return 'Download generated';
    case NOTIFICATION_EVENTS.incorrectEmail: //form file created for download
      return `Owner of ${value} has reported an incorrect email`;
    case 'createNewESign':
      return `eSign ${action?.metaData?.eSignName || ''} sent`;
    case 'createTransaction':
      return 'Sent Application';
    case 'draftTransaction':
      return 'Start Draft Application';
    case 'eSignEmailViewed':
      return `${value} view the email`;
    default:
      return event;
  }
}
