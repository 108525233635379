import { MlsData, MlsDataFieldTypes } from 'types';

export const mlsDataFieldTypes: MlsDataFieldTypes = {
  name: [],
  address: ['Address'],
  date: [
    'Sold Date',
    'Conditional Expiry Date',
    'Suspend Date',
    'Terminate Date',
    'Input Date',
    'List Date',
    'Open House Date 1',
    'Open House Date 2',
    'Open House Date 3',
    'Open House Update Date',
    'Photo Update Date',
    'Possession Date',
    'Closed Date',
    'Update Date',
    'Unavailable Date',
    'VTour Update Date',
    'Expiry Date',
    'Extension Entry Date'
  ],
  text: [],
  boolean: [
    'All Inclusive',
    'Cable Included',
    'CAC Included',
    'Central Vacuum',
    'Common Elements Included',
    'Family Room Den',
    'Elevator',
    'Energy Certificate',
    'Fireplace',
    'Furnished',
    'Handicap Equipped',
    'Heat Included',
    'Distribute Online',
    'Hydro Included',
    'Parking Included',
    'Private Entrance',
    'Retirement',
    'UFFI',
    'Prop Info Statement',
    'Water Included',
    'Condo Tax Included',
    'Bldg. Insurance Included',
    'Pets Allowed',
    'Air Con'
  ],
  number: [
    'Bathrooms',
    'Bedrooms',
    'Bedrooms+',
    'Co-Op Brokerage Commission',
    'Kitchen+',
    'Kitchen',
    'Room 10 Length',
    'Room 10 Width',
    'Room 11 Length',
    'Room 11 Width',
    'Room 12 Length',
    'Room 12 Width',
    'Room 1 Length',
    'Room 1 Width',
    'Room 2 Length',
    'Room 2 Width',
    'Room 3 Length',
    'Room 3 Width',
    'Room 4 Length',
    'Room 4 Width',
    'Room 5 Length',
    'Room 5 Width',
    'Room 6 Length',
    'Room 6 Width',
    'Room 7 Length',
    'Room 7 Width',
    'Room 8 Length',
    'Room 8 Width',
    'Room 9 Length',
    'Room 9 Width',
    'Rooms',
    'Rooms+',
    'Parking Spaces',
    'Condo Corp Number',
    'Stories',
    'Addl Monthly Fee',
    'Lot Depth',
    'Lot Width',
    'List Price',
    'Original Price',
    'Parking Cost',
    'Tax Cost',
    'Maintenance Cost'
  ],
  time: [
    'Open House Start Time 1',
    'Open House Start Time 2',
    'Open House Start Time 3',
    'Open House Stop Time 1',
    'Open House Stop Time 2',
    'Open House Stop Time 3'
  ],
  phoneNumber: []
};

export const mlsData: MlsData = {
  Address: [
    'Address',
    'Community',
    'Map Column',
    'Map Page',
    'Map Row',
    'Municipality',
    'Municipality Code',
    'Out Of Area Municipality',
    'Postal Code',
    'Province',
    'Street',
    'Street Direction',
    'Street Number',
    'Street Suffix',
    'TRREB Area Code',
    'TRREB Community Code',
    'Unit Number'
  ],
  'Physical Properties': [
    'Bathrooms',
    'Bathrooms+',
    'Building Style',
    'Building Type',
    'Kitchen',
    'Kitchen+',
    'Lot Depth',
    'Lot Size Unit',
    'Lot Width',
    'Private Entrance',
    'Rooms',
    'Rooms+',
    'Parking Included',
    'Parking Spaces',
    'Parking Cost'
  ],
  Expenses: [
    'Bldg. Insurance Included',
    'CAC Included',
    'Cable Included',
    'Common Elements Included',
    'Condo Tax Included',
    'Heat Included',
    'Hydro Included',
    'Maintenance Cost',
    'Tax Cost',
    'Original Price',
    'All Inclusive',
    'Water Included'
  ],
  Details: [
    'Ad Text',
    'Air Con',
    'Balcony',
    'Central Vacuum',
    'Elevator',
    'En suite Laundry',
    'Energy Certificate',
    'Extras',
    'Family Room Den',
    'Fireplace',
    'Furnished',
    'Handicap Equipped',
    'Heat Source',
    'Heating Type',
    'List Price',
    'Pets Allowed',
    'Possession',
    'Retirement',
    'UFFI'
  ],
  Dates: [
    'Closed Date',
    'Conditional Expiry Date',
    'Expiry Date',
    'Input Date',
    'List Date',
    'Photo Update Date',
    'Unavailable Date'
  ],
  MLS: ['Brokerage', 'Condo Corp', 'Condo Corp Number', 'MLS Number', 'Property Management']
};

export interface MLSType {
  id: string;
  'Hydro Included': boolean;
  'Room 5 Length': number;
  'Unavailable Date': number;
  'Room 4 Length': number;
  'Update Date': number;
  'Room 2 Length': number;
  'Room 3 Length': number;
  'Room 1 Width': number;
  Extras: string;
  'Ad Text': string;
  'Tour URL': string;
  'Is Available': boolean;
  'Room 1 Length': number;
  'Heating Type': string;
  'Property Management': string;
  'Parking Cost': number;
  'Conditional Expiry Date': number;
  'List Date': number;
  'Room 4 Width': number;
  'Municipality District': string;
  Status: string;
  'Room 6 Desc 1': string;
  'Room 6 Desc 2': string;
  'Bldg Age': string;
  'Room 8 Desc 3': string;
  'Room 8 Desc 2': string;
  'Room 6 Desc 3': string;
  'Map Row': string;
  'Map Column': string;
  'Prior Status': string;
  UFFI: boolean;
  Bedrooms: number;
  'Room 2 Desc 1': string;
  'Room 2 Desc 2': string;
  'Expiry Date': number;
  'Room 4 Desc 2': string;
  'Room 4 Desc 3': string;
  'Room 2 Desc 3': string;
  'Photo Update Date': number;
  'Room 8 Desc 1': string;
  'Room 4 Desc 1': string;
  Fireplace: boolean;
  'Heat Included': boolean;
  'Display Address Online': boolean;
  'Out Of Area Municipality': string;
  'Energy Certificate': boolean;
  'Municipality Code': string;
  'Condo Tax Included': boolean;
  'Prop Info Statement': boolean;
  'Building Type': string;
  'Street Address': string;
  'Room 1 Type': string;
  'Sold Price': string;
  Retirement: boolean;
  'Street Number': string;
  'Central Vacuum': boolean;
  'Room 9 Desc 3': string;
  'Room 12 Desc 2': string;
  'Room 12 Desc 1': string;
  'CAC Included': boolean;
  'Room 12 Desc 3': string;
  Rooms: number;
  'Input Date': number;
  'List Category': string;
  'Room 1 Desc 3': string;
  'Bldg. Insurance Included': boolean;
  'Pets Allowed': boolean;
  'Distribute Online': boolean;
  'Unit Number': string;
  'Room 9 Desc 1': string;
  'Room 9 Desc 2': string;
  'Room 5 Desc 3': string;
  'Room 1 Desc 1': string;
  'Room 5 Desc 2': string;
  'Room 1 Desc 2': string;
  'Room 5 Desc 1': string;
  'Is Lease': boolean;
  Brokerage: string;
  'Parking Facility': string;
  'Condo Corp': string;
  'Condo Exposure': string;
  'Street Direction': string;
  Exterior: string;
  'Cable Included': boolean;
  'Condo Corp Number': number;
  'Special Designation 1': string;
  'Room 10 Desc 3': string;
  'Bedrooms+': number;
  'Room 4 Type': string;
  'MLS Number': string;
  'Air Con': string;
  'TRREB Area Code': string;
  'En suite Laundry': boolean;
  'Postal Code': string;
  'Room 11 Desc 1': string;
  Balcony: string;
  'Room 11 Desc 2': string;
  'Room 11 Desc 3': string;
  'Room 5 Width': number;
  'Common Elements Included': boolean;
  City: string;
  'Room 2 Width': number;
  Province: string;
  'Room 3 Type': string;
  'Heat Source': string;
  Kitchen: number;
  'Building Style': string;
  'Family Room Den': boolean;
  Locker: string;
  Furnished: boolean;
  'TRREB Community Code': string;
  'Parking Included': boolean;
  'Street Suffix': string;
  Elevator: boolean;
  'List Price': number;
  'Private Entrance': boolean;
  'Water Included': boolean;
  Stories: number;
  'Kitchen+': number;
  'Room 7 Desc 2': string;
  'Room 7 Desc 1': string;
  'Room 5 Type': string;
  'Room 7 Desc 3': string;
  'Street Name': string;
  'Original Price': number;
  'Room 2 Type': string;
  'Room 10 Desc 1': string;
  'Room 10 Desc 2': string;
  'Map Page': string;
  'Room 3 Width': number;
  'All Inclusive': boolean;
  'Handicap Equipped': boolean;
  Possession: string;
  'Room 3 Desc 2': string;
  Bathrooms: number;
  'Room 3 Desc 1': string;
  'Room 3 Desc 3': string;
  Community: string;
  createTime: string;
  updateTime: string;
}

export const getMLSAddress = (mls?: MLSType) => mls && `${mls['Street Address']}`;
