import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { DrawerProvider } from '../providers/DrawerProvider';
import { ModalProvider } from '../providers/ModalProvider';
import { NotFound } from 'components';

import AuthRoutes from 'routers/AuthRoutes';
import RealtorRoutes from 'routers/RealtorRoutes';
import ClientRoutes from 'routers/ClientRoutes';
import AdminRoutes from 'routers/AdminRoutes';
import Profile from 'pages/Profile';
import Settings from 'pages/Settings';

export const ROOT_ROUTES = {
  auth: '/auth' as const,
  realtor: '/realtor' as const,
  client: '/client' as const,
  admin: '/admin' as const,
  profile: '/profile' as const,
  settings: '/settings' as const
};

export default function Routes(): JSX.Element {
  return (
    <Router>
      <ModalProvider>
        <DrawerProvider>
          <Switch>
            <Route exact path="/">
              <Redirect to={ROOT_ROUTES.client} />
            </Route>
            <Route path={ROOT_ROUTES.auth}>
              <AuthRoutes />
            </Route>
            <Route path={ROOT_ROUTES.realtor}>
              <RealtorRoutes />
            </Route>
            <Route path={ROOT_ROUTES.client}>
              <ClientRoutes />
            </Route>
            <Route path={ROOT_ROUTES.admin}>
              <AdminRoutes />
            </Route>
            <Route path={ROOT_ROUTES.profile}>
              <Profile />
            </Route>
            <Route path={ROOT_ROUTES.settings}>
              <Settings />
            </Route>
            <Route>
              <NotFound text="The requested page was not found" />
            </Route>
          </Switch>
        </DrawerProvider>
      </ModalProvider>
    </Router>
  );
}
