import { useState, useRef, useEffect } from 'react';
import throttle from 'lodash.throttle';
import type { DocumentProps } from 'react-pdf';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
interface Prop {
  docURL?: string | Blob;
}

export interface PDFPage {
  x: number;
  y: number;
  index: number;
  width: number;
  height: number;
  prevHeight: number;
}

interface UsePdfViewer {
  pdfWrapperRef: React.RefObject<HTMLDivElement>;
  pdfClassName: string;
  numPages: number;
  wrapperWidth: number;
  originalWidth: number;
  originalHeight: number;
  isPdfLoading: boolean;
  onLoadSuccess: DocumentProps['onLoadSuccess'];
  onLoadProgress: DocumentProps['onLoadProgress'];
  pages: PDFPage[];
}

export function usePdfViewer({ docURL }: Prop): UsePdfViewer {
  const [pdfClassName, setPdfClassName] = useState('');
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [originalWidth, setOriginalWidth] = useState(0);
  const [originalHeight, setOriginalHeight] = useState(0);
  const [numPages, setNumPages] = useState(0);
  const [isPdfLoading, setIsPdfLoading] = useState(true);
  const [pages, setPages] = useState<PDFPage[]>();
  const windowWidth = useRef(0);
  const pdfWrapperRef = useRef<HTMLDivElement>(null);

  const onLoadSuccess: DocumentProps['onLoadSuccess'] = async (pdf) => {
    const page = await pdf.getPage(1);
    const pages: PDFPage[] = [];
    setOriginalWidth(page.view[2]);
    setNumPages(pdf.numPages);
    setIsPdfLoading(false);
    let height = 0;
    for (let index = 0; index < pdf.numPages; index++) {
      const page = await pdf.getPage(index + 1);
      pages.push({
        index,
        x: page.view[0],
        y: page.view[1],
        height: page.view[3],
        width: page.view[2],
        prevHeight: height
      });
      height += page.view[3];
    }
    setPages(pages);
    setOriginalHeight(height);
  };

  function onLoadProgress() {
    setIsPdfLoading(true);
  }

  function setFormViewerSize() {
    //To let the flex box have its natural width, should hide elements with fixed sizes
    const newWindowWidth = window.innerWidth;
    if (windowWidth.current !== newWindowWidth && pdfWrapperRef.current) {
      windowWidth.current = newWindowWidth;
      setPdfClassName('hidden');
      setWrapperWidth(pdfWrapperRef.current.getBoundingClientRect().width);
      setPdfClassName('');
    }
  }

  useEffect(() => {
    setFormViewerSize();
    const setFormViewerSizeThrottled = throttle(setFormViewerSize, 500);
    window.addEventListener('resize', setFormViewerSizeThrottled);
    return () => {
      window.removeEventListener('resize', setFormViewerSizeThrottled);
    };
  }, []);

  useEffect(() => {
    setIsPdfLoading(true);
    return () => {
      //avoiding error when switching from pdf with more pages to pdf with less pages
      //somtimes it happens, not always
      setNumPages(1);
    };
  }, [docURL]);

  return {
    pdfWrapperRef,
    pdfClassName,
    numPages,
    wrapperWidth,
    originalWidth,
    originalHeight,
    isPdfLoading,
    onLoadSuccess,
    onLoadProgress,
    pages
  };
}
