import { db, collection, collectionGroup } from 'services/firebase';
import type {
  CollectionReference,
  RealtorSettings,
  ClientSettings,
  Profile,
  Template,
  Form,
  Applicant,
  Transaction,
  ClientTransaction,
  OpenTransaction,
  HomelloNotification,
  MarkDowns,
  LastActivity,
  TransactionForm
} from 'types';
import { FieldItem } from 'types';
import { Clause } from 'types/clause';

import { ESign, Transaction as NewTransaction } from 'types/newTransaction';

export const clauseCollection = collection(db, 'clauses') as CollectionReference<Clause>;

export const profileCollection = collection(db, 'profile') as CollectionReference<Profile>;

export const settingsCollection = collection(db, 'settings') as CollectionReference<
  RealtorSettings | ClientSettings
>;

export const fieldsCollection = collection(db, 'fields') as CollectionReference<FieldItem>;

export const templatesCollection = collection(db, 'templates') as CollectionReference<Template>;

export const formsCollection = collection(db, 'forms') as CollectionReference<Form>;

export const transactionFormsCollection = collection(
  db,
  'transaction_forms'
) as CollectionReference<TransactionForm>;

export const draftFormsCollection = collection(db, 'draft_forms') as CollectionReference<Form>;

export const systemFormsCollection = collection(db, 'system_forms') as CollectionReference<Form>;

export const applicantsCollection = collection(db, 'applicants') as CollectionReference<Applicant>;

export const transactionsCollection = collection(
  db,
  'transactions'
) as CollectionReference<Transaction>;

export const newTransactionsCollection = collection(
  db,
  'new__transactions'
) as CollectionReference<NewTransaction>;

export const clientTransactionsParentCollection = collection(
  db,
  'transactions'
) as CollectionReference<ClientTransaction>;

export const openTransactionsParentCollection = collection(
  db,
  'transactions'
) as CollectionReference<OpenTransaction>;

export const openTransactionsCollection = collectionGroup(
  db,
  'openTransactions'
) as CollectionReference<OpenTransaction>;

export const clientTransactionsCollection = collectionGroup(
  db,
  'clientTransactions'
) as CollectionReference<ClientTransaction>;

export const notificationsCollection = collection(
  db,
  'notifications'
) as CollectionReference<HomelloNotification>;

export const eSignsCollection = collection(db, 'e_signs') as CollectionReference<ESign>;

export const actionsCollection = collection(
  db,
  'actions'
) as CollectionReference<HomelloNotification>;

export const markDownsCollection = collection(db, 'mark_downs') as CollectionReference<MarkDowns>;

export const lastActivitiesCollection = collection(
  db,
  'last_activities'
) as CollectionReference<LastActivity>;
