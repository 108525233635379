import loading from 'assets/icons/gray-loading.png';
import loadingBlue from 'assets/icons/blue-loading.png';

import { FullPageLoaderColor } from 'types';

interface Props {
  visible: boolean;
  color: FullPageLoaderColor;
  keyName: string;
}

export default function FullPageLoader({ visible, color, keyName }: Props): JSX.Element | null {
  if (!visible) {
    return null;
  }

  return (
    <div
      style={{ zIndex: '9999' }}
      className="w-screen h-screen fixed overflow-hidden top-0 left-0 bg-gray-2 bg-opacity-50 flex items-center justify-center"
      data-key={keyName}
    >
      <img src={color !== 'blue' ? loadingBlue : loading} className="animate-spin" alt="Homello" />
    </div>
  );
}
