import { createContext, useCallback, useState } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';

import { ConfirmationBox as DefaultConfirmationBox } from 'components';

import closeIcon from 'assets/icons/close.svg';

import type { ReactNode } from 'react';

export interface ConfirmationBoxProps {
  onReject: () => void;
  onConfirm: () => void;
  props: {
    title: string;
    text: string;
    button: string;
    cancelButton?: string;
    hideCancel?: boolean;
    color: string;
  };
}

export interface ContextProps {
  getConfirmation: (
    props: ConfirmationProps
  ) => Promise<boolean | ((value: boolean | PromiseLike<boolean>) => void)>;
}

interface Props {
  children: ReactNode;
}

interface Resolve {
  resolve: (value: boolean | PromiseLike<boolean>) => void;
}

interface ConfirmationProps {
  title: string;
  text: string;
  button: string;
  cancelButton?: string;
  hideCancel?: boolean;
  color: string;
  size?: ModalSize;
  onClose?: 'reject' | 'confirm';
  customBox?: ({
    onReject,
    onConfirm,
    props: { title, text, button, cancelButton, color }
  }: ConfirmationBoxProps) => JSX.Element;
}

type ModalSize = 'Small' | 'Medium';

function createPromise() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let resolver = (value: boolean | PromiseLike<boolean>) => {};

  const promise = new Promise<boolean>((resolve) => {
    resolver = resolve;
  });

  return { promise, resolver };
}

export const ConfirmationContext = createContext<ContextProps>({} as ContextProps);

export function ConfirmationProvider({ children }: Props): JSX.Element {
  const [visible, setVisible] = useState(false);
  const [modalSize, setModalSize] = useState<ModalSize>('Small');
  const [props, setProps] = useState<ConfirmationProps>({} as ConfirmationProps);
  const [resolve, setResolve] = useState<Resolve>({} as Resolve);
  const [ConfirmationBox, setConfirmationBox] = useState({ Box: DefaultConfirmationBox });

  async function getConfirmation({ customBox, size, ...props }: ConfirmationProps) {
    const { promise, resolver } = createPromise();

    if (customBox) {
      setConfirmationBox({ Box: customBox });
    } else {
      setConfirmationBox({ Box: DefaultConfirmationBox });
    }

    if (size) {
      setModalSize(size);
    } else {
      setModalSize('Small');
    }

    setVisible(true);
    setProps(props);
    setResolve({ resolve: resolver });

    return promise;
  }

  const handleConfirm = useCallback(() => {
    setVisible(false);
    resolve.resolve(true);
  }, [resolve]);

  const handleReject = useCallback(() => {
    setVisible(false);
    resolve.resolve(false);
  }, [resolve]);

  const value: ContextProps = {
    getConfirmation
  };

  return (
    <ConfirmationContext.Provider value={value}>
      {children}
      <Modal
        className="flex items-center justify-center"
        open={visible}
        onClose={() => {
          if (props.onClose === 'confirm') {
            handleConfirm();
          } else {
            handleReject();
          }
          setVisible(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200
        }}
      >
        {(() => {
          switch (modalSize) {
            case 'Small':
              return (
                <Fade in={visible}>
                  <div
                    style={{ boxShadow: '0 24px 48px 0 rgba(0,0,0,0.2)' }}
                    className="relative w-full max-w-xs bg-white rounded-3xl pt-7 pr-5 pb-[22px] pl-[30px] overflow-y-auto focus:outline-none"
                  >
                    <div
                      className="absolute right-5 top-5 cursor-pointer hover:opacity-70"
                      onClick={() => {
                        handleReject();
                        setVisible(false);
                      }}
                    >
                      <img src={closeIcon} className="w-6 h-6" alt="Close" />
                    </div>
                    <ConfirmationBox.Box
                      onReject={handleReject}
                      onConfirm={handleConfirm}
                      props={props}
                    />
                  </div>
                </Fade>
              );
            case 'Medium':
              return (
                <Fade in={visible}>
                  <div
                    style={{
                      boxShadow: '0 24px 48px 0 rgba(0,0,0,0.2)'
                    }}
                    className="relative w-full max-w-[640px] max-h-screen mt-10 sm:mt-0 h-[calc(100vh-2.5rem)] sm:h-auto overflow-y-auto bg-white rounded-t-3xl sm:rounded-3xl px-[30px] sm:px-[50px] py-10 focus:outline-none"
                  >
                    <div
                      className="absolute right-5 top-5 cursor-pointer hover:opacity-70"
                      onClick={() => {
                        handleReject();
                        setVisible(false);
                      }}
                    >
                      <img src={closeIcon} className="w-6 h-6" alt="Close" />
                    </div>
                    <ConfirmationBox.Box
                      onReject={handleReject}
                      onConfirm={handleConfirm}
                      props={props}
                    />
                  </div>
                </Fade>
              );
            default:
              const exhaustiveCheck: never = modalSize;
              return exhaustiveCheck;
          }
        })()}
      </Modal>
    </ConfirmationContext.Provider>
  );
}
