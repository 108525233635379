import { getStorage, ref } from 'services/firebase';

import type { StorageReference } from 'types';

export function getProfilePictureRef(uid: string): StorageReference {
  const storage = getStorage();
  const imagesRef = ref(storage, `${uid}/profile_pictures/profile_picture`);

  return imagesRef;
}

export function getFormRef(formPath: string): StorageReference {
  const storage = getStorage();
  const imagesRef = ref(storage, formPath);
  return imagesRef;
}

export function getDocumentURL(email: string, transactionId: string, documentId: string): string {
  return `${email}/documents/${transactionId}/${documentId}`;
}

export function getDocumentRef(
  email: string,
  transactionId: string,
  documentId: string
): StorageReference {
  const storage = getStorage();
  const imagesRef = ref(storage, `${email}/documents/${transactionId}/${documentId}`);

  return imagesRef;
}

export function getIdRef(email: string, uid: string): StorageReference {
  const storage = getStorage();
  const imagesRef = ref(storage, `${email}/${uid}/id/clientId`);

  return imagesRef;
}

export function getRefByPath(path: string): StorageReference {
  const storage = getStorage();
  const imagesRef = ref(storage, path);

  return imagesRef;
}

export function getTransactionFileByPathRef(path: string): StorageReference {
  const storage = getStorage();
  const imagesRef = ref(storage, path);

  return imagesRef;
}
