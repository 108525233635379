import { QueryClient, QueryClientProvider } from 'react-query';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

import { SnackbarProvider } from 'providers/SnackbarProvider';
import { ConfirmationProvider } from 'providers/ConfirmationProvider';
import { FullPageLoaderProvider } from 'providers/FullPageLoaderProvider';
import { AuthProvider } from 'providers/AuthProvider';
import reactQueryDefaultOptions from 'config/react-query';
import Routes from 'routers/Routes';
import { useEffect } from 'react';

export const queryClientOptions = new QueryClient({
  defaultOptions: reactQueryDefaultOptions
});
declare global {
  interface Window {
    shiftKey?: boolean;
  }
}

function App(): JSX.Element {
  useEffect(() => {
    const onKeyDown = (_event) => {
      window.shiftKey = (_event as KeyboardEvent).shiftKey;
    };
    const onKeyUp = () => {
      window.shiftKey = false;
    };
    document.removeEventListener('keyup', onKeyUp);
    document.removeEventListener('keydown', onKeyDown);
    return () => {
      document.addEventListener('keydown', onKeyDown);
      document.addEventListener('keyup', onKeyUp);
    };
  }, []);
  return (
    <QueryClientProvider client={queryClientOptions}>
      <FullPageLoaderProvider>
        <SnackbarProvider>
          <ConfirmationProvider>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </ConfirmationProvider>
        </SnackbarProvider>
      </FullPageLoaderProvider>
    </QueryClientProvider>
  );
}

export default App;
