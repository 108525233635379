function on(eventName: string, listener: EventListenerOrEventListenerObject) {
  document.addEventListener(eventName, listener);
}

function once(eventName: string, listener: EventListenerOrEventListenerObject) {
  document.addEventListener(eventName, listener, { once: true });
}

function off(eventName: string, listener: EventListenerOrEventListenerObject) {
  document.removeEventListener(eventName, listener);
}

function emit(eventName: string, data: any) {
  const event = new CustomEvent(eventName, {
    detail: data
  });
  document.dispatchEvent(event);
}

const eventEmitter = {
  on,
  off,
  emit,
  once
};

export default eventEmitter;
