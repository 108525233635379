const REALTOR_ID_HEADER_TEXT = 'REAL ESTATE COUNCIL OF';
const NAME_HEADER_TEXT = 'CERTIFICATE OF REGISTRATION';
const BROKERAGE_NAME_HEADER_TEXT = 'Employer:';
const EXPIRY_HEADER_TEXT = 'Expiry: ';

interface ParseRecoText {
  name: string;
  expiry: string;
  brokerageName: string;
  realtorId: string;
}

function findLineIndexStartingWith(lines: string[], start: string) {
  return lines.findIndex((line) => line.startsWith(start));
}

function getLineStartingWith(lines: string[], start: string) {
  const lineIndex = findLineIndexStartingWith(lines, start);
  return lineIndex !== -1 ? lines[lineIndex] : '';
}

function getNextLineStartingWith(lines: string[], start: string) {
  const lineIndex = findLineIndexStartingWith(lines, start);
  return lineIndex !== -1 ? lines[lineIndex + 1] ?? '' : '';
}

export function parseRecoText(text: string): ParseRecoText {
  const lines = text.split('\n');
  const realtorIdLine = getNextLineStartingWith(lines, REALTOR_ID_HEADER_TEXT);
  const name = getNextLineStartingWith(lines, NAME_HEADER_TEXT);
  const brokerageName = getNextLineStartingWith(lines, BROKERAGE_NAME_HEADER_TEXT);
  const expiryLine = getLineStartingWith(lines, EXPIRY_HEADER_TEXT);

  const realtorId = realtorIdLine.split(' ')[1] ?? '';
  const expiry = expiryLine.substring(EXPIRY_HEADER_TEXT.length);
  return {
    name,
    expiry,
    brokerageName,
    realtorId
  };
}

interface GetNameParts {
  firstName: string;
  middleName: string;
  lastName: string;
}

function capitalizeFirstLetter(str: string) {
  return str
    .split('')
    .map((char, i) => {
      if (i === 0) {
        return char.toUpperCase();
      }

      return char.toLowerCase();
    })
    .join('');
}

export function extractNameParts(fullName: string): GetNameParts {
  const [first, ...rest] = fullName.split(' ').reduce((prev, curr) => {
    if (curr) {
      return [...prev, capitalizeFirstLetter(curr)];
    }
    return prev;
  }, [] as string[]);
  const firstName = first ?? '';
  const lastName = rest.pop() ?? '';
  const middleName = rest.join(' ');

  return { firstName, middleName, lastName };
}
