import { getDoc, getDocs } from 'services/firebase';
import {
  allSystemFormsQuery,
  applicantsQuery,
  clientTransactionQuery,
  clientTransactionsQuery,
  transactionFormsQuery,
  draftFormsQuery,
  fieldsQuery,
  formsQuery,
  lastActivitiesDoc,
  markDownsDoc,
  notificationsByOpenTransactionIdQuery,
  notificationsByTransactionIdQuery,
  notificationsQuery,
  openTransactionQuery,
  openTransactionsQuery,
  profileDoc,
  realtorClientTransactionsQuery,
  settingsDoc,
  templatesQuery,
  transactionQuery,
  transactionsByClientQuery,
  transactionsQuery,
  clausesQuery,
  transactionsNewQuery
} from 'services/firestore';
import type {
  Applicant,
  ClientSettings,
  ClientTransaction,
  DocumentSnapshot,
  FieldItem,
  Form,
  HomelloNotification,
  LastActivity,
  MarkDowns,
  OpenTransaction,
  Profile,
  QuerySnapshot,
  RealtorSettings,
  Template,
  Transaction,
  TransactionForm
} from 'types';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { Clause } from 'types/clause';
import { Transaction as NewTransaction } from 'types/newTransaction';

export function getProfile(uid: string): Promise<DocumentSnapshot<Profile>> {
  return getDoc<Profile>(profileDoc(uid));
}

export function getSettings(
  uid: string
): Promise<DocumentSnapshot<RealtorSettings | ClientSettings>> {
  return getDoc<RealtorSettings | ClientSettings>(settingsDoc(uid));
}

export function getTemplates(uid: string): Promise<QuerySnapshot<Template>> {
  return getDocs(templatesQuery(uid));
}

export function getForm(uid: string): Promise<QuerySnapshot<Form>> {
  return getDocs(formsQuery(uid));
}

export function getFields(): Promise<QuerySnapshot<FieldItem>> {
  return getDocs(fieldsQuery());
}

export function getTransactionForm(
  uid: string,
  transactionId: string
): Promise<QuerySnapshot<TransactionForm>> {
  return getDocs(transactionFormsQuery(uid, transactionId));
}

export function getDraftForm(uid: string): Promise<QuerySnapshot<Form>> {
  return getDocs(draftFormsQuery(uid));
}

export function getSystemForms(): Promise<QuerySnapshot<Form>> {
  return getDocs(allSystemFormsQuery());
}

export function getApplicants(uid: string): Promise<QuerySnapshot<Applicant>> {
  return getDocs(applicantsQuery(uid));
}

export function getClientTransactions(email: string): Promise<QuerySnapshot<ClientTransaction>> {
  return getDocs(clientTransactionsQuery(email));
}

export function getTransactionsByClient(email: string): Promise<QuerySnapshot<Transaction>> {
  return getDocs(transactionsByClientQuery(email));
}

export function getTransaction(
  uid: string,
  transactionId: string
): Promise<QuerySnapshot<Transaction>> {
  return getDocs(transactionQuery(uid, transactionId));
}

export function getClauses(
  uid: string,
  sort: string,
  ascending: boolean
): Promise<QuerySnapshot<Clause>> {
  return getDocs(clausesQuery(uid, sort, ascending));
}

export function getTransactions(
  uid: string,
  sort: string,
  ascending: boolean,
  limit: number
): Promise<QuerySnapshot<Transaction>> {
  return getDocs(transactionsQuery(uid, sort, ascending, limit));
}

export function getNewTransactions(
  uid: string,
  sort: string,
  ascending: boolean,
  limit: number
): Promise<QuerySnapshot<NewTransaction>> {
  return getDocs(transactionsNewQuery(uid, sort, ascending, limit));
}

export function getOpenTransactions(
  uid: string,
  transactionId: string
): Promise<QuerySnapshot<OpenTransaction>> {
  return getDocs(openTransactionsQuery(uid, transactionId));
}

export function getOpenTransaction(
  uid: string,
  openTransactionId: string
): Promise<QuerySnapshot<OpenTransaction>> {
  return getDocs(openTransactionQuery(uid, openTransactionId));
}

export function getRealtorClientTransactions(
  uid: string,
  openTransactionId: string
): Promise<QuerySnapshot<ClientTransaction>> {
  return getDocs(realtorClientTransactionsQuery(uid, openTransactionId));
}

export function getClientTransaction(
  id: string,
  email: string
): Promise<QuerySnapshot<ClientTransaction>> {
  return getDocs(clientTransactionQuery(id, email));
}

export function getNotifications(
  uid: string,
  startAfterCursor: QueryDocumentSnapshot<HomelloNotification> | null
): Promise<QuerySnapshot<HomelloNotification>> {
  return getDocs(notificationsQuery(uid, startAfterCursor));
}

export function getNotificationsByOpenTransactionId(
  uid: string,
  openTransactionId: string
): Promise<QuerySnapshot<HomelloNotification>> {
  return getDocs(notificationsByOpenTransactionIdQuery(uid, openTransactionId));
}

export function getNotificationsByTransactionId(
  uid: string,
  transactionId: string,
  type: 'transaction' | 'eSign' = 'transaction'
): Promise<QuerySnapshot<HomelloNotification>> {
  return getDocs(notificationsByTransactionIdQuery(uid, transactionId, type));
}

export function getMarkDowns(): Promise<DocumentSnapshot<MarkDowns>> {
  return getDoc<MarkDowns>(markDownsDoc());
}

export function getLastActivity(uid: string): Promise<DocumentSnapshot<LastActivity>> {
  return getDoc<LastActivity>(lastActivitiesDoc(uid));
}
