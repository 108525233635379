import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import ProtectedRoute from './ProtectedRoute';
import { NotFound } from 'components';

const NewForm = lazy(() => import('containers/admin/NewForm'));
const Templates = lazy(() => import('pages/realtor/Templates'));
const Transactions = lazy(() => import('pages/realtor/Transactions'));
const RealtorProfile = lazy(() => import('pages/realtor/RealtorProfile'));
const RealtorSettings = lazy(() => import('pages/realtor/RealtorSettings'));
const TransactionData = lazy(() => import('pages/realtor/TransactionData'));
const FormBuilder = lazy(() => import('pages/realtor/FormBuilder'));
const MarkDownEditor = lazy(() => import('pages/admin/MarkDownEditor'));
const FAQ = lazy(() => import('pages/realtor/FAQ'));
const ContactUs = lazy(() => import('pages/realtor/ContactUs'));

import type { HomelloNotification } from 'types';
import LinearProgress from 'components/LinearProgress';

export const ADMIN_NESTED_ROUTES = {
  newForm: '/newForm' as const,
  formBuilderRoot: '/formBuilder' as const,
  formBuilder: '/formBuilder/:formId' as const,
  templates: '/templates' as const,
  settings: '/settings' as const,
  profile: '/profile' as const,
  transactionData: '/transactionData' as const,
  faq: '/FAQ' as const,
  contact: '/contact' as const,
  markDownEditor: '/markDownEditor' as const
};

interface Props {
  notifications: HomelloNotification[] | null;
}

export default function RealtorNestedRoutes({ notifications }: Props): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Suspense fallback={<LinearProgress />}>
      <Switch>
        <ProtectedRoute role="admin" exact path={`${path}`}>
          <Transactions notifications={notifications ? notifications : null} />
        </ProtectedRoute>
        <ProtectedRoute role="admin" exact path={`${path}${ADMIN_NESTED_ROUTES.formBuilderRoot}`}>
          <NewForm />
        </ProtectedRoute>
        <ProtectedRoute role="admin" exact path={`${path}${ADMIN_NESTED_ROUTES.newForm}`}>
          <NewForm newForm={true} />
        </ProtectedRoute>
        <ProtectedRoute role="admin" path={`${path}${ADMIN_NESTED_ROUTES.formBuilder}`}>
          <FormBuilder />
        </ProtectedRoute>
        <ProtectedRoute role="admin" path={`${path}${ADMIN_NESTED_ROUTES.templates}`}>
          <Templates />
        </ProtectedRoute>
        <ProtectedRoute role="admin" path={`${path}${ADMIN_NESTED_ROUTES.profile}`}>
          <RealtorProfile />
        </ProtectedRoute>
        <ProtectedRoute role="admin" path={`${path}${ADMIN_NESTED_ROUTES.settings}`}>
          <RealtorSettings />
        </ProtectedRoute>
        <ProtectedRoute role="admin" path={`${path}${ADMIN_NESTED_ROUTES.transactionData}`}>
          <TransactionData />
        </ProtectedRoute>
        <ProtectedRoute role="admin" path={`${path}${ADMIN_NESTED_ROUTES.markDownEditor}`}>
          <MarkDownEditor />
        </ProtectedRoute>
        <ProtectedRoute role="admin" path={`${path}${ADMIN_NESTED_ROUTES.faq}`}>
          <FAQ />
        </ProtectedRoute>
        <ProtectedRoute role="admin" path={`${path}${ADMIN_NESTED_ROUTES.contact}`}>
          <ContactUs />
        </ProtectedRoute>
        <Route>
          <NotFound text="The requested page was not found" />
        </Route>
      </Switch>
    </Suspense>
  );
}
