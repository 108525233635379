import { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient, UseQueryOptions, QueryKey } from 'react-query';

import { useAuth } from 'hooks';

import { WithoutOwnerId, QuerySnapshot } from 'types';

interface Props<T, O extends any[] = any[]> {
  queryKey: string;
  getter: (uid: string, ...args: O) => Promise<QuerySnapshot<T>>;
  setter: (applicant: T) => Promise<void>;
  remover: (id: string) => Promise<void>;
}

export interface UseCRUDConf<T = any, O = any> {
  args?: O;
  options?: Omit<
    UseQueryOptions<QuerySnapshot<T>, QuerySnapshot<T>, QuerySnapshot<T>, QueryKey>,
    'queryKey' | 'queryFn'
  >;
}
interface UseCRUD<T> {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  items: T[] | null;
  setItem: (item: WithoutOwnerId<T>) => Promise<void>;
  removeItem: (id: string) => Promise<void>;
}

export function useCRUD<T extends { ownerId: string }, O extends any[] = any[]>(
  { queryKey, getter, setter, remover }: Props<T, O>,
  conf?: UseCRUDConf<T, O>
): UseCRUD<T> {
  const { args = [] as O, options } = conf || {};
  const [items, setItems] = useState<T[] | null>(null);
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  const uid = currentUser!.uid;

  const itemQuery = useQuery(queryKey, () => getter(uid, ...args), options);

  const setItemMutation = useMutation<void, unknown, T, unknown>((item) => setter(item), {
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    }
  });

  const removeItemMutation = useMutation<void, unknown, string, unknown>((id) => remover(id), {
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    }
  });

  function addOwnerIdToData(data: WithoutOwnerId<T>): T {
    return { ...data, ownerId: uid } as T;
  }

  function setItem(item: WithoutOwnerId<T>) {
    return setItemMutation.mutateAsync(addOwnerIdToData(item));
  }

  function removeItem(id: string) {
    return removeItemMutation.mutateAsync(id);
  }

  useEffect(() => {
    if (itemQuery.isSuccess) {
      const result = itemQuery.data;
      const queryResult: T[] = [];
      if (!result.empty) {
        result.forEach((item) => queryResult.push({ ...item.data(), id: item.id }));
      }
      setItems(queryResult);
    }
  }, [itemQuery.data, itemQuery.isSuccess]);

  return {
    isLoading: itemQuery.isLoading,
    isError: itemQuery.isError,
    isSuccess: itemQuery.isSuccess,
    items,
    setItem,
    removeItem
  };
}
