import { functions, httpsCallable } from 'services/firebase';
import type { HttpsCallableResult, RetractTransactionResponse } from 'types';

export function retractTransaction(
  eSignId: string
): Promise<HttpsCallableResult<RetractTransactionResponse>> {
  const retractTransaction = httpsCallable<{ eSignId: string }, RetractTransactionResponse>(
    functions,
    'retractSignrequest'
  );
  return retractTransaction({ eSignId });
}
