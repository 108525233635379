import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function useURLQuery<T = any>(
  parser?: {
    [key in keyof Partial<T>]: (value: any) => any;
  },
  replace?: boolean
) {
  const { search, hash } = useLocation();
  const history = useHistory();

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const urlQuery = new Proxy(
    {},
    {
      get(_, p) {
        if (typeof p === 'string') {
          const res = query.getAll(p);
          if (parser?.[p]) {
            return parser[p](res);
          }
          if (res.length === 0) return undefined;
          if (res.length === 1) return res[0];
          return res;
        }
      },
      set(target, p, newValue) {
        if (typeof p === 'string') {
          target[p] = newValue;
          if (newValue && !Array.isArray(newValue)) {
            query.set(p, newValue);
          } else if (newValue && Array.isArray(newValue)) {
            query.delete(p);
            for (let index = 0; index < newValue.length; index++) {
              query.append(p, newValue[index]);
            }
          } else {
            query.delete(p);
          }
          if (replace) {
            history.replace({
              search: query.toString(),
              hash
            });
          } else {
            history.push({
              search: query.toString(),
              hash
            });
          }
        }
        return true;
      }
    }
  ) as T;

  return urlQuery;
}
