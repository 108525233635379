import { FieldItem } from '../types';

export const initialFields: FieldItem[] = [
  // {
  //   type: 'name',
  //   text: 'Name',
  //   description: '',
  //   value: '',
  //   predefine: true,
  //   category: 'Personal Info'
  // },
  // {
  //   type: 'address',
  //   text: 'Address',
  //   description: '',
  //   value: '',
  //   predefine: true,
  //   category: 'Personal Info'
  // },
  // {
  //   type: 'number',
  //   text: 'Phone',
  //   description: '',
  //   value: '',
  //   predefine: true,
  //   category: 'Personal Info'
  // },
  // {
  //   type: 'number',
  //   text: 'Fax',
  //   description: '',
  //   value: '',
  //   predefine: true,
  //   category: 'Personal Info'
  // },
  // {
  //   type: 'text',
  //   text: 'Email',
  //   description: '',
  //   value: '',
  //   predefine: true,
  //   category: 'Personal Info'
  // },
  // {
  //   type: 'date',
  //   text: 'Date of Birth',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Personal Info'
  // },
  // {
  //   type: 'number',
  //   text: 'SIN Number',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Personal Info'
  // },
  // {
  //   type: 'number',
  //   text: "Driver's License Number",
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Personal Info'
  // },
  // {
  //   type: 'text',
  //   text: 'Occupation',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Personal Info'
  // },
  // {
  //   type: 'name',
  //   text: 'Other Occupant - Name',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Other Occupants'
  // },
  // {
  //   type: 'text',
  //   text: 'Other Occupant - Relationship',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Other Occupants'
  // },
  // {
  //   type: 'number',
  //   text: 'Other Occupant - Age',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Other Occupants'
  // },
  // {
  //   type: 'boolean',
  //   text: 'Pets',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Possessions'
  // },
  // {
  //   type: 'text',
  //   text: 'Pet Description',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Possessions'
  // },
  // {
  //   type: 'text',
  //   text: 'Automobiles - Make',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Possessions'
  // },
  // {
  //   type: 'text',
  //   text: 'Automobiles - Model',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Possessions'
  // },
  // {
  //   type: 'text',
  //   text: 'Automobiles - Year',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Possessions'
  // },
  // {
  //   type: 'text',
  //   text: 'Automobiles - License Plate',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Possessions'
  // },
  // {
  //   type: 'text',
  //   text: 'Reason for Relocation',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Residence'
  // },
  // {
  //   type: 'address',
  //   text: 'Last Residence - Address',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Residence'
  // },
  // {
  //   type: 'date',
  //   text: 'Last Residance - Start Date',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Residence'
  // },
  // {
  //   type: 'date',
  //   text: 'Last Residance - End Date',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Residence'
  // },
  // {
  //   type: 'name',
  //   text: 'Last Residance - Landlord',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Residence'
  // },
  // {
  //   type: 'number',
  //   text: 'Last Residance - Landlord Phone',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Residence'
  // },
  // {
  //   type: 'text',
  //   text: 'Present Employment - Employer',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Employment'
  // },
  // {
  //   type: 'address',
  //   text: 'Present Employment - Business Address',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Employment'
  // },
  // {
  //   type: 'number',
  //   text: 'Present Employment - Business Phone',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Employment'
  // },
  // {
  //   type: 'text',
  //   text: 'Present Employment - Position',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Employment'
  // },
  // {
  //   type: 'text',
  //   text: 'Present Employment - Length of Employment',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Employment'
  // },
  // {
  //   type: 'name',
  //   text: 'Present Employment - Supervisor Name',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Employment'
  // },
  // {
  //   type: 'text',
  //   text: 'Present Employment - Salary Range',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Employment'
  // },
  // {
  //   type: 'text',
  //   text: 'Bank Name',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Banking'
  // },
  // {
  //   type: 'text',
  //   text: 'Bank Branch',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Banking'
  // },
  // {
  //   type: 'address',
  //   text: 'Bank Address',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Banking'
  // },
  // {
  //   type: 'text',
  //   text: 'Chequeing Account #',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Banking'
  // },
  // {
  //   type: 'text',
  //   text: 'Savings Account #',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'Banking'
  // },
  // {
  //   type: 'text',
  //   text: 'Financial Obligation',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Banking'
  // },
  // {
  //   type: 'number',
  //   text: 'Financial Obligation - Amount',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Banking'
  // },
  // {
  //   type: 'name',
  //   text: 'Personal Reference - Name',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'References'
  // },
  // {
  //   type: 'address',
  //   text: 'Personal Reference - Address',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'References'
  // },
  // {
  //   type: 'number',
  //   text: 'Personal Reference - Phone',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'References'
  // },
  // {
  //   type: 'text',
  //   text: 'Personal Reference - Length of Acquaintance',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'References'
  // },
  // {
  //   type: 'text',
  //   text: 'Personal Reference - Occupation',
  //   description: '',
  //   owner: 1,
  //   value: '',
  //   predefine: true,
  //   category: 'References'
  // },
  // {
  //   type: 'date',
  //   text: 'Contract Date',
  //   description: '',
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'time',
  //   text: 'Contract Time',
  //   description: '',
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'date',
  //   text: 'View Date',
  //   description: '',
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'time',
  //   text: 'View Time',
  //   description: '',
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'text',
  //   text: 'Realtor ID',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   category: 'Personal Info'
  // },
  // {
  //   type: 'text',
  //   text: 'Website',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   category: 'Personal Info'
  // },
  // {
  //   type: 'name',
  //   text: 'Brokerage Name',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   category: 'Brokerage Info'
  // },
  // {
  //   type: 'address',
  //   text: 'Brokerage Address',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   category: 'Brokerage Info'
  // },
  // {
  //   type: 'number',
  //   text: 'Brokerage Phone',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   category: 'Brokerage Info'
  // },
  // {
  //   type: 'number',
  //   text: 'Brokerage Fax',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   category: 'Brokerage Info'
  // },
  // {
  //   type: 'date',
  //   text: 'Expiry Date',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'time',
  //   text: 'Expiry Time',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'text',
  //   text: 'Property Use',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'text',
  //   text: 'Geographic Location',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'number',
  //   text: 'Comission (%)',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'number',
  //   text: 'Comission Payable In (Days)',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'text',
  //   text: 'Schedule',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'text',
  //   text: 'Lease Term',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'date',
  //   text: 'Lease Term Start',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'text',
  //   text: 'Lease Payable Day',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'text',
  //   text: 'Deposit Payed Against (First Month)',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'text',
  //   text: 'Deposit Payed Against (Last Month)',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'text',
  //   text: 'Premises Use',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'text',
  //   text: 'Additional Terms',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'date',
  //   text: 'Offer Revocable Date',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'time',
  //   text: 'Offer Revocable Time',
  //   description: '',
  //   owner: 0,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Contract Details'
  // },
  // {
  //   type: 'name',
  //   text: 'Landlord Name',
  //   description: '',
  //   owner: -1,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Client Info'
  // },
  // {
  //   type: 'address',
  //   text: 'Landlord Address',
  //   description: '',
  //   owner: -1,
  //   value: '',
  //   predefine: true,
  //   unique: true,
  //   category: 'Client Info'
  // },
  // {
  //   type: 'number',
  //   text: 'Fax (Delivery to Landlord)',
  //   description: '',
  //   owner: -1,
  //   value: '',
  //   predefine: true,
  //   category: 'Client Info'
  // },
  // {
  //   type: 'text',
  //   text: 'Email (Delivery to Landlord)',
  //   description: '',
  //   owner: -1,
  //   value: '',
  //   predefine: true,
  //   category: 'Client Info'
  // }
];
