import { Redirect } from 'react-router-dom';

import { useAuth } from 'hooks';
import { ROOT_ROUTES } from 'routers/Routes';
import { REALTOR_NESTED_ROUTES } from 'routers/RealtorNestedRoutes';
import { ADMIN_NESTED_ROUTES } from 'routers/AdminNestedRoutes';
import { CLIENT_NESTED_ROUTES } from 'routers/ClientNestedRoutes';

export default function Profile(): JSX.Element {
  const { currentUser, logout } = useAuth();

  function getRedirectUrl() {
    if (currentUser?.isAdmin) {
      return `${ROOT_ROUTES.admin}${ADMIN_NESTED_ROUTES.profile}`;
    }

    if (currentUser?.isRealtor) {
      return `${ROOT_ROUTES.realtor}${REALTOR_NESTED_ROUTES.profile}`;
    }

    if (currentUser?.isClient) {
      return `${ROOT_ROUTES.client}${CLIENT_NESTED_ROUTES.profile}`;
    }

    return '';
  }

  if (currentUser && !currentUser.isClient && !currentUser.isRealtor) {
    logout();
  }

  return <Redirect to={getRedirectUrl()} />;
}
