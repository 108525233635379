import { limit, orderBy, query, startAfter, where } from 'services/firebase';
import {
  actionsCollection,
  applicantsCollection,
  clientTransactionsCollection,
  transactionFormsCollection,
  draftFormsCollection,
  fieldsCollection,
  formsCollection,
  notificationsCollection,
  openTransactionsCollection,
  systemFormsCollection,
  templatesCollection,
  transactionsCollection,
  clauseCollection,
  newTransactionsCollection,
  eSignsCollection
} from 'services/firestore';
import { NOTIFICATIONS_LOAD_LIMIT } from 'config/constants';

import type {
  Applicant,
  ClientTransaction,
  FieldItem,
  Form,
  HomelloNotification,
  OpenTransaction,
  Query,
  Template,
  Transaction,
  TransactionForm
} from 'types';

import { ESign, Transaction as NewTransaction } from 'types/newTransaction';

import { QueryDocumentSnapshot, or } from 'firebase/firestore';
import { Clause } from 'types/clause';

export const templatesQuery = function (uid: string): Query<Template> {
  return query(templatesCollection, where('ownerId', '==', uid));
};

export const fieldsQuery = function (): Query<FieldItem> {
  return query(fieldsCollection);
};

export const formsQuery = function (uid: string): Query<Form> {
  return query(formsCollection, where('ownerId', '==', uid));
};

export const transactionFormsQuery = function (
  uid: string,
  transactionId: string
): Query<TransactionForm> {
  return query(
    transactionFormsCollection,
    where('ownerId', '==', uid),
    where('transactionId', '==', transactionId || '')
  );
};

export const draftFormsQuery = function (uid: string): Query<Form> {
  return query(draftFormsCollection, where('ownerId', '==', uid));
};

export const allSystemFormsQuery = function (): Query<Form> {
  return query(systemFormsCollection);
};

export const applicantsQuery = function (uid: string): Query<Applicant> {
  return query(applicantsCollection, where('ownerId', '==', uid));
};

export const transactionQuery = function (uid: string, transactionId: string): Query<Transaction> {
  return query(
    transactionsCollection,
    where('ownerId', '==', uid),
    where('id', '==', transactionId)
  );
};

export const clausesQuery = function (
  uid: string,
  sort: string,
  ascending: boolean
): Query<Clause> {
  return query(
    clauseCollection,
    or(where('ownerId', '==', uid), where('isSystemClause', '==', true)),
    orderBy(sort, ascending ? 'asc' : 'desc')
  );
};

export const transactionsNewQuery = function (
  uid: string,
  sort: string,
  ascending: boolean,
  count: number
): Query<NewTransaction> {
  return query(
    newTransactionsCollection,
    where('createdBy', '==', uid),
    orderBy(sort, ascending ? 'asc' : 'desc'),
    limit(count)
  );
};

export const transactionsQuery = function (
  uid: string,
  sort: string,
  ascending: boolean,
  count: number
): Query<Transaction> {
  return query(
    transactionsCollection,
    where('ownerId', '==', uid),
    orderBy(sort, ascending ? 'asc' : 'desc'),
    limit(count)
  );
};

export const transactionsByClientQuery = function (email: string): Query<Transaction> {
  return query(transactionsCollection, where('applicantEmails', 'array-contains', email));
};

export const clientTransactionsByRealtorQuery = function (uid: string): Query<ClientTransaction> {
  return query(clientTransactionsCollection, where('realtorId', '==', uid));
};

export const clientTransactionsQuery = function (email: string): Query<ClientTransaction> {
  return query(clientTransactionsCollection, where('ownerEmail', '==', email));
};

export const realtorClientTransactionsQuery = function (
  uid: string,
  openTransactionId: string
): Query<ClientTransaction> {
  return query(
    clientTransactionsCollection,
    where('openTransactionId', '==', openTransactionId),
    where('realtorId', '==', uid)
  );
};

export const openTransactionsQuery = function (
  uid: string,
  transactionId: string
): Query<OpenTransaction> {
  return query(
    openTransactionsCollection,
    where('transactionId', '==', transactionId),
    where('realtorId', '==', uid)
  );
};

export const openTransactionQuery = function (
  uid: string,
  openTransactionId: string
): Query<OpenTransaction> {
  return query(
    openTransactionsCollection,
    where('id', '==', openTransactionId),
    where('realtorId', '==', uid)
  );
};

export const clientTransactionQuery = function (
  id: string,
  email: string
): Query<ClientTransaction> {
  return query(
    clientTransactionsCollection,
    where('ownerEmail', '==', email),
    where('id', '==', id)
  );
};

export const clientTransactionsByDateEditedQuery = function (
  uid: string,
  afterDate: number
): Query<ClientTransaction> {
  return query(
    clientTransactionsCollection,
    where('realtorId', '==', uid),
    where('dateEdited', '>', afterDate)
  );
};

export const notificationsByOpenTransactionIdQuery = function (
  uid: string,
  openTransactionId: string
): Query<HomelloNotification> {
  return query(
    actionsCollection,
    where('destination', '==', uid),
    where('event', 'not-in', ['formCommitted', 'allApplicantsSigned']),
    where('metaData.openTransactionId', '==', openTransactionId)
  );
};

export const getEsignOfTransaction = function (transactionId: string): Query<ESign> {
  return query(
    eSignsCollection,
    where('transactionId', '==', transactionId),
    orderBy('modifyTime', 'asc')
  );
};

export const notificationsByTransactionIdQuery = function (
  uid: string,
  transactionId: string,
  type: 'transaction' | 'eSign'
): Query<HomelloNotification> {
  return query(
    notificationsCollection,
    where('destination', '==', uid),
    where('seen', '==', false),
    type === 'eSign'
      ? where('metaData.eSignId', '==', transactionId)
      : where('metaData.transactionId', '==', transactionId)
  );
};

export const newNotificationsQuery = function (
  uid: string,
  lastActivity: number
): Query<HomelloNotification> {
  return query(
    notificationsCollection,
    where('destination', '==', uid),
    where('seen', '==', false),
    where('dateCreated', '>=', lastActivity)
  );
};

export const notificationsQuery = function (
  uid: string,
  startAfterCursor: QueryDocumentSnapshot<HomelloNotification> | null
): Query<HomelloNotification> {
  if (!startAfterCursor) {
    return query(
      notificationsCollection,
      where('destination', '==', uid),
      orderBy('dateCreated', 'desc'),
      limit(NOTIFICATIONS_LOAD_LIMIT)
    );
  }
  return query(
    notificationsCollection,
    where('destination', '==', uid),
    orderBy('dateCreated', 'desc'),
    startAfter(startAfterCursor),
    limit(NOTIFICATIONS_LOAD_LIMIT)
  );
};
