import { createContext, createRef, useState, forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

import { SNACK_BAR_AUTO_HIDE_DURATION } from 'config/constants';

import type { AlertProps, AlertColor } from '@mui/material/Alert';
import type { SnackbarCloseReason, SnackbarOrigin } from '@mui/material/Snackbar';
import type { SlideProps } from '@mui/material/Slide';
import type { TransitionProps } from '@mui/material/transitions';
import type { Dispatch, SetStateAction, ReactNode } from 'react';

export interface ContextProps {
  setSnack: Dispatch<SetStateAction<SnackState>>;
}

interface Props {
  children: ReactNode;
}

interface SnackState {
  message: string;
  severity: AlertColor;
  open: boolean;
}

export const SnackbarContext = createContext<ContextProps>({} as ContextProps);

const snackbarOptions = {
  autoHideDuration: SNACK_BAR_AUTO_HIDE_DURATION,
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' } as SnackbarOrigin
};

export function SnackbarProvider({ children }: Props): JSX.Element {
  const snackbarRef = createRef();
  //severity options: error,warning,info,success
  const [snack, setSnack] = useState<SnackState>({
    message: 'Success!',
    severity: 'success',
    open: false
  });

  const SlideTransition = forwardRef<unknown, TransitionProps>((props, ref) => {
    return <Slide ref={ref} direction="up" {...(props as SlideProps)} />;
  });

  const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnack((prev) => {
      const newState: typeof prev = { ...prev, open: false };
      return newState;
    });
  };

  const value: ContextProps = {
    setSnack
  };

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      {snack.open && (
        <Snackbar
          ref={snackbarRef}
          open={snack.open}
          TransitionComponent={SlideTransition}
          onClose={handleClose}
          {...snackbarOptions}
        >
          <Alert onClose={handleClose} severity={snack.severity}>
            {typeof snack.message === 'string' ? snack.message : JSON.stringify(snack.message)}
          </Alert>
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
}
