import type { ComponentPropsWithoutRef, LegacyRef } from 'react';
import { Children, forwardRef, Fragment, ReactElement } from 'react';

export const PopUpMenu = forwardRef(
  ({ children, className }: ComponentPropsWithoutRef<'div'>, ref: LegacyRef<HTMLDivElement>) => (
    <div
      ref={ref}
      style={{
        boxShadow: '0 2px 20px 2px rgba(0,0,0,0.08)'
      }}
      className={`absolute w-[200px] z-20 rounded-xl bg-white overflow-hidden ${className}`}
    >
      {Children.map(children, (child, index) => (
        <Fragment key={index}>
          {index !== 0 && <div className="w-full h-px bg-gray-2"></div>}
          {child}
        </Fragment>
      ))}
    </div>
  )
);

interface PopUpMenuItemProps extends ComponentPropsWithoutRef<'div'> {
  children: string;
  icon: string;
  labelClassName?: string;
  opacity?: number;
}

export function PopUpMenuItem({
  icon,
  opacity = 0.6,
  children,
  labelClassName,
  className,
  ...props
}: PopUpMenuItemProps): JSX.Element {
  return (
    <div
      className={`flex flex-row items-center justify-between p-4 cursor-pointer hover:bg-gray-2 ${className}`}
      {...props}
    >
      <div className={`flex-1 truncate text-input-label text-gray-6 ${labelClassName || ''}`}>
        {children}
      </div>
      <div className="w-4 h-4">
        <img src={icon} style={{ opacity }} className="w-full h-full opacity-70" alt={children} />
      </div>
    </div>
  );
}
