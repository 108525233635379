import { useEffect } from 'react';

import type { RefObject } from 'react';

export function closeAllPopups(): void {
  const evt = new Event('click', { bubbles: true, cancelable: false });
  window.dispatchEvent(evt);
}

export function useToggle<T extends RefObject<HTMLElement>, P extends RefObject<HTMLElement>>(
  toggleHandleRef: T,
  elementRef: P
): void {
  useEffect(() => {
    const toggleHandle = toggleHandleRef.current;
    const element = elementRef.current;

    const windowClicked = () => {
      if (element) {
        element.classList.add('hidden');
      }
    };

    const toggleVisible = (e: MouseEvent) => {
      closeAllPopups();
      e.stopPropagation();

      if (element) {
        element.classList.remove('hidden');
      }
    };

    window.addEventListener('click', windowClicked, false);
    // element.addEventListener("click", elementClicked, false);
    if (toggleHandle) {
      toggleHandle.addEventListener('click', toggleVisible, false);
    }

    return () => {
      window.removeEventListener('click', windowClicked, false);
      // element.removeEventListener("click", elementClicked, false);
      if (toggleHandle) {
        toggleHandle.removeEventListener('click', toggleVisible, false);
      }
    };
  }, [elementRef, toggleHandleRef]);
}
