import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import { queryListings } from 'services/queryListings';
import { LISTINGS_SEARCH_DEBOUNCE, LISTINGS_SEARCH_MAX_SUGGESTS } from 'config/constants';

import type { Listing, ListingsSearchParams } from 'types';

interface UseListings {
  listings: Listing[];
  loading: boolean;
}

export function useListings(query: string): UseListings {
  const [listings, setListings] = useState<Listing[]>([]);
  const [loading, setLoading] = useState(true);
  const searchParams: ListingsSearchParams = {
    query: '',
    fields: ['Street Address', 'id'],
    size: LISTINGS_SEARCH_MAX_SUGGESTS,
    from: 0,
    sort: ['date']
  };

  const listingsQuery = useQuery(
    ['listings', query],
    () => queryListings({ ...searchParams, query }),
    { enabled: false }
  );

  useEffect(() => {
    if (listingsQuery.isSuccess) {
      const result = listingsQuery.data;
      setListings(result.data);
      setLoading(false);
    }
  }, [listingsQuery]);

  useEffect(() => {
    setLoading(true);
    setListings([]);
    let fetchHandle: NodeJS.Timeout;
    if (query) {
      fetchHandle = setTimeout(() => {
        listingsQuery.refetch();
      }, LISTINGS_SEARCH_DEBOUNCE);
    }
    return () => {
      clearTimeout(fetchHandle);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return {
    listings,
    loading
  };
}
