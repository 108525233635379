import {
  settingsCollection,
  profileCollection,
  templatesCollection,
  formsCollection,
  applicantsCollection,
  systemFormsCollection,
  transactionFormsCollection,
  draftFormsCollection,
  transactionsCollection,
  clientTransactionsParentCollection,
  openTransactionsParentCollection,
  markDownsCollection,
  lastActivitiesCollection,
  notificationsCollection,
  fieldsCollection,
  actionsCollection,
  clauseCollection
} from 'services/firestore';
import { doc } from 'services/firebase';

import type {
  Template,
  DocumentReference,
  RealtorSettings,
  ClientSettings,
  Profile,
  Form,
  Applicant,
  Transaction,
  ClientTransaction,
  OpenTransaction,
  MarkDowns,
  LastActivity,
  HomelloNotification,
  TransactionForm
} from 'types';
import { FieldItem } from 'types';
import { Clause } from 'types/clause';

export function profileDoc(path: string): DocumentReference<Profile> {
  return doc<Profile>(profileCollection, path);
}

export function clauseDoc(path: string): DocumentReference<Clause> {
  return doc<Clause>(clauseCollection, path);
}

export function settingsDoc(path: string): DocumentReference<RealtorSettings | ClientSettings> {
  return doc<RealtorSettings | ClientSettings>(settingsCollection, path);
}

export function fieldsDoc(path: string): DocumentReference<FieldItem> {
  return doc<FieldItem>(fieldsCollection, path);
}

export function templateDoc(path: string): DocumentReference<Template> {
  return doc<Template>(templatesCollection, path);
}

export function formDoc(path: string): DocumentReference<Form> {
  return doc<Form>(formsCollection, path);
}

export function transactionFormDoc(path: string): DocumentReference<TransactionForm> {
  return doc<TransactionForm>(transactionFormsCollection, path);
}

export function draftFormDoc(path: string): DocumentReference<Form> {
  return doc<Form>(draftFormsCollection, path);
}

export function systemFormDoc(path: string): DocumentReference<Form> {
  return doc<Form>(systemFormsCollection, path);
}

export function applicantDoc(path: string): DocumentReference<Applicant> {
  return doc<Applicant>(applicantsCollection, path);
}

export function transactionDoc(path: string): DocumentReference<Transaction> {
  return doc<Transaction>(transactionsCollection, path);
}

export function markDownsDoc(): DocumentReference<MarkDowns> {
  return doc<MarkDowns>(markDownsCollection, 'markDowns');
}

export function lastActivitiesDoc(path: string): DocumentReference<LastActivity> {
  return doc<LastActivity>(lastActivitiesCollection, path);
}

export function notificationsDoc(path: string): DocumentReference<HomelloNotification> {
  return doc<HomelloNotification>(notificationsCollection, path);
}

export function actionsDoc(path: string): DocumentReference<HomelloNotification> {
  return doc<HomelloNotification>(actionsCollection, path);
}

export function openTransactionDoc(
  transactionId: string,
  openTransactionId: string
): DocumentReference<OpenTransaction> {
  return doc<OpenTransaction>(
    openTransactionsParentCollection,
    transactionId,
    'openTransactions',
    openTransactionId
  );
}

export function clientTransactionDoc(
  transactionId: string,
  openTransactionId: string,
  applicantId: string
): DocumentReference<ClientTransaction> {
  return doc<ClientTransaction>(
    clientTransactionsParentCollection,
    transactionId,
    'openTransactions',
    openTransactionId,
    'clientTransactions',
    applicantId
  );
}
