import { functions, httpsCallable } from 'services/firebase';
import type { HttpsCallableResult, QueryListingsResponse, ListingsSearchParams } from 'types';

export function queryListings(
  searchParams: ListingsSearchParams
): Promise<HttpsCallableResult<QueryListingsResponse>> {
  const searchIndexes = httpsCallable<ListingsSearchParams, QueryListingsResponse>(
    functions,
    'searchListings'
  );
  return searchIndexes(searchParams);
}
