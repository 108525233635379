import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
  removeCommittedDocument,
  setClientTransaction as setter,
  setCommittedDocument,
  submitTransaction as transactionLocker
} from 'services/firestore';

import type { ClientTransaction, ClientTransactionSort, SetClientTransaction } from 'types';
import { getMyTransactions } from 'services/transaction';
import { ExtendedTransaction } from 'types/newTransaction';

interface UseClientTransactions {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  clientTransactions: ExtendedTransaction[];
  setClientTransaction: (
    transactionId: string,
    clientTransactionId: string,
    applicantId: string,
    item: Partial<SetClientTransaction>
  ) => Promise<void>;
  commitDocument: (
    transactionId: string,
    clientTransactionId: string,
    applicantId: string,
    documentId: string
  ) => Promise<void>;
  unCommitDocument: (
    transactionId: string,
    clientTransactionId: string,
    applicantId: string,
    documentId: string
  ) => Promise<void>;
  submitTransaction: (clientTransaction: ClientTransaction) => Promise<void>;
}

interface SetItemMutation {
  transactionId: string;
  openTransactionId: string;
  applicantId: string;
  item: Partial<SetClientTransaction>;
}

interface CommitDocumentMutation {
  transactionId: string;
  openTransactionId: string;
  applicantId: string;
  documentId: string;
}

export function useClientTransactions(
  sort: ClientTransactionSort = 'dateEdited',
  ascending = true
): UseClientTransactions {
  const queryClient = useQueryClient();
  const clientTransactionsQuery = useQuery('clientTransactions', () => {
    return getMyTransactions({ sort, ascending });
  });

  const setClientTransactionMutation = useMutation<void, unknown, SetItemMutation, unknown>(
    ({ transactionId, openTransactionId, applicantId, item }) =>
      setter(transactionId, openTransactionId, applicantId, item),
    {
      onSettled: () => {
        queryClient.invalidateQueries('clientTransactions');
      }
    }
  );

  const commitDocumentMutation = useMutation<void, unknown, CommitDocumentMutation, unknown>(
    ({ transactionId, openTransactionId, applicantId, documentId }) =>
      setCommittedDocument(transactionId, openTransactionId, applicantId, documentId),
    {
      onSettled: () => {
        queryClient.invalidateQueries('clientTransactions');
      }
    }
  );

  const unCommitDocumentMutation = useMutation<void, unknown, CommitDocumentMutation, unknown>(
    ({ transactionId, openTransactionId, applicantId, documentId }) =>
      removeCommittedDocument(transactionId, openTransactionId, applicantId, documentId),
    {
      onSettled: () => {
        queryClient.invalidateQueries('clientTransactions');
      }
    }
  );

  const submitTransactionMutation = useMutation<void, unknown, ClientTransaction, unknown>(
    (clientTransaction) => transactionLocker(clientTransaction),
    {
      onSettled: () => {
        queryClient.invalidateQueries('clientTransactions');
      }
    }
  );

  function commitDocument(
    transactionId: string,
    openTransactionId: string,
    applicantId: string,
    documentId: string
  ) {
    return commitDocumentMutation.mutateAsync({
      transactionId,
      openTransactionId,
      applicantId,
      documentId
    });
  }

  function unCommitDocument(
    transactionId: string,
    openTransactionId: string,
    applicantId: string,
    documentId: string
  ) {
    return unCommitDocumentMutation.mutateAsync({
      transactionId,
      openTransactionId,
      applicantId,
      documentId
    });
  }

  function setClientTransaction(
    transactionId: string,
    openTransactionId: string,
    applicantId: string,
    item: Partial<SetClientTransaction>
  ) {
    return setClientTransactionMutation.mutateAsync({
      transactionId,
      openTransactionId,
      applicantId,
      item
    });
  }

  function submitTransaction(clientTransaction: ClientTransaction) {
    return submitTransactionMutation.mutateAsync(clientTransaction);
  }

  // useEffect(() => {
  //   if (clientTransactionsQuery.isSuccess) {
  //     const result = clientTransactionsQuery.data;
  //     const queryResult: ClientTransaction[] = [];
  //     if (!result) {
  //       result.forEach((item) => {
  //         const itemData = item.data();

  //         if (!itemData.deleted) {
  //           queryResult.push(item.data());
  //         }
  //       });
  //     }
  //     const sortedResults = sortArrayByKey(queryResult, sort, ascending ? 'asc' : 'desc');
  //     setClientTransactions(sortedResults);
  //   }
  // }, [ascending, clientTransactionsQuery.data, clientTransactionsQuery.isSuccess, sort]);
  return {
    isLoading: clientTransactionsQuery.isLoading,
    isError: clientTransactionsQuery.isError,
    isSuccess: clientTransactionsQuery.isSuccess,
    clientTransactions: clientTransactionsQuery.data?.data.transactions || [],
    setClientTransaction,
    commitDocument,
    unCommitDocument,
    submitTransaction
  };
}
