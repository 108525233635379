import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import MuiToolTip from '@mui/material/Tooltip';

import type { TooltipProps } from '@mui/material/Tooltip';

export const ToolTip = styled(({ className, ...props }: TooltipProps) => (
  <MuiToolTip arrow placement="top" {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    fontSize: 16,
    width: 17,
    '&::before': {
      border: '1px solid #000',
      backgroundColor: '#fff',
      boxSizing: 'border-box'
    }
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: '#000000',
    border: '1px solid #000000'
  }
}));
