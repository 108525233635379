import { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { useAuth } from 'hooks';
import {
  getSettings,
  setRealtorSettings as firestoreSetSettings,
  setLatestDocuments as firestoreSetLatestDocuments,
  setCustomFields as firestoreSetCustomFields,
  setRealtorFields as firestoreSetRealtorFields,
  setPreferences as firestoreSetPreferences,
  setBuilderOnboarding as firestoreSetBuilderOnboarding
} from 'services/firestore';
import { getProfilePicture, setProfilePicture } from 'services/storage';
import { initialRealtorSettings } from 'config/initialRealtorSettings';

import type { RealtorSettings, UploadResult } from 'types';

interface UseSettings {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  settings: RealtorSettings | null;
  profilePicURL: string;
  setSettings: (settings: RealtorSettings) => Promise<void>;
  setLatestDocuments: (documents: RealtorSettings['latestDocuments']) => Promise<void>;
  setProfilePic: (profilePic: File) => Promise<UploadResult>;
  setPreferences: (preferences: Partial<RealtorSettings['preferences']>) => Promise<void>;
  setCustomFields: (customFields: Partial<RealtorSettings['customFields']>) => Promise<void>;
  setRealtorFields: (realtorFields: Partial<RealtorSettings['realtorFields']>) => Promise<void>;
  setBuilderOnboarding: (
    builderOnboarding: Partial<RealtorSettings['builderOnboarding']>
  ) => Promise<void>;
}

export function useRealtorSettings(): UseSettings {
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  const uid = currentUser!.uid;
  const [settings, setSettingsState] = useState<RealtorSettings | null>(null);
  const [profilePicURL, setProfilePicURL] = useState(currentUser?.photoURL ?? '');

  const setProfilePicMutation = useMutation<UploadResult, unknown, File, unknown>(
    (profilePic) => setProfilePicture(uid, profilePic),
    {
      onSettled: () => {
        queryClient.invalidateQueries('profilePic');
      }
    }
  );

  const settingsMutation = useMutation<void, unknown, RealtorSettings, unknown>(
    (settings) => firestoreSetSettings(uid, settings),
    {
      onSettled: () => {
        queryClient.invalidateQueries('settings');
      }
    }
  );

  const latestDocumentsMutation = useMutation<
    void,
    unknown,
    RealtorSettings['latestDocuments'],
    unknown
  >((documents) => firestoreSetLatestDocuments(uid, documents), {
    onSettled: () => {
      queryClient.invalidateQueries('settings');
    }
  });

  const preferencesMutation = useMutation<
    void,
    unknown,
    Partial<RealtorSettings['preferences']>,
    unknown
  >((preferences) => firestoreSetPreferences(uid, preferences), {
    onSettled: () => {
      queryClient.invalidateQueries('settings');
    }
  });

  const builderOnboardingMutation = useMutation<
    void,
    unknown,
    Partial<RealtorSettings['builderOnboarding']>,
    unknown
  >((builderOnboarding) => firestoreSetBuilderOnboarding(uid, builderOnboarding), {
    onSettled: () => {
      queryClient.invalidateQueries('settings');
    }
  });

  const customFieldsMutation = useMutation<
    void,
    unknown,
    Partial<RealtorSettings['customFields']>,
    unknown
  >((customFields) => firestoreSetCustomFields(uid, customFields), {
    onSettled: () => {
      queryClient.invalidateQueries('settings');
    }
  });

  const realtorFieldsMutation = useMutation<
    void,
    unknown,
    Partial<RealtorSettings['realtorFields']>,
    unknown
  >((realtorFields) => firestoreSetRealtorFields(uid, realtorFields), {
    onSettled: () => {
      queryClient.invalidateQueries('settings');
    }
  });

  function setSettings(settings: RealtorSettings) {
    return settingsMutation.mutateAsync(settings);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function initializeSettings() {
    return setSettings(initialRealtorSettings);
  }

  const settingsQuery = useQuery(['settings', uid], () => getSettings(uid), {
    onSuccess: (data) => {
      // Check if user Settings is empty so this is the first time user has logged in
      // If so, we should populate some initial data for the user
      const exists = data.exists();
      if (!exists) {
        console.log('initSettings1');
        initializeSettings();
      }
    },
    onError: () => {
      console.log('initSettings2');
      initializeSettings();
    }
  });

  useEffect(() => {
    if (settingsQuery.isFetched) {
      const exists = settingsQuery.data?.exists();
      if (!exists) {
        console.log('initSettings3');
        initializeSettings();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsQuery.isFetched]);

  const profilePicQuery = useQuery('profilePic', () => {
    return new Promise<string>((res) => {
      getProfilePicture(uid)
        .then((url) => {
          res(url);
        })
        .catch(() => {
          //If the profile picture is not uploaded, there is no need to reject the reqeust.
          //The catch only causes the error not to be thrown
          res('');
        });
    });
  });

  function setLatestDocuments(documents: RealtorSettings['latestDocuments']) {
    return latestDocumentsMutation.mutateAsync(documents);
  }

  function setPreferences(preferences: Partial<RealtorSettings['preferences']>) {
    return preferencesMutation.mutateAsync(preferences);
  }

  function setBuilderOnboarding(builderOnboarding: Partial<RealtorSettings['builderOnboarding']>) {
    return builderOnboardingMutation.mutateAsync(builderOnboarding);
  }

  function setCustomFields(customFields: Partial<RealtorSettings['customFields']>) {
    return customFieldsMutation.mutateAsync(customFields);
  }

  function setRealtorFields(realtorFields: Partial<RealtorSettings['realtorFields']>) {
    return realtorFieldsMutation.mutateAsync(realtorFields);
  }

  function setProfilePic(profilePic: File) {
    return setProfilePicMutation.mutateAsync(profilePic);
  }
  useEffect(() => {
    if (settingsQuery.isSuccess) {
      const result = settingsQuery.data;
      if (result.exists()) {
        const settings = result.data() as RealtorSettings;
        setSettingsState((prev) => ({ ...prev, ...settings }));
      }
    }
  }, [settingsQuery.data, settingsQuery.isSuccess]);

  useEffect(() => {
    if (profilePicQuery.isSuccess) {
      const result = profilePicQuery.data;

      if (result) {
        setProfilePicURL(result);
      }
    }
  }, [profilePicQuery.data, profilePicQuery.isSuccess]);

  return {
    isLoading: settingsQuery.isLoading,
    isSuccess: settingsQuery.isSuccess,
    isError: settingsQuery.isError,
    settings,
    setSettings,
    setLatestDocuments,
    profilePicURL,
    setProfilePic,
    setPreferences,
    setCustomFields,
    setRealtorFields,
    setBuilderOnboarding
  };
}
