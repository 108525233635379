/* eslint-disable prettier/prettier */
import { createContext, useState, useCallback } from 'react';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';

import { useConfirm } from 'hooks';

import closeIcon from 'assets/icons/close.svg';

import type { ReactNode } from 'react';
import clsx from 'clsx';

interface SetModalProps {
  content: JSX.Element;
  hideClose?: boolean;
  closable?: boolean;
  size?: ModalSize | number;
  fullHeight?: boolean;
  onClose?: () => void;
  confirmOnClose?: boolean;
  title?: string;
  subTitle?: string;
  headerButton?: ReactNode;
  className?: string;
}

type ModalSize = 'Small' | 'Medium' | 'Medium2' | 'Large';

interface ModalClose {
  fn: (() => void) | undefined;
}

interface ModalContent {
  component: ReactNode;
  open: boolean;
}

export interface ContextProps {
  openModal: ({ content, size, onClose, confirmOnClose }: SetModalProps) => void;
  closeModal: () => void;
}

interface Props {
  children: ReactNode;
}

export const ModalContext = createContext<ContextProps>({} as ContextProps);

export function ModalProvider({ children }: Props): JSX.Element {
  const [modalSize, setModalSize] = useState<ModalSize | number>('Medium');
  const [modalConfirmOnClose, setModalConfirmOnClose] = useState(false);
  const [modalClose, setModalClose] = useState<ModalClose>({ fn: undefined });
  const [hideClose, setHideClose] = useState<boolean>();
  const [closable, setClosable] = useState<boolean>(true);
  const [title, setTitle] = useState<string | undefined>();
  const [headerButton, setHeaderButton] = useState<ReactNode>();
  const [subTitle, setSubTitle] = useState<string | undefined>();
  const [fullHeight, setFullHeight] = useState<boolean>(false);
  const [className, setClassName] = useState<string>('');
  const [modalContent, setModalContent] = useState<ModalContent>({ component: null, open: false });
  const { getConfirmation } = useConfirm();

  const closeModal = () => setModalContent((modal) => ({ ...modal, open: false }));

  const openModal = useCallback(
    ({
      content,
      hideClose,
      closable,
      size,
      onClose,
      confirmOnClose,
      fullHeight,
      title,
      subTitle,
      headerButton,
      className
    }: SetModalProps) => {
      setModalContent({ component: content, open: true });

      if (size) {
        setModalSize(size);
      } else {
        setModalSize('Medium');
      }

      setTitle(title);
      setSubTitle(subTitle);

      if (fullHeight) {
        setFullHeight(fullHeight);
      } else {
        setFullHeight(false);
      }

      if (closable === false) {
        setClosable(false);
      } else {
        setClosable(true);
      }

      if (hideClose) {
        setHideClose(hideClose);
      } else {
        setHideClose(false);
      }

      if (typeof onClose === 'function') {
        setModalClose({ fn: onClose });
      } else {
        setModalClose({ fn: undefined });
      }

      if (confirmOnClose) {
        setModalConfirmOnClose(true);
      } else {
        setModalConfirmOnClose(false);
      }

      if (headerButton) {
        setHeaderButton(headerButton);
      } else {
        setHeaderButton(undefined);
      }

      if (className) {
        setClassName(className)
      } else {
        setClassName("")
      }
    },
    []
  );

  const handleModalClose = useCallback(async () => {
    if (modalConfirmOnClose) {
      const shouldClose = await getConfirmation({
        title: 'Close Modal',
        text: 'Are you sure you want to close this modal?',
        button: 'Yes',
        color: 'orange'
      });

      if (!shouldClose) return;
    }
    if (closable) {
      closeModal();
    }
    if (typeof modalClose.fn === 'function') {
      modalClose.fn();
    }
  }, [getConfirmation, modalClose, modalConfirmOnClose, closable]);

  const value: ContextProps = {
    openModal,
    closeModal
  };
  const Transition = modalSize === 'Large' ? Slide : Fade;

  return (
    <ModalContext.Provider value={value}>
      {children}
      <Modal
        className="flex items-center justify-center min-h-[300px]"
        open={modalContent.open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        container={() => document.getElementById('modal-container')}
        slotProps={{
          backdrop: {
            timeout: 200
          }
        }}
      >
        <Transition in={modalContent.open}>
          <div
            style={{
              boxShadow: '0 24px 48px 0 rgba(0,0,0,0.2)',
              maxWidth: typeof modalSize === 'number' ? modalSize : undefined
            }}
            className={clsx(
              'relative w-full max-h-screen mt-10 sm:mt-0 h-[calc(100vh-2.5rem)] sm:h-auto overflow-y-auto bg-white rounded-t-3xl sm:rounded-3xl px-[20px] sm:px-[40px] py-10 focus:outline-none',
              modalSize !== 'Large' && fullHeight && '!h-screen !max-h-screen !rounded-none',
              modalSize === 'Small' && 'max-w-[380px]',
              modalSize === 'Medium' && 'max-w-[640px]',
              modalSize === 'Medium2' && 'max-w-[1000px]',
              modalSize === 'Large' && 'max-w-[1024px]',
              modalSize === 'Medium2' &&
                !fullHeight &&
                '!max-h-[calc(100vh-50px)] h-[calc(100vh-2.5rem)]',
              modalSize === 'Large' &&
                fullHeight &&
              'md:!h-screen md:!max-h-screen !h-full !max-h-full !rounded-none',
              className
            )}
          >
            {!hideClose && (
              <div
                className="absolute right-5 top-10 md:top-5 cursor-pointer hover:opacity-70"
                onClick={handleModalClose}
              >
                <img src={closeIcon} className="w-6 h-6" alt="Close" />
              </div>
            )}
            <div className="flex flex-row justify-between">
              <div>
                {title && <h2 className="font-bold !font-poppins">{title}</h2>}
                {subTitle && <h4 className="!font-poppins text-p3">{subTitle}</h4>}
              </div>
              {headerButton}
            </div>
            {modalContent.component}
          </div>
        </Transition>
      </Modal>
    </ModalContext.Provider>
  );
}
