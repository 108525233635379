import { useQuery } from 'react-query';
import { getActionCodeResponse } from 'services/auth';

interface UsePasswordReset {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

export function useVerifyEmail(disable?: boolean): UsePasswordReset {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params: { oobCode?: string } = Object.fromEntries(urlSearchParams.entries());
  const actionCode = params.oobCode;

  const actionCodeResponseQuery = useQuery(
    'actionCodeResponse',
    () => getActionCodeResponse(actionCode),
    {
      enabled: !disable
    }
  );

  return {
    isLoading: actionCodeResponseQuery.isLoading,
    isError: actionCodeResponseQuery.isError,
    isSuccess: actionCodeResponseQuery.isSuccess
  };
}
