import { useEffect } from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';

import { useSnackbar, useFullPageLoader, useMarkDowns } from 'hooks';

import type { MarkDowns } from 'types';

interface Props {
  selectedPage: keyof MarkDowns;
}

export function MarkDown({ selectedPage }: Props): JSX.Element {
  const { setLoader } = useFullPageLoader();
  const { setSnack } = useSnackbar();
  const { isLoading, isSuccess, isError, markDowns } = useMarkDowns();

  useEffect(() => {
    if (isLoading) {
      setLoader({
        color: 'blue',
        key: 'markDownsLoading'
      });
    }
    if (isError) {
      setSnack({
        message: 'Error Loading Data!',
        severity: 'error',
        open: true
      });
      setLoader({
        color: false,
        key: 'markDownsLoading'
      });
    }
    if (isSuccess) {
      setLoader({
        color: false,
        key: 'markDownsLoading'
      });
    }
  }, [isError, isLoading, isSuccess, setLoader, setSnack]);

  return <>{markDowns?.[selectedPage] && <MarkdownPreview source={markDowns?.[selectedPage]} />}</>;
}
