import type { ProfileToShare } from 'types';

type AlertTypes = 'MFA' | 'VerifyEmail' | 'Reco' | 'VerifyId' | 'FillTransaction';

interface GeneralAlert {
  type: AlertTypes;
  id: string;
}

interface MfaAlert extends GeneralAlert {
  type: 'MFA';
}

interface VerifyEmailAlert extends GeneralAlert {
  type: 'VerifyEmail';
}

interface RecoAlert extends GeneralAlert {
  type: 'Reco';
}

interface VerifyIdAlert extends GeneralAlert {
  type: 'VerifyId';
  data: {
    name: string;
  };
}

interface FillTransactionAlert extends GeneralAlert {
  type: 'FillTransaction';
  data: {
    realtorProfile: ProfileToShare;
  };
}

export type Alert = MfaAlert | VerifyEmailAlert | RecoAlert | VerifyIdAlert | FillTransactionAlert;

export const NOTIFICATION_EVENTS = {
  allApplicantsSigned: 'allApplicantsSigned',
  allTransactionSubmitted: 'allTransactionSubmitted',
  applicantIdUploaded: 'applicantIdUploaded',
  applicantSigned: 'applicantSigned',
  documentCommitted: 'documentCommitted',
  documentUncommitted: 'documentUncommitted',
  formCommitted: 'formCommitted',
  formSeen: 'formSeen',
  incorrectEmail: 'incorrectEmail',
  pdfFormCreated: 'pdfFormCreated',
  transactionComplete: 'transactionComplete',
  transactionRetractedSignrequest: 'transactionRetractedSignrequest',
  transactionSentSignrequest: 'transactionSentSignrequest',
  transactionSigned: 'transactionSigned',
  transactionSubmitted: 'transactionSubmitted',
  zipCompleted: 'zipCompleted'
} as const;
type NotificationEvents = (typeof NOTIFICATION_EVENTS)[keyof typeof NOTIFICATION_EVENTS];

type ActionEvents =
  | NotificationEvents
  | 'createTransaction'
  | 'createNewESign'
  | 'draftTransaction'
  | 'eSignEmailViewed';

export interface HomelloNotification {
  id: string;
  stacked?: boolean;
  dateCreated: number;
  destinations: string;
  event: ActionEvents;
  seen: boolean;
  source: string;
  sourceDetail?: string;
  updateCount?: number;
  metaData?: {
    formId?: string;
    formName?: string;
    applicantId?: string;
    transactionId?: string;
    notificationId?: string;
    transactionName?: string;
    openTransactionId?: string;
    openTransactionName?: string;
    clientTransactionId?: string;
    eSignId?: string;
    eSignName?: string;
    clauseId?: string;
    clientClauseId?: string;
    value?: string;
  };
}
