import clsx from 'clsx';
import deepEqual from 'deep-equal';
import { forwardRef, memo } from 'react';
import type { ComponentPropsWithoutRef, LegacyRef } from 'react';

export const ButtonL = forwardRef(
  (
    { children, className = '', disabled, ...props }: ComponentPropsWithoutRef<'button'>,
    ref: LegacyRef<HTMLButtonElement>
  ) => (
    <button
      className={`h-[60px] min-w-[8rem] rounded-md text-btn1 font-bold px-10 focus:outline-none hover:opacity-70 ${
        disabled ? 'cursor-not-allowed opacity-30 hover:!opacity-30' : ''
      } ${className}`}
      ref={ref}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
);

export const ButtonM = forwardRef(
  (
    { children, className = '', disabled, ...props }: ComponentPropsWithoutRef<'button'>,
    ref: LegacyRef<HTMLButtonElement>
  ) => (
    <button
      className={`h-10 rounded-md text-btn2 font-semibold px-5 focus:outline-none hover:opacity-70 ${
        disabled ? 'cursor-not-allowed opacity-30 hover:!opacity-30' : ''
      } ${className}`}
      ref={ref}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
);

export const ButtonS = memo(
  forwardRef(
    (
      { children, className = '', disabled, ...props }: ComponentPropsWithoutRef<'button'>,
      ref: LegacyRef<HTMLButtonElement>
    ) => (
      <button
        className={clsx(
          'h-9 min-w-[80px] rounded-md text-btn4 px-5 focus:outline-none hover:opacity-70',
          disabled ? 'bg-gray-2 text-gray-6' : '',
          className
        )}
        ref={ref}
        disabled={disabled}
        {...props}
      >
        {children}
      </button>
    )
  ),
  deepEqual
);
