import { createContext, useState, useCallback } from 'react';

import FullPageLoader from 'containers/FullPageLoader';

import type { FullPageLoaderColor } from 'types';
import type { ReactNode } from 'react';

interface SetLoader {
  color: false | FullPageLoaderColor;
  key: string;
}
export interface ContextProps {
  setLoader: ({ color, key }: SetLoader) => void;
}

interface Props {
  children: ReactNode;
}

export const FullPageLoaderContex = createContext<ContextProps>({} as ContextProps);

export function FullPageLoaderProvider({ children }: Props): JSX.Element {
  const [color, setColor] = useState<FullPageLoaderColor>('white');
  const [queue, setQueue] = useState<string[]>([]);
  const [keyName, setKeyName] = useState<string>();

  const setLoader = useCallback(({ color, key }: SetLoader) => {
    setKeyName(key);
    console.log({ key, queue });
    if (color === false) {
      setQueue((prev) => prev.filter((item) => item !== key));
      return;
    }

    if (color) {
      setColor(color);
    }

    setQueue((prev) => {
      if (!prev.find((item) => item === key)) {
        return [...prev, key];
      }
      return prev;
    });
  }, []);

  const value: ContextProps = {
    setLoader
  };
  return (
    <FullPageLoaderContex.Provider value={value}>
      {children}
      <FullPageLoader keyName={keyName} visible={!!queue.length} color={color} />
    </FullPageLoaderContex.Provider>
  );
}
