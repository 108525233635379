import { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { useAuth } from 'hooks';
import { getProfile } from 'services/firestore';
import { updateProfile } from 'services/updateProfile';

import type { Profile, UpdateProfileResponse, HttpsCallableResult } from 'types';
import { FormFields } from 'types/newTransaction';

interface UseProfile {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  profile: Profile | null;
  setProfile: (profile: Profile) => Promise<HttpsCallableResult<UpdateProfileResponse>>;
  profileFormData: FormFields;
}

export function useProfile(): UseProfile {
  const queryClient = useQueryClient();
  const { currentUser } = useAuth();
  const uid = currentUser!.uid;
  const [profile, setProfileState] = useState<Profile | null>(null);

  const profileMutation = useMutation<
    HttpsCallableResult<UpdateProfileResponse>,
    unknown,
    Profile,
    unknown
  >((profile) => updateProfile(profile), {
    onSettled: () => {
      queryClient.invalidateQueries('profile');
    }
  });

  const profileQuery = useQuery('profile', () => getProfile(uid));

  function setProfile(profile: Profile) {
    return profileMutation.mutateAsync(profile);
  }

  useEffect(() => {
    if (profileQuery.isSuccess) {
      const result = profileQuery.data;
      if (result.exists()) {
        setProfileState(result.data());
      }
    }
  }, [profileQuery.data, profileQuery.isSuccess]);

  function getFormData(profile: Profile) {
    if (profile) {
      const profileEntries = Object.entries(profile);
      if (profileEntries.length) {
        const formData: FormFields = {};
        for (let index = 0; index < profileEntries.length; index++) {
          const [key, { value }] = profileEntries[index];
          formData[key] = value;
        }
        return formData;
      }
    }
  }

  return {
    isLoading: profileQuery.isLoading,
    isError: profileQuery.isError,
    isSuccess: profileQuery.isSuccess,
    profile: profile,
    setProfile,
    profileFormData: getFormData(profile)
  };
}
