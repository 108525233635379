import { useEffect } from 'react';

import { useFullPageLoader } from 'hooks';

export default function Loading(): JSX.Element {
  const { setLoader } = useFullPageLoader();

  useEffect(() => {
    setLoader({
      color: 'white',
      key: 'MasterLoading'
    });
    return () => {
      setLoader({
        color: false,
        key: 'MasterLoading'
      });
    };
  }, [setLoader]);
  return <div className="w-screen h-screen" style={{ backgroundColor: '#0a00ea' }}></div>;
}
