import { updateProfile } from 'firebase/auth';
import {
  auth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode,
  multiFactor,
  deleteUser,
  updatePassword
} from 'services/firebase';
import { functions, httpsCallable } from 'services/firebase';
import type {
  UserCredential,
  SendPasswordResetLinkData,
  SendPasswordResetLinkResponse,
  HttpsCallableResult,
  SendVerifyEmailLinkResponse,
  SetIsRealtorResponse,
  SetIsClientResponse
} from 'types';
import { SetIsClientParams } from 'types';

export function reloadToken(): Promise<void> {
  if (auth.currentUser) {
    return auth.currentUser.reload();
  }

  return Promise.resolve();
}

export function reloadIdToken(): Promise<string> {
  if (auth.currentUser) {
    return auth.currentUser.getIdToken(true);
  }

  return Promise.resolve('');
}

export function unenrollMobileMFA(): Promise<void> {
  if (!auth.currentUser) return Promise.reject();

  const multiFactorUser = multiFactor(auth.currentUser);
  const options = multiFactorUser.enrolledFactors;

  if (!options.length) return Promise.reject();

  return multiFactorUser.unenroll(options[0]);
}

export function deleteAccount(): Promise<void> {
  if (!auth.currentUser) return Promise.reject();

  return deleteUser(auth.currentUser);
}

export function changePassword(newPassword: string): Promise<void> {
  if (!auth.currentUser) return Promise.reject();

  return updatePassword(auth.currentUser, newPassword);
}

export function login(email: string, password: string): Promise<UserCredential> {
  return signInWithEmailAndPassword(auth, email, password);
}

export function loginByToken(token: string): Promise<UserCredential> {
  return signInWithCustomToken(auth, token);
}

export function loginWithGoogle(): Promise<UserCredential> {
  const provider = new GoogleAuthProvider();
  return signInWithPopup(auth, provider);
}

export function loginWithFacebook(): Promise<UserCredential> {
  const provider = new FacebookAuthProvider();
  return signInWithPopup(auth, provider);
}

export function logout(): Promise<void> {
  return auth.signOut();
}

export async function register(
  email: string,
  password: string,
  displayName?: string
): Promise<UserCredential> {
  const res = await createUserWithEmailAndPassword(auth, email, password);
  if (displayName) {
    await updateProfile(res.user, { displayName });
  }
  return res;
}

export function resetPassword(
  email: SendPasswordResetLinkData
): Promise<HttpsCallableResult<SendPasswordResetLinkResponse>> {
  const sendPasswordResetLink = httpsCallable<
    SendPasswordResetLinkData,
    SendPasswordResetLinkResponse
  >(functions, 'sendPasswordResetLink');
  return sendPasswordResetLink(email);
}

export function verifyEmailAddress(): Promise<HttpsCallableResult<SendVerifyEmailLinkResponse>> {
  const sendVerifyEmailLink = httpsCallable<unknown, SendVerifyEmailLinkResponse>(
    functions,
    'sendEmailVerification'
  );
  return sendVerifyEmailLink();
}

export function getEmailFromPasswordReset(actionCode: string | undefined): Promise<string> {
  if (!actionCode) {
    return Promise.reject('No Action Code');
  }

  return verifyPasswordResetCode(auth, actionCode);
}

export function getActionCodeResponse(actionCode: string | undefined): Promise<void> {
  if (!actionCode) {
    return Promise.reject('No Action Code');
  }

  return applyActionCode(auth, actionCode);
}

export function ResetToNewPassword(actionCode: string, password: string): Promise<void> {
  return confirmPasswordReset(auth, actionCode, password);
}

export function setIsRealtor(): Promise<HttpsCallableResult<SetIsRealtorResponse>> {
  const setIsRealtor = httpsCallable<unknown, SetIsRealtorResponse>(functions, 'setIsRealtor');
  return setIsRealtor();
}

export function setIsClient(token?: string): Promise<HttpsCallableResult<SetIsClientResponse>> {
  const setIsClient = httpsCallable<SetIsClientParams | undefined, SetIsClientResponse>(
    functions,
    'setIsClient'
  );
  return setIsClient(token ? { token } : undefined);
}

export async function loginAs(userId: string) {
  const createFunction = httpsCallable<string>(functions, 'fakeLogin');
  const res = await createFunction(userId);
}
