import { Switch } from '@headlessui/react';

interface Props {
  value: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export function Toggle({ value, disabled, onChange, onFocus, onBlur }: Props): JSX.Element {
  return (
    <Switch.Group>
      <div className="flex w-full">
        <Switch
          disabled={disabled}
          checked={value ?? false}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          className={`${
            value ? 'bg-green-1' : 'bg-gray-3'
          } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none`}
        >
          <span
            className={`${
              value ? 'translate-x-6' : 'translate-x-1'
            } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
          ></span>
        </Switch>
      </div>
    </Switch.Group>
  );
}
