import { useQuery } from 'react-query';

import type { HttpsCallableResult } from 'types';
import { getTransaction } from 'services/transaction';
import { ExtendedTransaction } from 'types/newTransaction';
import { ResponseFromFirebase, WithHistory } from 'types/base';

type TransactionResponse = WithHistory<ResponseFromFirebase<ExtendedTransaction, 'transaction'>>;

export function useTransaction(transactionId: string) {
  const transactionQuery = useQuery<HttpsCallableResult<TransactionResponse>>(
    ['getTransaction', transactionId],
    () => getTransaction(transactionId),
    {
      cacheTime: 30 * 1000
    }
  );

  return transactionQuery;
}
