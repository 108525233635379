import { ButtonS } from 'components';

import type { ConfirmationBoxProps } from 'providers/ConfirmationProvider';

export function ConfirmationBox({
  onReject,
  onConfirm,
  props: { title, text, button, hideCancel, cancelButton = 'Cancel', color }
}: ConfirmationBoxProps): JSX.Element {
  return (
    <>
      <div>
        <h3 className="text-p2 font-semibold pr-6">{title}</h3>
      </div>
      <div className="mt-[25px]">
        <p className="text-p2 text-gray-6">{text}</p>
      </div>
      <div className="mt-[30px] flex items-center justify-end">
        {!hideCancel && (
          <ButtonS type="button" className="text-gray-6 bg-gray-2 mr-[12px]" onClick={onReject}>
            {cancelButton}
          </ButtonS>
        )}
        <ButtonS
          type="button"
          style={{ backgroundColor: color }}
          className="text-white"
          onClick={() => {
            onConfirm();
          }}
        >
          {button}
        </ButtonS>
      </div>
    </>
  );
}
