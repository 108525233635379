import { app } from 'services/firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

//Creating a reference to auth
const auth = getAuth(app);
//Creating a reference to firestore db
const db = getFirestore(app);
//Creating a reference to storage
const storage = getStorage(app);
//Creating a reference to clout functions
const functions = getFunctions(app);

// connectFirestoreEmulator(db, 'localhost', 8080);
// connectFunctionsEmulator(functions, 'localhost', 5001);

//Exporting firebase references created above
export { auth, db, storage, functions };
//Exporting auth utils
export {
  signInWithEmailAndPassword,
  signInWithCustomToken,
  onAuthStateChanged,
  onIdTokenChanged,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  verifyPasswordResetCode,
  confirmPasswordReset,
  applyActionCode,
  RecaptchaVerifier,
  multiFactor,
  PhoneAuthProvider,
  reauthenticateWithCredential,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  deleteUser,
  updatePassword
} from 'firebase/auth';
export type { MultiFactorError, MultiFactorResolver } from 'firebase/auth';
export type { PhoneMultiFactorInfo } from '@firebase/auth-types';
//Exporting firestore db utils
export {
  collection,
  doc,
  setDoc,
  deleteDoc,
  getDoc,
  getDocs,
  query,
  where,
  limit,
  startAfter,
  orderBy,
  deleteField,
  writeBatch,
  collectionGroup,
  updateDoc,
  onSnapshot
} from 'firebase/firestore';
//Exporting storage utils
export {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  getMetadata
} from 'firebase/storage';
//Exporting cloud functions utils
export { httpsCallable } from 'firebase/functions';
//Exporting error class
export { FirebaseError } from 'firebase/app';
