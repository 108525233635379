import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import { Suspense, lazy } from 'react';

import { NotFound } from 'components';
import ProtectedRoute from 'routers/ProtectedRoute';
const Login = lazy(() => import('pages/auth/Login'));
const ClientLogin = lazy(() => import('pages/auth/ClientLogin'));
const Register = lazy(() => import('pages/auth/Register'));
const Forgot = lazy(() => import('pages/auth/Forgot'));
const Reset = lazy(() => import('pages/auth/Reset'));
const Verify = lazy(() => import('pages/auth/Verify'));
const SetMFA = lazy(() => import('pages/auth/SetMFA'));
const EnrollMFA = lazy(() => import('pages/auth/EnrollMFA'));
const Deleted = lazy(() => import('pages/auth/Deleted'));
const ClientRegister = lazy(() => import('pages/auth/ClientRegister'));
const VerifyEmail = lazy(() => import('pages/auth/VerifyEmail'));

import LinearProgress from 'components/LinearProgress';

export const AUTH_ROUTES = {
  login: '/login' as const,
  clientLogin: '/clientLogin' as const,
  register: '/register' as const,
  clientRegister: '/clientRegister' as const,
  forgot: '/forgot' as const,
  reset: '/reset' as const,
  verify: '/verify' as const,
  setMFA: '/setMFA' as const,
  verifyEmail: '/verifyEmail' as const,
  enrollMFA: '/enrollMFA/:verificationId' as const,
  enrollMfaRoot: '/enrollMFA' as const,
  deleted: '/deleted' as const
};

export default function AuthRoutes(): JSX.Element {
  const { path } = useRouteMatch();

  return (
    <Suspense fallback={<LinearProgress />}>
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}${AUTH_ROUTES.login}`} />
        </Route>
        <Route path={`${path}${AUTH_ROUTES.login}`}>
          <Login />
        </Route>
        <Route path={`${path}${AUTH_ROUTES.clientLogin}`}>
          <ClientLogin />
        </Route>
        <Route path={`${path}${AUTH_ROUTES.register}`}>
          <Register />
        </Route>
        <Route path={`${path}${AUTH_ROUTES.clientRegister}`}>
          <ClientRegister />
        </Route>
        <Route path={`${path}${AUTH_ROUTES.forgot}`}>
          <Forgot />
        </Route>
        <Route exact path={`${path}${AUTH_ROUTES.reset}`}>
          <Reset />
        </Route>
        <Route exact path={`${path}${AUTH_ROUTES.verify}`}>
          <Verify />
        </Route>
        <Route exact path={`${path}${AUTH_ROUTES.deleted}`}>
          <Deleted />
        </Route>
        <ProtectedRoute exact role="user" path={`${path}${AUTH_ROUTES.setMFA}`}>
          <SetMFA />
        </ProtectedRoute>
        <ProtectedRoute exact role="user" path={`${path}${AUTH_ROUTES.verifyEmail}`}>
          <VerifyEmail />
        </ProtectedRoute>
        <ProtectedRoute exact role="user" path={`${path}${AUTH_ROUTES.enrollMFA}`}>
          <EnrollMFA />
        </ProtectedRoute>
        <Route>
          <NotFound text="The requested page was not found" />
        </Route>
      </Switch>
    </Suspense>
  );
}
