import { useEffect } from 'react';
import eventEmitter from 'utils/emiiter';

export function useEmitter<T = any>(
  eventName: string,
  effect: (data: T) => void,
  once?: boolean,
  deps?: React.DependencyList
) {
  useEffect(() => {
    const callBack = (event: any) => {
      effect(event.detail as T);
    };
    if (once) {
      eventEmitter.once(eventName, callBack);
    } else {
      eventEmitter.on(eventName, callBack);
    }
    return () => {
      eventEmitter.off(eventName, callBack);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps || [effect, eventName, once]);
}
