import { SORT_FIELD_X_Y_OFFSET } from 'config/constants';

import { FormField } from 'types';

type Dir = 'asc' | 'desc';

export function sortEntriesBy<T extends [string, T[1]]>(key: keyof T[1], dir: Dir = 'desc') {
  return ([, { [key]: item1Value }]: T, [, { [key]: item2Value }]: T): number => {
    if (
      typeof item1Value !== 'string' &&
      typeof item2Value !== 'string' &&
      typeof item1Value !== 'number' &&
      typeof item2Value !== 'number'
    ) {
      return 0;
    }
    let item1ValueLowerCase: string | number = '';
    let item2ValueLowerCase: string | number = '';

    if (typeof item1Value === 'string' && typeof item2Value === 'string') {
      item1ValueLowerCase = item1Value.toString().toLowerCase();
      item2ValueLowerCase = item2Value.toString().toLowerCase();
    }

    if (typeof item1Value === 'number' && typeof item2Value === 'number') {
      item1ValueLowerCase = item1Value;
      item2ValueLowerCase = item2Value;
    }

    if (item1ValueLowerCase === item2ValueLowerCase) {
      return 0;
    } else {
      return item1ValueLowerCase < item2ValueLowerCase
        ? dir === 'asc'
          ? -1
          : 1
        : dir === 'asc'
        ? 1
        : -1;
    }
  };
}

export function sortByKey<T>(key: keyof T, dir: Dir = 'desc') {
  return ({ [key]: item1 }: T, { [key]: item2 }: T): number => {
    if (
      (typeof item1 !== 'string' && typeof item1 !== 'number') ||
      (typeof item2 !== 'string' && typeof item2 !== 'number')
    ) {
      return 0;
    }

    const item1LowerCase = item1.toString().toLowerCase();
    const item2LowerCase = item2.toString().toLowerCase();

    if (item1LowerCase === item2LowerCase) {
      return 0;
    }

    return item1LowerCase < item2LowerCase ? (dir === 'asc' ? -1 : 1) : dir === 'asc' ? 1 : -1;
  };
}

export function sortArrayByKey<T>(arr: Array<T>, key: keyof T, dir: Dir = 'desc'): T[] {
  return [...arr].sort(sortByKey(key, dir));
}

export function sortFields(
  [, field1]: [string, FormField],
  [, field2]: [string, FormField]
): number {
  const { y: y1, x: x1 } = field1;
  const { y: y2, x: x2 } = field2;

  if (y2 === y1) {
    if (x2 === x1) return 0;

    return x2 > x1 ? -1 : 1;
  }

  if (Math.abs(y2 - y1) <= SORT_FIELD_X_Y_OFFSET) {
    if (x2 === x1) return 0;

    return x2 > x1 ? -1 : 1;
  }

  return y2 > y1 ? -1 : 1;
}
